import axios from 'axios';
import config from './config';

const hostUrl = config.hostUrl;
const adminPath = config.adminPath;

axios.interceptors.request.use(async (config) => {
  if (!window.location.href.includes(adminPath)) {
    const token = localStorage.getItem('token');
    config.headers = { 'x-auth-token': token };
  }
  config.baseURL = hostUrl;

  return config;
});
export default axios;
