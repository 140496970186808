import axios from '../host';
export const SET_ACTIVE_STATE = 'SET_ACTIVE_STATE';
export const SET_ACTION_STATE = 'SET_ACTION_STATE';

export const setActiveState = (state, props) => async (dispatch) => {
  return dispatch({
    type: SET_ACTIVE_STATE,
    payload: { state, props },
  });
};

export const setActionState = (action, value) => async (dispatch) => {
  return dispatch({
    type: SET_ACTION_STATE,
    payload: { action, value },
  });
};
