/*global FB*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses } from 'utils/utility';
import { calvh, clickAnime } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'img-star': {
    height: calvh('9vh'),
    maxWidth: '14vw',
    objectFit: 'contain',
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: calvh('2.9vh'),
    lineHeight: calvh('2.9vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
    alignSelf: 'flex-end',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: calvh('5.5vh'),
    width: calvh('5.5vh'),
    color: '#fff',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    borderRadius: '50%',

    '& div': {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
    },
    '&[facebook]': {
      backgroundColor: '#3c589a',
      '& div': {
        backgroundImage: `url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%22-5%20-5%2042%2042%22%3E%3Cpath%20d%3D%22M17.78%2027.5V17.008h3.522l.527-4.09h-4.05v-2.61c0-1.182.33-1.99%202.023-1.99h2.166V4.66c-.375-.05-1.66-.16-3.155-.16-3.123%200-5.26%201.905-5.26%205.405v3.016h-3.53v4.09h3.53V27.5h4.223z%22%20fill%3D%22%23fff%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E)`,
      },
    },
    '&[twitter]': {
      backgroundColor: '#55acee',
      '& div': {
        backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%22-4%20-4%2039%2039%22%3E%0A%3Cpath%20d%3D%22M28%208.557a9.913%209.913%200%200%201-2.828.775%204.93%204.93%200%200%200%202.166-2.725%209.738%209.738%200%200%201-3.13%201.194%204.92%204.92%200%200%200-3.593-1.55%204.924%204.924%200%200%200-4.794%206.049c-4.09-.21-7.72-2.17-10.15-5.15a4.942%204.942%200%200%200-.665%202.477c0%201.71.87%203.214%202.19%204.1a4.968%204.968%200%200%201-2.23-.616v.06c0%202.39%201.7%204.38%203.952%204.83-.414.115-.85.174-1.297.174-.318%200-.626-.03-.928-.086a4.935%204.935%200%200%200%204.6%203.42%209.893%209.893%200%200%201-6.114%202.107c-.398%200-.79-.023-1.175-.068a13.953%2013.953%200%200%200%207.55%202.213c9.056%200%2014.01-7.507%2014.01-14.013%200-.213-.005-.426-.015-.637.96-.695%201.795-1.56%202.455-2.55z%22%20fill%3D%22%23fff%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E')`,
      },
    },
    '&[whatsapp]': {
      backgroundColor: '#55eb4c',
      '& div': {
        backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%22-5%20-5%2040%2040%22%3E%3Cpath%20id%3D%22arc1%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%20d%3D%22M%2011.579798566743314%2024.396926207859085%20A%2010%2010%200%201%200%206.808479557110079%2020.73576436351046%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%207%2019%20l%20-1%206%20l%206%20-1%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20fill%3D%22none%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%2010%2010%20q%20-1%208%208%2011%20c%205%20-1%200%20-6%20-1%20-3%20q%20-4%20-3%20-5%20-5%20c%204%20-2%20-1%20-5%20-1%20-4%22%20fill%3D%22%23fff%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E')`,
      },
    },
    '&[ig]': {
      background:
        'radial-gradient(circle at 30% 107%,#fdf497 0,#fdf497 5%,#fd5949 45%,#d6249f 60%,#285aeb 90%)',
      '& div': {
        backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22Layer_1%22%20version%3D%221.1%22%20viewBox%3D%22-10%20-10%20148%20148%22%20xml%3Aspace%3D%22preserve%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cg%3E%3Cg%3E%3Cpath%20d%3D%22M86%2C112H42c-14.336%2C0-26-11.663-26-26V42c0-14.337%2C11.664-26%2C26-26h44c14.337%2C0%2C26%2C11.663%2C26%2C26v44%20%20%20%20C112%2C100.337%2C100.337%2C112%2C86%2C112z%20M42%2C24c-9.925%2C0-18%2C8.074-18%2C18v44c0%2C9.925%2C8.075%2C18%2C18%2C18h44c9.926%2C0%2C18-8.075%2C18-18V42%20%20%20%20c0-9.926-8.074-18-18-18H42z%22%20fill%3D%22%23fff%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%3E%3Cpath%20d%3D%22M64%2C88c-13.234%2C0-24-10.767-24-24c0-13.234%2C10.766-24%2C24-24s24%2C10.766%2C24%2C24C88%2C77.233%2C77.234%2C88%2C64%2C88z%20M64%2C48c-8.822%2C0-16%2C7.178-16%2C16s7.178%2C16%2C16%2C16c8.822%2C0%2C16-7.178%2C16-16S72.822%2C48%2C64%2C48z%22%20fill%3D%22%23fff%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3Cg%3E%3Ccircle%20cx%3D%2289.5%22%20cy%3D%2238.5%22%20fill%3D%22%23fff%22%20r%3D%225.5%22%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E')`,
      },
    },
    '&[line]': {
      backgroundColor: '#00c300',
      '& div': {
        backgroundImage: `url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M28%2014.304c0-5.37-5.384-9.738-12-9.738S4%208.936%204%2014.304c0%204.814%204.27%208.846%2010.035%209.608.39.084.923.258%201.058.592.122.303.08.778.04%201.084l-.172%201.028c-.05.303-.24%201.187%201.04.647s6.91-4.07%209.43-6.968c1.737-1.905%202.57-3.842%202.57-5.99zM11.302%2017.5H8.918c-.347%200-.63-.283-.63-.63V12.1c0-.346.283-.628.63-.628.348%200%20.63.283.63.63v4.14h1.754c.35%200%20.63.28.63.628%200%20.347-.282.63-.63.63zm2.467-.63c0%20.347-.284.628-.63.628-.348%200-.63-.282-.63-.63V12.1c0-.347.282-.63.63-.63.346%200%20.63.284.63.63v4.77zm5.74%200c0%20.27-.175.51-.433.596-.065.02-.132.032-.2.032-.195%200-.384-.094-.502-.25l-2.443-3.33v2.95c0%20.35-.282.63-.63.63-.347%200-.63-.282-.63-.63V12.1c0-.27.174-.51.43-.597.066-.02.134-.033.2-.033.197%200%20.386.094.503.252l2.444%203.328V12.1c0-.347.282-.63.63-.63.346%200%20.63.284.63.63v4.77zm3.855-3.014c.348%200%20.63.282.63.63%200%20.346-.282.628-.63.628H21.61v1.126h1.755c.348%200%20.63.282.63.63%200%20.347-.282.628-.63.628H20.98c-.345%200-.628-.282-.628-.63v-4.766c0-.346.283-.628.63-.628h2.384c.348%200%20.63.283.63.63%200%20.346-.282.628-.63.628h-1.754v1.126h1.754z%22%2F%3E%3C%2Fsvg%3E')`,
      },
    },
  },
}));

const Share = (props) => {
  const { data } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  // React.useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     FB.init({
  //       appId: '564617709045461',
  //       xfbml: true,
  //       version: 'v9.0',
  //       //version: 'v2.7',
  //     });
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, 'script', 'facebook-jssdk');
  // }, []);

  const handleClick = (app) => {
    const { image, comment } = data;
    let url = '';
    switch (app) {
      case 'facebook':
        //url = `https://www.facebook.com/sharer/sharer.php?display=popup&u=${image}`;
        //handleShareFacebook({ image, comment });
        url = `https://www.facebook.com/dialog/share?app_id=564617709045461&display=popup&quote=${encodeURIComponent(
          comment
        )}&picture=${image}`;
        break;
      // case 'twitter':
      //   break;
      // case 'whatsapp':
      //   break;
      // case 'ig':
      //   break;
      case 'line':
        url = `https://social-plugins.line.me/lineit/share?url=${image}`;
        // url = `https://line.me/R/msg/text/?${encodeURIComponent(
        //   comment
        // )}%0D%0A${encodeURIComponent(image)}`;

        break;
    }
    // var link = document.createElement('a');
    // link.href = url;
    // link.target = '_blank';
    // link.style.display = 'none';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    window.open(url, '_blank');
  };

  const handleShareFacebook = (data) => {
    const { image, comment } = data;
    function sharePhoto() {
      FB.ui(
        {
          method: 'share',
          display: 'popup',
          href: 'https://www.tuagom.com/%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%88%E0%B8%B1%E0%B8%81%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B9%89%E0%B8%AD%E0%B8%A1/',
          quote: comment,
        },
        function (response) {}
      );
    }
    // FB.getLoginStatus(function (response) {
    //   if (response.status === 'connected') {
    //     // The user is already logged in, share the photo
    //     sharePhoto();
    //   } else {
    //     // The user is not logged in, prompt them to log in
    //     FB.login(
    //       function (response) {
    //         if (response.authResponse) {
    //           // The user has logged in, share the photo
    //           sharePhoto();
    //         } else {
    //           console.log('User cancelled login or did not fully authorize.');
    //         }
    //       },
    //       { scope: 'publish_actions' }
    //     );
    //   }
    // });
    sharePhoto();
  };

  return (
    <div className={c('root')}>
      {/* <div
        class='heateor_sss_sharing_container heateor_sss_horizontal_sharing'
        heateor-sss-data-href='https://www.tuagom.com/%e0%b8%a3%e0%b8%b9%e0%b9%89%e0%b8%88%e0%b8%b1%e0%b8%81%e0%b8%81%e0%b8%b1%e0%b8%9a%e0%b8%ab%e0%b8%a1%e0%b8%b2%e0%b8%81%e0%b8%a5%e0%b9%89%e0%b8%ad%e0%b8%a1/'
      >
        <div class='heateor_sss_sharing_title' style='font-weight:bold'>
          แชร์บทความ(Share)
        </div>
        <ul class='heateor_sss_sharing_ul'>
          <li class='heateorSssSharingRound'>
            <i
              style='width:40px;height:40px;border-radius:999px;'
              alt='Facebook'
              title='Facebook'
              class='heateorSssSharing heateorSssFacebookBackground'
              onclick='heateorSssPopup("https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.tuagom.com%2F%25e0%25b8%25a3%25e0%25b8%25b9%25e0%25b9%2589%25e0%25b8%2588%25e0%25b8%25b1%25e0%25b8%2581%25e0%25b8%2581%25e0%25b8%25b1%25e0%25b8%259a%25e0%25b8%25ab%25e0%25b8%25a1%25e0%25b8%25b2%25e0%25b8%2581%25e0%25b8%25a5%25e0%25b9%2589%25e0%25b8%25ad%25e0%25b8%25a1%2F")'
            >
              <ss
                style='display:block;border-radius:999px;'
                class='heateorSssSharingSvg heateorSssFacebookSvg'
              ></ss>
            </i>
          </li>
          <li class='heateorSssSharingRound'>
            <i
              style='width:40px;height:40px;border-radius:999px;'
              alt='Twitter'
              title='Twitter'
              class='heateorSssSharing heateorSssTwitterBackground'
              onclick='heateorSssPopup("http://twitter.com/intent/tweet?text=%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%88%E0%B8%B1%E0%B8%81%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B9%89%E0%B8%AD%E0%B8%A1&amp;url=https%3A%2F%2Fwww.tuagom.com%2F%25e0%25b8%25a3%25e0%25b8%25b9%25e0%25b9%2589%25e0%25b8%2588%25e0%25b8%25b1%25e0%25b8%2581%25e0%25b8%2581%25e0%25b8%25b1%25e0%25b8%259a%25e0%25b8%25ab%25e0%25b8%25a1%25e0%25b8%25b2%25e0%25b8%2581%25e0%25b8%25a5%25e0%25b9%2589%25e0%25b8%25ad%25e0%25b8%25a1%2F")'
            >
              <ss
                style='display:block;border-radius:999px;'
                class='heateorSssSharingSvg heateorSssTwitterSvg'
              ></ss>
            </i>
          </li>
          <li class='heateorSssSharingRound'>
            <i
              style='width:40px;height:40px;border-radius:999px;'
              alt='Whatsapp'
              title='Whatsapp'
              class='heateorSssSharing heateorSssWhatsappBackground'
            >
              <a
                href='https://web.whatsapp.com/send?text=%E0%B8%A3%E0%B8%B9%E0%B9%89%E0%B8%88%E0%B8%B1%E0%B8%81%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%81%E0%B8%A5%E0%B9%89%E0%B8%AD%E0%B8%A1 https%3A%2F%2Fwww.tuagom.com%2F%25e0%25b8%25a3%25e0%25b8%25b9%25e0%25b9%2589%25e0%25b8%2588%25e0%25b8%25b1%25e0%25b8%2581%25e0%25b8%2581%25e0%25b8%25b1%25e0%25b8%259a%25e0%25b8%25ab%25e0%25b8%25a1%25e0%25b8%25b2%25e0%25b8%2581%25e0%25b8%25a5%25e0%25b9%2589%25e0%25b8%25ad%25e0%25b8%25a1%2F'
                rel='nofollow noopener'
                target='_blank'
              >
                <ss
                  style='display:block'
                  class='heateorSssSharingSvg heateorSssWhatsappSvg'
                ></ss>
              </a>
            </i>
          </li>
          <li class='heateorSssSharingRound'>
            <i
              style='width:40px;height:40px;border-radius:999px;'
              alt='Instagram'
              title='Instagram'
              class='heateorSssSharing heateorSssInstagramBackground'
            >
              <a
                href='https://www.instagram.com/'
                rel='nofollow noopener'
                target='_blank'
              >
                <ss
                  style='display:block;border-radius:999px;'
                  class='heateorSssSharingSvg heateorSssInstagramSvg'
                ></ss>
              </a>
            </i>
          </li>
          <li class='heateorSssSharingRound'>
            <i
              style='width:40px;height:40px;border-radius:999px;'
              alt='Line'
              title='Line'
              class='heateorSssSharing heateorSssLineBackground'
            >
              <a href='https://social-plugins.line.me/lineit/share?url=https%3A%2F%2Fwww.tuagom.com%2F%25e0%25b8%25a3%25e0%25b8%25b9%25e0%25b9%2589%25e0%25b8%2588%25e0%25b8%25b1%25e0%25b8%2581%25e0%25b8%2581%25e0%25b8%25b1%25e0%25b8%259a%25e0%25b8%25ab%25e0%25b8%25a1%25e0%25b8%25b2%25e0%25b8%2581%25e0%25b8%25a5%25e0%25b9%2589%25e0%25b8%25ad%25e0%25b8%25a1%2F'>
                <ss
                  style='display:block'
                  class='heateorSssSharingSvg heateorSssLineSvg'
                ></ss>
              </a>
            </i>
          </li>
        </ul>
        <div class='heateorSssClear'></div>
      </div> */}
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item container className={c('label')}>
          แชร์บทความ(Share)
        </Grid>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid
            item
            container
            justifyContent='space-evenly'
            alignItems='center'
          >
            <Grid item>
              <div
                className={c('icon')}
                facebook=''
                ref={clickAnime}
                onClick={() => handleClick('facebook')}
              >
                <div></div>
              </div>
            </Grid>
            {/* <Grid item>
              <div
                className={c('icon')}
                twitter=''
                ref={clickAnime}
                onClick={() => handleClick('twitter')}
              >
                <div></div>
              </div>
            </Grid>
            <Grid item>
              <div
                className={c('icon')}
                whatsapp=''
                ref={clickAnime}
                onClick={() => handleClick('whatsapp')}
              >
                <div></div>
              </div>
            </Grid>
            <Grid item>
              <div
                className={c('icon')}
                ig=''
                ref={clickAnime}
                onClick={() => handleClick('ig')}
              >
                <div></div>
              </div>
            </Grid> */}
            <Grid item>
              <div
                className={c('icon')}
                line=''
                ref={clickAnime}
                onClick={() => handleClick('line')}
              >
                <div></div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Share;
