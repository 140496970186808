import { combineReducers } from 'redux';
import decision from './decision';
import auth from './auth';
import config from './config';
import trainer from './trainer';

export default combineReducers({
  decision,
  auth,
  config,
  trainer,
});
