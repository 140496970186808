import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonUpload from 'components/fragments/ButtonUpload/ButtonUpload';
const ButtonUploadComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonUpload/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonUpload {...props} />
  </ImagesProvider>
);

export default ButtonUploadComponent;
