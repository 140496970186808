import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import StarNumber from 'components/fragments/StarNumber/StarNumber';
const StarNumberComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/StarNumber/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <StarNumber {...props} />
  </ImagesProvider>
);

export default StarNumberComponent;
