import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';
import { ButtonBase } from '@material-ui/core';
import config from '../../../config';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'button-add-line': {
    height: ({ height }) => height || calvh('7vh'),
    maxWidth: ({ maxWidth }) => maxWidth || '26vw',
    objectFit: 'contain',
  },
}));

const ButtonSave = (props) => {
  const { onClick } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('button-add-line')}
        src={images['button-add-line-btn.png']}
        ref={clickAnime}
        onClick={(e) => {
          window.open(config.lineLiff || '');
          onClick && onClick(e);
        }}
      />
    </ButtonBase>
  );
};

export default ButtonSave;
