import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import Frame from 'components/fragments/Frame/Frame';
const FrameComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/Frame/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Frame {...props} />
  </ImagesProvider>
);

export default FrameComponent;
