import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { calvh } from 'utils/element';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: ({ styles }) => styles?.justifyContent,
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingRight: calvh('1vh'),
    width: ({ styles }) => styles?.width,
  },
  'label-container': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingRight: calvh('1vh'),
    width: 'min-content',
  },
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    padding: 2,
    fontSize: calvh('3.5vh'),
    flexBasis: '100%',
    '@media (max-width:434px)': {
      fontSize: calvh('3vh'),
    },
    '& [require]': {
      color: 'red',
    },
  },
  'label-helpertext': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 2,
    fontSize: calvh('1.9vh'),
    flexBasis: '100%',
    '@media (max-width:434px)': {
      fontSize: calvh('1.5vh'),
    },
    color: 'red',
    whiteSpace: 'nowrap',
  },
  'input-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: ({ styles }) => styles?.inputMaxWidth || '40vw',
    width: ({ styles }) => styles?.inputWidth,
    height: ({ styles }) => styles?.inputContainerHeight || calvh('5vh'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 70%',
    backgroundPosition: 'center',
  },
  input: {
    fontSize: ({ styles }) => styles?.fontSize || calvh('1.9vh'),
    fontWeight: 600,
    margin: ({ styles }) => styles?.iputMargin || calvh('0.8vh'),
    height: ({ styles }) => styles?.inputHeight || calvh('2.1vh'),
    borderColor: 'transparent',
    width: '100%',
    lineHeight: ({ styles }) => styles?.fontSize || calvh('1.9vh'),
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width:434px)': {
      fontSize: ({ styles }) => styles?.fontSizeSm,
    },
  },
}));

const TextField = (props) => {
  const { label, value, onChange, require, helperText, imgTextBox } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <div className={'text' + ' ' + c('label-container')}>
        <div className={'text' + ' ' + c('label')}>
          {require ? <div require=''>**</div> : null}
          {label}
        </div>
        {helperText ? (
          <div className={c('label-helpertext')}>{helperText}</div>
        ) : null}
      </div>

      <div
        className={c('input-container')}
        style={{
          backgroundImage: `url(${
            imgTextBox || images['input-field-text-input.png']
          })`,
        }}
      >
        <input className={c('input')} onChange={onChange} value={value} />
      </div>
    </div>
  );
};

export default TextField;
