export const AUTHEN_SUCCESS = 'AUTHEN_SUCCESS';
export const AUTHEN_FAIL = 'AUTHEN_FAIL';
export const LOGOUT = 'LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';

// Login User
export const setAuthenSuccess = (token) => async (dispatch) => {
  dispatch({
    type: AUTHEN_SUCCESS,
    payload: { token },
  });
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  // dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
