import * as type from '../actions/auth';

//import { setAuthToken } from 'utils/auth';

const token = localStorage.getItem('token');
//console.log('token;;', !!token);
//localStorage.removeItem('token');

const initialState = {
  token: token,
  //isAuthenSuccess: token ? true : false,
  isAuthenSuccess: true,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.AUTHEN_SUCCESS:
      // localStorage.setItem('token', action.payload.token);
      // if (localStorage.token) {
      //   setAuthToken(localStorage.token);
      // }
      return {
        ...state,
        ...action.payload,
        isAuthenSuccess: true,
        loading: false,
      };
    case type.AUTH_ERROR:
    case type.AUTHEN_FAIL:
    case type.LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenSuccess: false,
        loading: false,
      };
    default:
      return state;
  }
}
