import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  mark: {
    height: calvh('3vh'),
    maxWidth: '10vw',
    objectFit: 'contain',
    paddingRight: calvh('1vh'),
    filter: 'drop-shadow(0px 4px 2px grey)',
  },
  'caption-text': {
    fontSize: calvh('2vh'),
    color: '#009420',
    '@media (min-width:434px)': {
      fontSize: calvh('2.5vh'),
    },
  },
}));

const PointList = (props) => {
  const { list, spacing, point, styles } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      {(list || []).map((item, idx) => {
        return (
          <div
            key={'point-item-' + idx}
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignItems: `${styles?.alignItems || 'center'}`,
              padding: `${spacing || '0px'} 0px`,
            }}
          >
            {point ? (
              point
            ) : (
              <img className={c('mark')} src={images['point-list-mark.png']} />
            )}
            <div>
              <div style={{ display: 'flex', flexFlow: 'column' }}>
                <div text-resize='' className={'text'} style={styles?.item}>
                  {item?.text || item}
                </div>
                {item?.caption ? (
                  <div className={'text' + ' ' + c('caption-text')}>
                    {item?.caption || ''}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PointList;
