import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService, checkValidity } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { login } from 'services/auth';
import { useImagesContext } from 'contexts/ImagesContext';
import { useDispatch } from 'react-redux';
import * as decisionActions from 'actions/decision';
import * as authActions from 'actions/auth';
import * as trainerActions from 'actions/trainer';
import Progress from 'components/fragments/Progress';
import ButtonOK from 'components/fragments/ButtonOK';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  'container-login': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '60vw',
    height: calvh('10vh'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 70%',
    backgroundPosition: 'center',
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar': {
    position: 'fixed',
    top: 0,
    left: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '4rem',
    zIndex: 1,
  },
  'input-login': {
    fontSize: calvh('3vh'),
    fontWeight: 600,
    margin: '.5rem',
    borderColor: 'transparent',
    width: '100%',
    //lineHeight: '3rem',
    '&:focus': {
      outline: 'none',
    },
    textAlign: 'center',
  },
  'img-logo': {
    objectFit: 'contain',
    height: calvh('32vh'),
    width: '60vw',
  },
  'img-placeholder': {
    height: calvh('7vh'),
    maxWidth: '80vw',
    objectFit: 'contain',
  },
  'img-warning': {
    height: calvh('13vh'),
    maxWidth: '90vw',
    paddingTop: calvh('1vh'),
    objectFit: 'contain',
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
}));

const Login = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [form, setForm] = React.useState({});
  const [loginFlag, setLoginFlag] = React.useState({});
  const dispatch = useDispatch();

  const handleClickLogin = () => {
    const { valid, message } = handleValidate();
    if (valid) {
      handleLoginService();
    } else {
      warningAlert(undefined, message);
    }
  };

  const handleValidate = () => {
    console.log('form:', checkValidity(form['code'], { required: true }));
    let valid = true;
    let message = '';
    if (!checkValidity(form['code'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุ code';
    }
    return { valid, message };
  };

  const handleChangeForm = (event, field) => {
    const value = event.target.value;
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleLoginService = (next) => {
    callService(
      login,
      { code: form['code'] },
      setLoginFlag,
      (resp) => {
        dispatch(trainerActions.setTrainerInfo(resp?.teacher));
        //dispatch(authActions.setAuthenSuccess());
        if (resp?.teacher?.videoDone) {
          dispatch(decisionActions.setActiveState('schedule'));
        } else {
          dispatch(decisionActions.setActiveState('introduce'));
        }
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const pendingFlag = React.useMemo(
    () => loginFlag.request,
    [loginFlag.request]
  );

  const successFlag = React.useMemo(
    () => loginFlag.success,
    [loginFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? <Progress /> : null}
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={12} container justifyContent='center'>
          <img className={c('img-logo')} src={images['login-logo.png']} />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <div
            className={c('container-login')}
            style={{
              backgroundImage: `url(${images['login-field.png']})`,
            }}
          >
            <input
              className={c('input-login')}
              onChange={(e) => handleChangeForm(e, 'code')}
            />
          </div>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <img
            className={c('img-placeholder')}
            src={images['login-placeholder.png']}
          />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <ButtonOK onClick={handleClickLogin} />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <img className={c('img-warning')} src={images['login-warning.png']} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
