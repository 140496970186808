import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';

import { ButtonBase } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'btn-logout': {
    height: ({ height }) => height || calvh('7vh'),
    maxWidth: '22vw',
    objectFit: 'contain',
  },
}));

const ButtonLogout = (props) => {
  const { onClick, refFn } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('btn-logout')}
        src={images['button-logout.png']}
        ref={(r) => {
          if (r) {
            refFn && refFn(r);
            clickAnime(r);
          }
        }}
        onClick={onClick}
      />
    </ButtonBase>
  );
};

export default ButtonLogout;
