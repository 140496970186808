import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImagesProvider } from 'contexts/ImagesContext';
import Wrapper, { When } from 'components/utils/wrapper';
import Background from 'pages/trainers/background';
import Trainers from 'components/features/trainers';
import TrainerSchedule from 'components/features/trainer-schedule';
import * as decisionActions from 'actions/decision';

const BackgroundComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'pages/trainers/background/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Background {...props} />
  </ImagesProvider>
);
const TrainersComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/trainers/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <Trainers {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const TrainerScheduleComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/trainer-schedule/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <TrainerSchedule {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const Decision = (props) => {
  const store = useSelector((state) => state);
  const { activeState } = store?.decision;
  const [state, setState] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(decisionActions.setActiveState('trainers'));
  }, []);

  React.useEffect(() => {
    setState(activeState?.state);
  }, [activeState]);

  return (
    <div className={'app-font-face'}>
      <Wrapper open consider={state}>
        <When condition={'trainers'}>
          <TrainersComponent />
        </When>
        <When condition={'trainer-schedule'}>
          <TrainerScheduleComponent />
        </When>
      </Wrapper>
    </div>
  );
};
export default Decision;
