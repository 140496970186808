import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import {
//   Search as SearchIcon,
//   DoubleArrow as DoubleArrowIcon,
//   CheckCircle as CheckCircleIcon
// } from '@material-ui/icons';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { getStudentInfo } from 'services/student';
import { useImagesContext } from 'contexts/ImagesContext';
import TextField3 from 'components/fragments/TextField3';
import ButtonSave from 'components/fragments/ButtonSave';
import UploadImage from 'components/fragments/UploadImage';
//import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  'img-line': {
    width: '85%',
    height: '0.6vh',
    margin: '0.5rem 0px',
  },
  'img-line-vertical': {
    height: '85%',
    width: '0.6vh',
    margin: '0px 0.5rem',
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
  'student-setting-password02': {
    //width: calvh('25vh'),
    maxHeight: calvh('4.5vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
    //objectPosition: '0%',
    //paddingLeft: '1rem',
  },
  'student-setting-comment': {
    maxHeight: calvh('3.5vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
  },
  'student-setting-teacher01': {
    maxHeight: calvh('4.5vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
  },
  'student-setting-password': {
    maxHeight: calvh('4.5vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
  },
}));

const StudentSetting = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <Grid
        container
        justifyContent='center'
        alignItems='flex-start'
        alignContent='flex-start'
        style={{ marginTop: '0.5rem', marginBottom: '4rem' }}
      >
        <Grid
          item
          xs={11}
          container
          justifyContent='space-evenly'
          alignItems='flex-end'
          style={{ flexWrap: 'nowrap' }}
        >
          <Grid
            item
            style={{ display: 'flex', justifyContent: 'center', width: '45%' }}
          >
            <TextField3
              label={
                <img
                  className={c('student-setting-password02')}
                  src={images['student-setting-password02.png']}
                />
              }
              imgField={images['student-setting-id.png']}
              vertical
              styles={{
                inputContainer: {
                  height: calvh('9vh'),
                  maxWidth: calvh('22vh'),
                },
                input: {
                  fontSize: calvh('5.5vh'),
                  height: calvh('4vh'),
                  padding: `0px 0px ${calvh('1vh')} 0px`,
                },
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '10%',
            }}
          >
            <img
              className={c('img-line-vertical')}
              src={images['student-setting-line01.png']}
            />
          </Grid>
          <Grid
            item
            style={{ display: 'flex', justifyContent: 'center', width: '45%' }}
          >
            <UploadImage />
          </Grid>
        </Grid>
        <Grid item container justifyContent='center'>
          <img
            className={c('img-line')}
            src={images['student-setting-line02.png']}
          />
        </Grid>
        <Grid item container justifyContent='center'>
          <Grid
            item
            xs={11}
            container
            justifyContent='center'
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <TextField3
                label={
                  <img
                    className={c('student-setting-comment')}
                    src={images['student-setting-comment.png']}
                  />
                }
                imgField={images['student-setting-comment01.png']}
                vertical
                styles={{
                  width: '100%',
                  margin: '1vh 0px 0px 0px',
                  inputContainer: {
                    height: calvh('26vh'),
                    width: '100%',
                    maxWidth: calvh('50vh'),
                    backgroundSize: '100% 90%',
                  },
                  input: {
                    fontSize: calvh('4vh'),
                    height: calvh('21vh'),
                    padding: `0px 0px 0px 0px`,
                    lineHeight: calvh('4vh'),
                  },
                }}
                textarea
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <TextField3
                label={
                  <img
                    className={c('student-setting-teacher01')}
                    src={images['student-setting-teacher01.png']}
                  />
                }
                imgField={images['student-setting-teacher.png']}
                styles={{
                  labelContainer: {
                    padding: `0px ${calvh('1vh')} ${calvh('1vh')} 0px`,
                  },
                  inputContainer: {
                    height: calvh('9vh'),
                    maxWidth: calvh('22vh'),
                  },
                  input: {
                    fontSize: calvh('5.5vh'),
                    height: calvh('4vh'),
                    padding: `0px 0px ${calvh('1vh')} 0px`,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent='center'>
          <img
            className={c('img-line')}
            style={{ marginTop: '0rem' }}
            src={images['student-setting-line02.png']}
          />
        </Grid>
        <Grid
          item
          xs={11}
          container
          justifyContent='space-evenly'
          alignItems='flex-end'
          style={{ flexWrap: 'nowrap' }}
        >
          <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField3
              label={
                <img
                  className={c('student-setting-password')}
                  src={images['student-setting-password.png']}
                />
              }
              imgField={images['student-setting-password01.png']}
              vertical
              styles={{
                margin: '0.5vh 0px 0px 0px',
                inputContainer: {
                  height: calvh('8vh'),
                  maxWidth: calvh('25vh'),
                },
                input: {
                  fontSize: calvh('5.5vh'),
                  height: calvh('4vh'),
                  padding: `0px 0px ${calvh('1vh')} 0px`,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <ButtonSave />
        </Grid>
      </Grid>
    </div>
  );
};

export default StudentSetting;
