import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert } from 'utils/alert';
import {
  /*getPrinciple,*/ getTrainerPrincipleChecklist,
  editTrainerPrincipleInfo,
} from 'services/principle';
//import { useImagesContext } from 'contexts/ImagesContext';
import Progress from 'components/fragments/Progress';
import PageNumber from 'components/fragments/PageNumber';
import ButtonOK from 'components/fragments/ButtonOK';
import ButtonBack from 'components/fragments/ButtonBack';
import PointList from 'components/fragments/PointList';
import * as decisionActions from 'actions/decision';
import Video from 'components/fragments/Video';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      lineHeight: calvh('3.3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
      '@media (max-width:420px)': {
        fontSize: calvh('2.7vh'),
        lineHeight: calvh('3vh'),
      },
      '@media (max-width:320px)': {
        fontSize: calvh('2.5vh'),
        lineHeight: calvh('2.6vh'),
      },
    },
  },
  'container-devider': {
    width: '95%',
    height: calvh('0.36vh'),
    backgroundColor: '#6c2912',
  },
  'box-video-section1': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: calvh('1vh'),
    //padding: '1vh',
    border: `${calvh('1vh')} solid #9b703db3`,
    borderRadius: '10px',
    backgroundColor: '#f6fff080',
    width: '95%',
    height: '30vh',
    '& [video]': {},
  },
  'box-section2': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: calvh('1vh'),
    padding: calvh('1vh'),
    border: `${calvh('0.7vh')} solid #f0292991`,
    borderRadius: '5px',
    backgroundColor: '#f6fff080',
    width: 'fit-content',
    margin: 'auto',
    '& [title]': {
      fontSize: calvh('3.6vh'),
      textAlign: 'center',
      color: '#f02929',
      width: 'fit-content',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  point: {
    fontSize: calvh('5vh'),
    fontWeight: 'bolder',
    color: '#009420',
    textShadow: `#fff ${calvh('0.2vh')} ${calvh('0.2vh')} 0px, #fff ${calvh(
      '-0.2vh'
    )} ${calvh('-0.14vh')} 0px, #fff 0px ${calvh('0.4vh')} 0px`,
  },
  description: {
    fontSize: calvh('4.8vh'),
    fontWeight: 'bolder',
    color: '#1421ae',
    textAlign: 'center',
  },
  'img-principle': {
    height: 'fit-content',
    maxHeight: calvh('32vh'),
    maxWidth: '90vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
}));

const Principle = (props) => {
  //const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  //const [getPrincipleFlag, setGetPrincipleFlag] = React.useState({});
  const [
    getTrainerPrincipleChecklistFlag,
    setGetTrainerPrincipleChecklistFlag,
  ] = React.useState({});
  const [editTrainerPrincipleInfoFlag, setEditTrainerPrincipleInfoFlag] =
    React.useState({});
  const [principles, setPrinciples] = React.useState([]);
  const [principleSelected, setPrincipleSelected] = React.useState({});
  const [enableButtonOk, setEnableButtonOk] = React.useState(false);
  const dispatch = useDispatch();
  const { actionState, activeState } = useSelector((state) => state?.decision);

  React.useEffect(() => {
    handleGetTrainerPrincipleChecklistService(({ principles }) => {
      const orderPrinciples = (principles || []).sort(
        (a, b) => +(a.id || 0) - +(b.id || 0)
      );
      if (orderPrinciples.length) {
        if (activeState.props?.previous === 'introduce-end') {
          setPrincipleSelected(orderPrinciples[orderPrinciples.length - 1]);
        } else {
          setPrincipleSelected(orderPrinciples[0]);
        }
      }
      setPrinciples(principles);
    });
  }, []);

  React.useEffect(() => {
    const video = principleSelected;
    if (
      !video?.videoValue ||
      video?.videoType === 'YOUTUBE' ||
      video?.watched
    ) {
      setEnableButtonOk(true);
    } else {
      setEnableButtonOk(false);
    }
  }, [principleSelected]);

  const handleVideoAlmostFinished = () => {
    //console.log('handleVideoAlmostFinished');
    setEnableButtonOk(true);
  };

  const handleClickOk = () => {
    handleEditTrainerPrincipleInfoService(() => {
      const nextPrinciple = principles.find(
        (principle) => +principle?.id === +principleSelected?.id + 1
      );
      if (nextPrinciple) {
        handleGetTrainerPrincipleChecklistService(({ principles }) => {
          const principle = principles.find(
            (principle) => principle?.id === nextPrinciple?.id
          );
          setPrinciples(principles);
          setPrincipleSelected(principle);
        });
      } else {
        dispatch(decisionActions.setActiveState('introduce-end'));
      }
    });
  };

  const handleClickBack = () => {
    const previousPrinciple = principles.find(
      (principle) => +principle?.id === +principleSelected?.id - 1
    );
    console.log('prevs:', principleSelected);
    console.log('back:', previousPrinciple);
    if (previousPrinciple) {
      setPrincipleSelected(previousPrinciple);
    } else {
      dispatch(decisionActions.setActiveState('introduce'));
    }
  };

  const handleGetTrainerPrincipleChecklistService = (next) => {
    callService(
      getTrainerPrincipleChecklist,
      null,
      setGetTrainerPrincipleChecklistFlag,
      ({ checklist, principles, trainerInfo }) => {
        const unwatchedList = (checklist || [])
          .filter((item) => !item?.watched)
          .sort((a, b) => a.id - b.id);
        console.log('unwatchedList::', unwatchedList);
        if (
          unwatchedList.length ||
          (!trainerInfo?.videoDone && actionState['reachedIntroduceEnd'])
        ) {
          // const principleIdSelected = unwatchedList
          //   .sort((a, b) => a.id - b.id)
          //   .map((item) => item?.id)[0];
          // const principle = principles.find(
          //   (principle) => principle?.id === principleIdSelected
          // );
          next && next({ principles, unwatchedList });
        } else if (trainerInfo?.videoDone) {
          dispatch(decisionActions.setActiveState('schedule'));
        } else {
          if (!actionState['reachedIntroduceEnd']) {
            dispatch(decisionActions.setActiveState('introduce-end'));
          }
        }
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleEditTrainerPrincipleInfoService = (next) => {
    callService(
      editTrainerPrincipleInfo,
      {
        id: principleSelected?.id,
        watched: true,
      },
      setEditTrainerPrincipleInfoFlag,
      (resp) => {
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleError = (error) => {
    errorAlert(error, (result) => {
      if (result.isConfirmed) {
        if (error.response.status === 401) {
          dispatch(decisionActions.setActiveState('login'));
        }
      } else if (result.isDenied) {
      }
    });
  };

  const pendingFlag = React.useMemo(
    () => getTrainerPrincipleChecklistFlag.request,
    [getTrainerPrincipleChecklistFlag.request]
  );

  const successFlag = React.useMemo(
    () => getTrainerPrincipleChecklistFlag.success,
    [getTrainerPrincipleChecklistFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} container justifyContent='center'>
            <PageNumber page={principleSelected.id - 1} />
          </Grid>

          {principleSelected?.videoValue ? (
            <Grid
              item
              xs={12}
              container
              justifyContent='center'
              alignItems='center'
              style={{ height: '50%' }}
            >
              <div className={c('box-video-section1')}>
                <Video
                  value={`${config.hostUrl}${principleSelected?.videoValue}?id=${principleSelected?.id}`}
                  type={principleSelected?.videoType}
                  onAlmostFinished={handleVideoAlmostFinished}
                />
              </div>
            </Grid>
          ) : null}
          {principleSelected?.videoValue ? (
            <Grid
              item
              xs={12}
              container
              justifyContent='center'
              style={{ padding: '10px 0px' }}
            >
              <div className={c('container-devider')} />
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{ padding: '1vh' }}
          >
            <img
              className={c('img-principle')}
              src={`${config.hostUrl}${principleSelected?.imageUri}`}
              style={{
                maxHeight: !principleSelected?.videoValue
                  ? calvh('68vh')
                  : undefined,
              }}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{ paddingBottom: 10 }}
          >
            <div style={{ paddingLeft: '1vw' }}>
              <PointList
                list={principleSelected?.section1?.list}
                point={<div className={c('point')}>✔</div>}
                styles={{ item: { fontSize: calvh('3.5vh') } }}
              />
              {principleSelected?.section1?.description ? (
                <div className={'text' + ' ' + c('description')}>
                  {principleSelected?.section1?.description}
                </div>
              ) : null}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            <div style={{ paddingLeft: '1vw' }}>
              <div className={c('box-section2')}>
                <div title='' className={'text'}>
                  {principleSelected?.section2?.title}
                </div>
              </div>
              <PointList
                list={principleSelected?.section2?.list}
                spacing={calvh('0.5vh')}
              />
            </div>
          </Grid> */}

          <Grid
            item
            xs={12}
            container
            justifyContent='space-between'
            style={{ padding: calvh('1vh') }}
          >
            <Grid item>
              <ButtonBack onClick={handleClickBack} />
            </Grid>
            <Grid item>
              <ButtonOK
                onClick={handleClickOk}
                disabled={
                  !enableButtonOk || editTrainerPrincipleInfoFlag.request
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {editTrainerPrincipleInfoFlag.request ? <Progress /> : null}
    </div>
  );
};

export default Principle;
