import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import imgLoading from 'images/circle-loading-animation.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    //backdropFilter: 'brightness(0.8) opacity(0.8)',
    backgroundColor: '#04040424',
  },
  'img-loading': {
    //top: 'calc(25vh)',
    objectFit: 'contain',
    width: '50%',
  },
}));

const Progress = (props) => {
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <img src={imgLoading} className={c('img-loading')}></img>
    </div>
  );
};

export default Progress;
