import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ScheduleTable from 'components/fragments/ScheduleTable/ScheduleTable';
//import {ColumnHeader} from 'components/fragments/ScheduleTable/ScheduleTable';
const ScheduleTableComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ScheduleTable/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ScheduleTable {...props} />
  </ImagesProvider>
);

export default ScheduleTableComponent;
