import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Backdrop from '@material-ui/core/Backdrop';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import ButtonBack from 'components/fragments/ButtonBack';
import { renderAnime, calvh } from 'utils/element';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    //backdropFilter: 'brightness(0.8) opacity(0.8)',
    backgroundColor: '#04040424',
    paddingTop: calvh('1vh'),
  },
  'schedule-finish': {
    //top: 'calc(25vh)',
    width: calvh('40vh'),
    objectFit: 'contain',
    maxWidth: '60vw',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const SuccessAlert = (props) => {
  const { onClose } = props;
  const c = joinClasses(useStyles({ ...props }));
  const { images } = useImagesContext();

  return (
    <div className={c('root')}>
      {/* <Backdrop className={c('backdrop')} open={true}> */}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={images['schedule-finish.png']}
          className={c('schedule-finish')}
          ref={renderAnime}
        ></img>
        <div
          style={{
            position: 'absolute',
            bottom: calvh('1vh'),
            paddingBottom: calvh('0.5vh'),
          }}
        >
          <ButtonBack
            refFn={renderAnime}
            onClick={onClose}
            height={calvh('5vh')}
            maxWidth={'20vw'}
          />
        </div>
      </div>
      {/* </Backdrop> */}
    </div>
  );
};

export default SuccessAlert;
