import * as type from '../actions/trainer';

const initialState = {
  trainerInfo: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.SET_TRAINER_INFO:
      return { ...state, trainerInfo: action.payload.value };
    default:
      return state;
  }
}
