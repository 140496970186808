import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import UploadImage from 'components/fragments/UploadImage/UploadImage';
const UploadImageComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/UploadImage/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <UploadImage {...props} />
  </ImagesProvider>
);

export default UploadImageComponent;
