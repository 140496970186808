import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import BoxNumber from 'components/fragments/BoxNumber/BoxNumber';
const BoxNumberComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/BoxNumber/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <BoxNumber {...props} />
  </ImagesProvider>
);

export default BoxNumberComponent;
