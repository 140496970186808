export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
}

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  console.debug(image);
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  const base64Image = canvas.toDataURL('image/jpeg');
  return base64Image;

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob(
  //     blob => {
  //       blob.name = fileName;
  //       resolve(blob);
  //     },
  //     'image/jpeg',
  //     1
  //   );
  // });
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //resolve(reader.result)
      reduceImageQuality(reader.result).then((resp) => {
        resolve(resp);
      });
    };
    reader.onerror = (error) => reject(error);
  });

export function reduceImageQuality(base64Str) {
  return new Promise((resolve, reject) => {
    const targetQualityInBytes = 300000; // Target size 300kB
    const imageContent = base64Str.split(',')[1];
    const imageSizeInBytes = window.atob(imageContent).length;

    if (imageSizeInBytes <= targetQualityInBytes) {
      resolve(base64Str);
    } else {
      const newQuality = targetQualityInBytes / imageSizeInBytes;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const newImage = new Image();
      newImage.onload = () => {
        canvas.width = newImage.width;
        canvas.height = newImage.height;
        context.drawImage(newImage, 0, 0);
        resolve(canvas.toDataURL('image/jpeg', newQuality));
      };
      newImage.onerror = (error) => {
        reject(error);
      };
      newImage.src = base64Str;
    }
  });
}
