import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';
import { ButtonBase } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'btn-ok': {
    //marginTop: '1vh',
    height: calvh('7vh'),
    maxWidth: '22vw',
    objectFit: 'contain',
  },
}));

const ButtonOK = (props) => {
  const { onClick, disabled } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('btn-ok')}
        style={{
          filter: disabled ? 'grayscale(0.7)' : undefined,
        }}
        src={images['button-ok.png']}
        ref={(ref) => (!disabled ? clickAnime(ref) : undefined)}
        onClick={(e) => (!disabled ? onClick(e) : undefined)}
      />
    </ButtonBase>
  );
};

export default ButtonOK;
