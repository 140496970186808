import axios from 'host';

export const getIntroduce = (
  req,
  next = null,
  error = null,
  complete = null
) => {
  axios
    .get('/teacher/videos')
    .then((resp) => {
      if ((resp.data || [])[0]) {
        next && next((resp.data || [])[0]);
      } else {
        error && error('not found info.');
      }
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });

  // setTimeout(() => {
  //   next({
  //     section1: {
  //       list: [
  //         {
  //           text: 'บทเรียนจะมีทั้งหมด 12 หน้า',
  //           caption: '',
  //         },
  //         {
  //           text: 'เมื่อจบภาระกิจแล้วกด OK เพื่อไปหน้าภัดไป',
  //           caption: '',
  //         },
  //         {
  //           text: 'ใช้เวลาโดยรวมประมาณ 2 ชม',
  //           caption: '',
  //         },
  //         {
  //           text: 'ไม่จำเป็นต้องทำผ่านในวันเดียว',
  //           caption: 'เมื่อเข้ามาอีกครั้ง ใส่รหัสสามารถกลับมาดูต่อได้',
  //         },
  //       ],
  //     },
  //     section2: {
  //       text: [
  //         'เรียนจบบทเรียนทั้งหมดแล้ว',
  //         'โปรแกรมจะบันทึกข้อมูลของผู้ฝึกสอนเข้าระบบ',
  //         'และสามารถเข้ามาสอนที่สถาบันได้ทันที',
  //       ],
  //     },
  //     section3: {
  //       title: 'อัตราค่าจ้าง PATHTIME',
  //       text: [
  //         'เสาร์-อาทิตย์ ทำงาน 8 ชม.พัก 1 ชม.',
  //         'มาสอนที่สถาบันครั้งที่ 1-2 ได้รับค่าจ้าง 500บาท',
  //         'มาสอนที่สถาบันครั้งที่ 3-6 ได้รับค่าจ้าง 550บาท',
  //         'มาสอนครั้งที่ 7 เป็นต้นไป ได้รับค่าจ้าง 600บาท',
  //       ],
  //     },
  //     section4: {
  //       title: 'ปัจจุบันมี 2 สาขา',
  //       text: [
  //         '1.เกทเวย์เอกมัย ชั้น4 (ติดBTSเอกมัย)',
  //         '2.พาราไดซ์พาร์ค ศรีนครินทร์ ชั้น4',
  //       ],
  //     },
  //   });
  //   complete();
  // }, 500);
};

export const getIntroduceEnd = (
  req,
  next = null,
  error = null,
  complete = null
) => {
  // setTimeout(() => {
  //   next({
  //     section1: {
  //       title: 'ภารกิจสำเร็จ',
  //       list: [
  //         {
  //           text: 'หากต้องการดูซ้ำสามารถย้อนกลับ(BACK)ไปดูบทเรียนที่ไม่เข้าใจได้',
  //           caption: '',
  //         },
  //       ],
  //     },
  //     section2: {
  //       text: 'กด OK ถือว่าเสร็จสิ้นภาระกิจและไม่สามารถย้อนกลับได้',
  //     },
  //   });
  //   complete();
  // }, 500);

  axios
    .get('/teacher/videos')
    .then((resp) => {
      const videos = resp.data || [];
      if (videos.length) {
        next && next(videos[videos.length - 1]);
      } else {
        error && error('videos not found.');
      }
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};
