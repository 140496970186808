import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonNext from 'components/fragments/ButtonNext/ButtonNext';
const ButtonNextComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonNext/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonNext {...props} />
  </ImagesProvider>
);

export default ButtonNextComponent;
