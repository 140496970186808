import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  root: ({ styles }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(styles?.root || {}),
  }),
  'box-number': {
    height: ({ styles }) => styles?.height || calvh('11vh'),
    maxWidth: ({ styles }) => styles?.maxWidth || '18vw',
    objectFit: 'contain',
  },
  number: {
    position: 'absolute',
    zIndex: 2,
    fontSize: ({ styles }) => styles?.fontSize || calvh('7vh'),
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%',
    top: 0,
    left: 0,
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('-0.1vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.25vh'
    )} ${calvh('0.25vh')} 0px`,
  },
}));

const BoxNumber = (props) => {
  const { type, number } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <Grid container justifyContent='center' style={{ position: 'relative' }}>
        <img
          className={c('box-number')}
          src={images[`box-number-${type}.png`]}
        />
        <div className={'text' + ' ' + c('number')}>{number}</div>
      </Grid>
    </div>
  );
};

export default BoxNumber;
