import axios from 'host';
import config from '../config';
import { editTrainerSchedule } from './schedule';

export const getTrainers = (next = null, error = null, complete = null) => {
  axios
    .get('/teachers')
    .then((resp) => {
      next && next(resp.data);
      return;
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });

  // setTimeout(() => {
  //   next([
  //     { nickName: 'nickName1', code: 'code1', status: 'done' },
  //     { nickName: 'nickName2', code: 'code2' },
  //     { nickName: 'nickName3', code: 'code3' },
  //     { nickName: 'nickName4', code: 'code4' },
  //     { nickName: 'nickName5', code: 'code5' },
  //     { nickName: 'nickName6', code: 'code6' },
  //     { nickName: 'nickName7', code: 'code7', status: 'done' },
  //     { nickName: 'nickName8', code: 'code8' },
  //     { nickName: 'nickName9', code: 'code9' },
  //     { nickName: 'nickName10', code: 'code10' },
  //     { nickName: 'nickName11', code: 'code11' },
  //     { nickName: 'nickName12', code: 'code12' },
  //     { nickName: 'nickName13', code: 'code13' },
  //     { nickName: 'nickName14', code: 'code14' },
  //     { nickName: 'nickName15', code: 'code15' },
  //     { nickName: 'nickName16', code: 'code16' },
  //     { nickName: 'nickName17', code: 'code17' },
  //     { nickName: 'nickName18', code: 'code18' },
  //     { nickName: 'nickName19', code: 'code19' },
  //     { nickName: 'nickName20', code: 'code20', status: 'done' },
  //     { nickName: 'nickName21', code: 'code21' },
  //     { nickName: 'nickName22', code: 'code22' },
  //     { nickName: 'nickName23', code: 'code23' },
  //   ]);
  //   complete();
  // }, 1000);
};

export const getTrainerInfo = (next = null, error = null, complete = null) => {
  axios
    .get('/teacher')
    .then((resp) => {
      next && next(resp.data);
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};

export const editTrainerInfo = (
  { name, nickName, phoneNumber, schedule, id, videoDone },
  next = null,
  error = null,
  complete = null
) => {
  let apiUrl = '';
  if (window.location.href.includes(config.adminPath)) {
    apiUrl = `/teachers/${id}`;
  } else {
    apiUrl = `/teacher`;
  }
  //console.log('schedule--', schedule);
  if (schedule) {
    axios
      .patch(apiUrl, { name, nickName, phoneNumber, videoDone })
      .then((resp) => {
        editTrainerSchedule({ schedule, id }, next, error, complete);
      })
      .catch((err) => {
        error && error(err);
        complete && complete();
      });
  } else {
    axios
      .patch(apiUrl, { name, nickName, phoneNumber, videoDone })
      .then((resp) => {
        next && next(resp?.data);
      })
      .catch((err) => {
        error && error(err);
      })
      .finally(() => {
        complete && complete();
      });
  }

  // setTimeout(() => {
  //   complete();
  // }, 1000);
};
