import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';

import { ButtonBase } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ({ styles }) => styles?.padding,
    margin: ({ styles }) => styles?.margin,
  },
  'btn-watch': {
    width: '12vw',
    maxHeight: calvh('6vh'),
    objectFit: 'contain',
  },
}));

const ButtonWatch = (props) => {
  const { onClick, refFn } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('btn-watch')}
        src={images['button-watch.png']}
        ref={(r) => {
          if (r) {
            refFn && refFn(r);
            clickAnime(r);
          }
        }}
        onClick={onClick}
      />
    </ButtonBase>
  );
};

export default ButtonWatch;
