import { isSafariMobile } from 'utils/utility';
export function clickAnime(element) {
  if (element) {
    element.addEventListener('click', () => {
      element.style.cssText =
        //'transition: all .085s ease;transform: scale(1.05);filter: drop-shadow(0px 4px 2px grey)';
        'transition: all .09s ease;transform: scale(1.05)';
      setTimeout(() => {
        element.style.cssText =
          'transition: all .09s ease;transform: scale(1);filter: none';
      }, 85);
    });
  }
}

export function renderAnime(element) {
  if (element) {
    element.style.cssText =
      //'transition: all .085s ease;transform: scale(1.05);filter: drop-shadow(0px 4px 2px grey)';
      'transition: all .09s ease;transform: scale(1.09)';
    setTimeout(() => {
      element.style.cssText =
        'transition: all .17s ease;transform: scale(1);filter: none';
    }, 90);
  }
}

export function calvh(vh) {
  if (isSafariMobile()) {
    const noise = 10;
    const h = +(vh || '').split('vh')[0];
    //var element = document.querySelector('[root]');
    //if (element) {
    //var scrollHeight = element.scrollHeight;
    // window.alert(
    //   JSON.stringify({
    //     height: window.screen.height,
    //     availHeight: window.screen.availHeight,
    //   })
    // );
    // window.alert(
    //   JSON.stringify({ diff: window.window.outerHeight - window.innerHeight })
    // );
    return `${(h - (noise * h) / 100).toFixed(4)}vh`;
    // } else {
    //   return vh;
    // }
  } else {
    return vh;
  }
}
