import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonQuestion from 'components/fragments/ButtonQuestion/ButtonQuestion';
const ButtonQuestionComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonQuestion/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonQuestion {...props} />
  </ImagesProvider>
);

export default ButtonQuestionComponent;
