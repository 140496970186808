import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { clickAnime, calvh } from 'utils/element';
//import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  'row-container': {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    lineHeight: calvh('5vh'),
    padding: ({ spacing }) => spacing || `${calvh('0.5vh')} 0px`,
  },

  'text-container': {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    //padding: '2vh',
    border: `${calvh('0.5vh')} solid #9b703db3`,
    borderRadius: '2px',
    //backgroundColor: '#f6fff080',
    padding: `0px ${calvh('1vh')}`,
  },
  'text-point': {
    textAlign: 'center',
    flexBasis: '10%',
    fontSize: calvh('4vh'),
    marginLeft: '1.8vw',
    '@media (min-width:450px)': {
      fontSize: calvh('4.5vh'),
    },
    '@media (max-width:450px)': {
      fontSize: calvh('3.2vh'),
    },
  },
  'text-nickname': {
    whiteSpace: 'nowrap',
    fontSize: calvh('3.5vh'),
    '@media (min-width:450px)': {
      fontSize: calvh('4vh'),
    },
    '@media (max-width:450px)': {
      fontSize: calvh('2.8vh'),
    },
  },
  'text-code': {
    whiteSpace: 'nowrap',
    fontSize: calvh('3.5vh'),
    '@media (min-width:450px)': {
      fontSize: calvh('4vh'),
    },
    '@media (max-width:450px)': {
      fontSize: calvh('2.8vh'),
    },
  },
}));

const TrainerList = (props) => {
  const { trainers, parentIdx, point, styles, onClick } = props;
  //const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      {(trainers || []).map((trainer, idx) => {
        return (
          <div
            key={'trainer-row-' + idx}
            className={c('row-container')}
            ref={clickAnime}
            onClick={() => onClick(trainer)}
          >
            {point ? (
              point
            ) : (
              <div className={'text' + ' ' + c('text-point')}>
                {parentIdx ? parentIdx + idx + 1 : idx + 1}
              </div>
            )}

            <div
              className={c('text-container')}
              style={{
                backgroundColor: trainer?.videoDone ? '#04db2c9c' : '#f6fff080',
              }}
            >
              <div
                className={'text' + ' ' + c('text-nickname')}
                style={styles?.item}
              >
                <span>{'ชื่อเล่น '}</span>
                <span
                  style={{ color: '#522402' }}
                >{`${trainer?.nickName}`}</span>
              </div>
              <div className={'text' + ' ' + c('text-code')}>
                <span>{'รหัส '}</span>
                <span style={{ color: '#522402' }}>{`${trainer?.code}`}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TrainerList;
