import axios from '../host';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const handleSuccess = (token) => {
  const curDate = dayjs.utc().local().format();
  localStorage.setItem('loginDate', curDate);
  localStorage.setItem('token', token);
};

export const login = ({ code }, next = null, error = null, complete = null) => {
  axios
    .post('/teacher/sign-in', { code })
    .then((resp) => {
      if (resp?.data?.token) {
        handleSuccess(resp?.data?.token);
        next && next(resp?.data);
      } else {
        error && error({ message: 'Token is not missing' });
      }
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};

export const logout = (next = null, error = null, complete = null) => {
  // axios
  //   .post('/orders/upload-slip', {
  //     orderId,
  //     slip,
  //     customerId,
  //   })
  //   .then((resp) => {
  //     next && next(resp.data)
  //     return
  //   })
  //   .catch((err) => {
  //     error && error(err)
  //   })
  //   .finally(() => {
  //     complete && complete()
  //   })

  setTimeout(() => {
    localStorage.clear();
    next({});
    complete();
  }, 1000);
};
