import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import {
//   Search as SearchIcon,
//   DoubleArrow as DoubleArrowIcon,
//   CheckCircle as CheckCircleIcon
// } from '@material-ui/icons';
import { joinClasses, callService } from 'utils/utility';
import { errorAlert, warningAlert } from 'utils/alert';
import { calvh, clickAnime } from 'utils/element';
import { getStudentPhoto } from 'services/student-photo';
import { useImagesContext } from 'contexts/ImagesContext';
import ButtonBack from 'components/fragments/ButtonBack';
import Progress from 'components/fragments/Progress';
import UploadImage from 'components/fragments/UploadImage';
import { Carousel } from 'antd';
import Share from 'components/fragments/Share';
//import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  'carousel-container': {
    zIndex: 1,
    width: '100%',
    height: 'auto',
    '& [class~="ant-carousel"] [class~="slick-dots"] button': {
      background: '#9798aa !important',
    },
    '& [class~="ant-carousel"] [class~="slick-dots"] [class~="slick-active"] button':
      {
        background: '#adaeb6 !important',
      },
    '& [class~="ant-carousel"] [class~="slick-dots"]': {
      display: 'none !important',
    },
  },
  'label-date': {
    alignSelf: 'flex-start',
    fontSize: calvh('3.8vh'),
    lineHeight: calvh('3.8vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
    //paddingBottom: '5px',
  },
  comment: {
    fontSize: calvh('3.3vh'),
    lineHeight: calvh('3.3vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
    padding: `${calvh('0.5vh')} ${calvh('2vh')}`,
  },
  'container-devider': {
    width: '100%',
    height: '.2rem',
    backgroundColor: '#7d4c08',
  },
  'student-photo-prev': {
    position: 'fixed',
    zIndex: 2,
    left: '91vw',
    top: calvh('9vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'student-photo-next': {
    position: 'fixed',
    zIndex: 2,
    left: '91vw',
    bottom: calvh('44vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 50%',
  },
  'img-save': {
    height: calvh('6vh'),
    maxWidth: '12vw',
    objectFit: 'contain',
    margin: 4,
  },
  'img-student-photo': {
    height: calvh('18vh'),
    width: '46vw',
    objectFit: 'contain',
    margin: 4,
  },
  'img-line': {
    width: '85%',
    height: '0.6vh',
    margin: '0.1rem 0px',
  },
}));

const DEFAULT_PHOTO_PAGE = 0;
const DEFAULT_PHOTO_ROWS_PER_PAGE = 2;

const StudentPhoto = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [getStudentPhotoFlag, setGetStudentPhotoFlag] = React.useState({});
  const [studentPhotoInfo, setStudentPhotoInfo] = React.useState({});
  const [photoPagination, setPhotoPagination] = React.useState({
    rowsPerPage: DEFAULT_PHOTO_ROWS_PER_PAGE,
    page: DEFAULT_PHOTO_PAGE,
  });
  const [sharedData, setSharedData] = React.useState({
    image:
      'https://www.chinadailyhk.com/attachments/image/65/90/96/462950_168247/462950_168247_800_450_jpg.jpg',
  });
  const refPhotoCarousel = React.useRef(null);

  React.useEffect(() => {
    handleGetStudentPhoto((resp) => {
      setSharedData((prev) => ({ ...prev, comment: resp.comment }));
    });
  }, []);

  const handlePhotoCarouselAfterChangePage = (newPage) => {};

  const handleClickPhotoPrev = () => {
    refPhotoCarousel.current && refPhotoCarousel.current.prev();
    if (photoPagination?.page > 0) {
      setPhotoPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setPhotoPagination((prev) => ({
        ...prev,
        page:
          Math.ceil(
            (studentPhotoInfo.rowS || []).length / DEFAULT_PHOTO_ROWS_PER_PAGE
          ) - 1,
      }));
    }
  };

  const handleClickPhotoNext = () => {
    refPhotoCarousel.current && refPhotoCarousel.current.next();
    if (
      photoPagination?.page <
      Math.ceil(
        (studentPhotoInfo.rows || []).length / DEFAULT_PHOTO_ROWS_PER_PAGE
      ) -
        1
    ) {
      setPhotoPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    } else {
      setPhotoPagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const handleChangeUploadImage = (data) => {
    setSharedData((prev) => ({ ...prev, image: data }));
  };

  const handleGetStudentPhoto = (next) => {
    callService(
      getStudentPhoto,
      {},
      setGetStudentPhotoFlag,
      (resp) => {
        setStudentPhotoInfo(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const pendingFlag = React.useMemo(
    () => getStudentPhotoFlag.request,
    [getStudentPhotoFlag.request]
  );

  const successFlag = React.useMemo(
    () => getStudentPhotoFlag.success,
    [getStudentPhotoFlag.success]
  );

  return (
    <div className={c('root')}>
      <Grid
        container
        direction='column'
        //justifyContent='space-between'
        alignItems='center'
        style={{ height: '100%' }}
      >
        <Grid
          item
          container
          justifyContent='center'
          alignItems='flex-start'
          alignContent='flex-start'
          style={{ marginTop: '0.5rem' }}
        >
          {pendingFlag ? (
            <Progress />
          ) : successFlag ? (
            <>
              <Grid item xs={11} container>
                <img
                  style={{
                    height: calvh('4vh'),
                    maxWidth: '28vw',
                    objectFit: 'contain',
                  }}
                  src={images[`student-photo-photo.png`]}
                />
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent='center'
                  style={{ padding: '5px 0px' }}
                >
                  <div className={c('container-devider')} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={11}
                container
                style={{
                  // height: calvh('79vh'),
                  // overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <div className={c('carousel-container')}>
                  <Carousel
                    afterChange={handlePhotoCarouselAfterChangePage}
                    ref={refPhotoCarousel}
                    dotPosition='right'
                  >
                    {Array.from(
                      Array(
                        Math.ceil(
                          (studentPhotoInfo.rows || []).length /
                            DEFAULT_PHOTO_ROWS_PER_PAGE
                        )
                      ).keys()
                    ).map((_, pidx) => {
                      const page = pidx;
                      const pageSize = photoPagination.rowsPerPage;
                      const trainersFilter = (
                        studentPhotoInfo.rows || []
                      ).slice(page * pageSize, page * pageSize + pageSize);
                      return (
                        <div key={`photo-page-` + pidx}>
                          <div
                            style={{
                              height: calvh('42vh'),
                              overflow: 'auto',
                            }}
                          >
                            {(trainersFilter || []).map((photo, idx) => (
                              <div
                                key={'photo_' + idx}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexFlow: 'column',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <div className={c('label-date')}>
                                    {photo.date}
                                  </div>
                                  <img
                                    className={c('img-student-photo')}
                                    src={photo.image}
                                  />
                                  <img
                                    className={c('img-save')}
                                    src={images[`student-photo-save.png`]}
                                    ref={clickAnime}
                                  />
                                </div>

                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justifyContent='center'
                                  style={{ padding: '5px 0px' }}
                                >
                                  <div className={c('container-devider')} />
                                </Grid>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                <img
                  className={c('student-photo-prev')}
                  src={images['student-photo-prev.png']}
                  onClick={handleClickPhotoPrev}
                  ref={clickAnime}
                />
                <img
                  className={c('student-photo-next')}
                  src={images['student-photo-next.png']}
                  onClick={handleClickPhotoNext}
                  ref={clickAnime}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <Grid item container justifyContent='center'>
          <Grid item container justifyContent='center'>
            <img
              className={c('img-line')}
              src={images['student-photo-line02.png']}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent='center'
            //style={{ height: calvh('25vh') }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'center',
              }}
            >
              <img className={c('img-student-photo')} src={sharedData?.image} />
              <UploadImage
                noLabel
                styles={{
                  inputContainer: { width: '30vw' },
                  button: { height: calvh('7vh') },
                }}
                onChange={handleChangeUploadImage}
              />
            </Grid>
            <Grid item>
              <div className={c('comment')}>{sharedData?.comment}</div>
            </Grid>
          </Grid>
          <Grid item container justifyContent='center'>
            <img
              className={c('img-line')}
              src={images['student-photo-line02.png']}
            />
          </Grid>
          <Grid item container justifyContent='center'>
            <Share data={sharedData} />
          </Grid>
        </Grid>

        <div style={{ position: 'absolute', bottom: '-4vh', zIndex: 2 }}>
          <ButtonBack />
        </div>
      </Grid>
    </div>
  );
};

export default StudentPhoto;
