import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService, checkValidity } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { getTrainerSchedule } from 'services/schedule';
import { editTrainerInfo } from 'services/trainer';
import { useImagesContext } from 'contexts/ImagesContext';
import { useDispatch, useSelector } from 'react-redux';
import Progress from 'components/fragments/Progress';
import ButtonSave from 'components/fragments/ButtonSave';
import ButtonAddLine from 'components/fragments/ButtonAddLine';
import ScheduleTable from 'components/fragments/ScheduleTable';
import TextField from 'components/fragments/TextField';
import SuccessAlert from 'components/fragments/SuccessAlert';
import * as decisionActions from 'actions/decision';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
    },
  },
  'text-title': {
    lineHeight: calvh('5vh'),
    textAlign: 'center',
    padding: 2,
    color: '#009420',
    fontSize: calvh('7vh'),
    margin: 'auto',
    '@media (max-width:434px)': {
      fontSize: calvh('6.5vh'),
    },
  },
  'text-sub-title': {
    textAlign: 'center',
    padding: 2,
    color: 'red',
    fontSize: calvh('3vh'),
    margin: 'auto',
    flexBasis: '100%',
    '@media (max-width:434px)': {
      fontSize: calvh('2.5vh'),
    },
    lineHeight: calvh('2vh'),
  },
  'text-suggestion': {
    textAlign: 'center',
    padding: 2,
    color: '#009420',
    fontSize: calvh('3.5vh'),
    margin: 'auto',
    flexBasis: '100%',
    '@media (max-width:434px)': {
      fontSize: calvh('2.8vh'),
    },
    lineHeight: calvh('4vh'),
  },
  'container-devider': {
    width: '95%',
    height: calvh('0.25vh'),
    backgroundColor: '#6c2912',
  },
  'img-field-tel': {
    height: calvh('4vh'),
    maxWidth: '29vw',
    objectFit: 'contain',
  },
  'img-field-name': {
    height: calvh('6vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
  },
  'img-field-nickname': {
    height: calvh('4vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
  },
  'img-schedule-concern': {
    height: calvh('5vh'),
    maxWidth: '50vw',
    objectFit: 'contain',
    paddingLeft: '1vw',
  },
}));

const Schedule = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [getTrainerScheduleFlag, setGetTrainerScheduleFlag] = React.useState(
    {}
  );
  const [editTrainerInfoFlag, setEditTrainerInfoFlag] = React.useState({});
  const [schedule, setSchedule] = React.useState([]);
  const [updateSchedule, setUpdateSchedule] = React.useState([]);
  const [editTrainerInfoResp, setEditTrainerInfoResp] =
    React.useState(undefined);

  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const dispatch = useDispatch();
  const trainerInfo = useSelector((state) => state?.trainer?.trainerInfo);
  const [form, setForm] = React.useState({
    phoneNumber: trainerInfo?.phoneNumber,
    name: trainerInfo?.name,
    nickName: trainerInfo?.nickName,
  });
  //console.log('trainerInfo:', trainerInfo);
  React.useEffect(() => {
    handleGetTrainerScheduleService();
  }, []);

  const handleChangeSchedule = (updateSchedule) => {
    setUpdateSchedule(updateSchedule);
  };

  const handleValidate = () => {
    let valid = true;
    let message = '';
    console.log('form:::', form);
    if (!checkValidity(form['phoneNumber'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุหมายเลขโทรศัพท์';
    } else if (!checkValidity(form['phoneNumber'], { isPhoneNumber: true })) {
      valid = false;
      message = 'กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง';
    } else if (!checkValidity(form['name'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุชื่อ';
    } else if (!checkValidity(form['nickName'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุชื่อเล่น';
    }
    return { valid, message };
  };

  const handleClickSave = () => {
    //dispatch(decisionActions.setActionState('editTrainerInfo', true));
    const { valid, message } = handleValidate();
    if (valid) {
      handleEditTrainerInfoService(() => {
        handleOpenSuccessAlert(true);
        dispatch(decisionActions.setActionState('editTrainerInfo', true));
      });
    } else {
      warningAlert(undefined, message);
    }
  };

  const handleClickAddLine = () => {};

  const handleChangeForm = (event, field) => {
    const value = event.target.value;
    console.log({ value: event.target.value, field });
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleOpenSuccessAlert = (value) => {
    setOpenSuccessAlert(value);
  };

  const handleGetTrainerScheduleService = (next) => {
    callService(
      getTrainerSchedule,
      { id: trainerInfo?.id },
      setGetTrainerScheduleFlag,
      (resp) => {
        setSchedule(resp);
        setUpdateSchedule(resp);
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleEditTrainerInfoService = (next) => {
    callService(
      editTrainerInfo,
      { ...form, id: trainerInfo?.id, schedule: updateSchedule },
      setEditTrainerInfoFlag,
      (resp) => {
        setEditTrainerInfoResp(resp);
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleError = (error) => {
    errorAlert(error, (result) => {
      if (result.isConfirmed) {
        if (error.response.status === 401) {
          dispatch(decisionActions.setActiveState('login'));
        }
      } else if (result.isDenied) {
      }
    });
  };

  const pendingFlag = React.useMemo(
    () => getTrainerScheduleFlag.request,
    [getTrainerScheduleFlag.request]
  );

  const successFlag = React.useMemo(
    () => getTrainerScheduleFlag.success,
    [getTrainerScheduleFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} container justifyContent='center'>
            <p className={'text' + ' ' + c('text-title')}>{'ลงตารางเวลา'}</p>
            <p className={c('text-sub-title')}>
              {'(**PATHTIME เท่านั้น หากประจำไม่ต้องลง)'}
            </p>
            <p className={c('text-suggestion')}>
              {'กดช่อง ช่วงเวลาที่สะดวกสอนให้ขึ้นแถบสีเขียว'}
            </p>
          </Grid>

          <Grid item xs={12}>
            <ScheduleTable
              schedule={schedule}
              onChange={handleChangeSchedule}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
              <TextField
                label={
                  <img
                    className={c('img-field-tel')}
                    src={images['schedule-tel.png']}
                  />
                }
                styles={{
                  inputMaxWidth: calvh('30vh'),
                  fontSize: calvh('3vh'),
                  fontSizeSm: calvh('2.6vh'),
                  inputMargin: calvh('0.5vh'),
                  inputHeight: calvh('3vh'),
                }}
                onChange={(e) => handleChangeForm(e, 'phoneNumber')}
                value={form['phoneNumber']}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'flex-start',
              }}
            >
              <TextField
                label={
                  <img
                    className={c('img-field-name')}
                    src={images['schedule-name.png']}
                  />
                }
                //helperText={'ไม่ต้องใส่นามสกุล'}
                styles={{
                  inputMaxWidth: calvh('22vh'),
                  fontSize: calvh('3vh'),
                  fontSizeSm: calvh('2.6vh'),
                  inputMargin: calvh('0.5vh'),
                  inputHeight: calvh('3vh'),
                }}
                onChange={(e) => handleChangeForm(e, 'name')}
                value={form['name']}
              />
              <TextField
                label={
                  <img
                    className={c('img-field-nickname')}
                    src={images['schedule-nickname.png']}
                  />
                }
                styles={{
                  inputMaxWidth: calvh('18vh'),
                  fontSize: calvh('3vh'),
                  fontSizeSm: calvh('2.6vh'),
                  inputMargin: calvh('0.5vh'),
                  inputHeight: calvh('3vh'),
                }}
                onChange={(e) => handleChangeForm(e, 'nickName')}
                value={form['nickName']}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: `${calvh('1vh')} 0px 0px 0px` }}
          >
            <div className={c('container-devider')} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{
              padding: `${calvh('1vh')} ${calvh('1vh')} 0px ${calvh('1vh')}`,
              flexWrap: 'nowrap',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                //justifyContent: 'space-between',
                alignItems: 'center',
                height: calvh('8vh'),
              }}
            >
              <ButtonAddLine
                onClick={handleClickAddLine}
                height={calvh('6vh')}
                maxWidth={'32vw'}
              />

              <img
                className={c('img-schedule-concern')}
                src={images['schedule-concern.png']}
              />
            </div>
          </Grid>
          {/* <Grid item xs={12} container justifyContent='center'>
            <img
              className={c('img-schedule-concern')}
              src={images['schedule-concern.png']}
            />
          </Grid> */}
        </Grid>
      ) : null}
      {editTrainerInfoFlag.request ? <Progress /> : null}
      {openSuccessAlert ? (
        <SuccessAlert onClose={() => handleOpenSuccessAlert(false)} />
      ) : null}
      <ButtonSave
        styles={{
          root: {
            position: 'absolute',
            bottom: calvh('-3.5vh'),
            left: 2,
            zIndex: 2,
          },
        }}
        onClick={handleClickSave}
        height={calvh('6vh')}
        disabled={editTrainerInfoFlag.request}
      />
    </div>
  );
};

export default Schedule;
