import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import Table from 'components/fragments/Table/Table';
const TableComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/Table/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Table {...props} />
  </ImagesProvider>
);

export default TableComponent;
