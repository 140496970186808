import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
//import { useImagesContext } from 'contexts/ImagesContext';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}));

const Video = (props) => {
  const { value, type, onAlmostFinished } = props;
  //const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const refVideo = React.useRef(null);
  const [trigger, setTrigger] = useState(false);

  React.useEffect(() => {
    console.log('ccccc:', value);
    if (refVideo.current) {
      refVideo.current.removeEventListener(
        'timeupdate',
        listenerVidoeAlmostFinished
      );
    }
    setTrigger(false);
    setTimeout(() => {
      setTrigger(true);
    }, 500);
  }, [value]);

  React.useEffect(() => {
    return () => {
      refVideo.current.removeEventListener(
        'timeupdate',
        listenerVidoeAlmostFinished
      );
    };
  }, []);

  const listenerVidoeAlmostFinished = React.useCallback(() => {
    // console.log('listenerVidoeAlmostFinished:', {
    //   currentTime: refVideo.current.currentTime,
    //   duration: refVideo.current.duration - 5,
    // });
    if (refVideo.current.currentTime >= refVideo.current.duration - 5) {
      onAlmostFinished && onAlmostFinished();
    }
  }, [trigger]);

  const handleListenVidoeAlmostFinished = React.useCallback(() => {
    refVideo.current.addEventListener(
      'timeupdate',
      listenerVidoeAlmostFinished
    );
  }, [trigger]);

  return (
    <div className={c('root')}>
      {trigger && type === 'YOUTUBE' ? (
        <iframe
          width='100%'
          height='100%'
          src={`https://www.youtube.com/embed/${value}?rel=0`}
        ></iframe>
      ) : trigger ? (
        <video
          //width='100%'
          height='100%'
          controls
          autoPlay
          style={{
            maxWidth: '100%',
            objectFit: 'fill',
          }}
          src={value}
          ref={(ref) => {
            if (ref && !refVideo.current) {
              refVideo.current = ref;
              handleListenVidoeAlmostFinished();
            }
          }}
        ></video>
      ) : null}
    </div>
  );
};

export default Video;
