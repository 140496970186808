import React, { createContext, useState, useContext, useEffect } from 'react';

import imgLoading from '../images/circle-loading-animation.gif';

export const ImagesContext = createContext({
  images: {},
});

export const useImagesContext = () => useContext(ImagesContext);

export const ImagesProvider = (props) => {
  const [images, setImages] = useState({});
  const [imagesReadyCnt, setImagesReadyCnt] = useState(0);
  const { r } = props;

  useEffect(() => {
    const importedImages = {};
    let i = 0;
    r.keys().forEach((item) => {
      const importedImg = r(item);
      const path = item.replace(/(.*\/).*/g, (m, g1) => g1);
      importedImages[item.replace(path, '')] = importedImg;
      i++;
      setImagesReadyCnt(i);
    });
    setImages(importedImages);
  }, [r]);

  return Object.keys(images).length !== imagesReadyCnt ? (
    <img src={imgLoading} onContextMenu={(e) => e.preventDefault()} />
  ) : (
    <ImagesContext.Provider value={{ images }}>
      {props.children}
    </ImagesContext.Provider>
  );
};

export const ImagesConsumer = ImagesContext.Consumer;
