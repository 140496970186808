import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import PageNumber from 'components/fragments/PageNumber/PageNumber';
const PageNumberComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/PageNumber/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <PageNumber {...props} />
  </ImagesProvider>
);

export default PageNumberComponent;
