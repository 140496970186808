import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useImagesContext } from 'contexts/ImagesContext';
import { joinClasses, callService } from 'utils/utility';
import { clickAnime } from 'utils/element';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { logout } from 'services/auth';
import Table from 'components/fragments/Table';
import { Carousel } from 'antd';
import ButtonBack from 'components/fragments/ButtonBack';
import Frame from 'components/fragments/Frame';
import Progress from 'components/fragments/Progress';
import { getStudentSummary } from 'services/student-summary';

const tableRoundCellHeight = calvh('11.8vh');
const tableRoundCellWidth = `${(100 / 3).toFixed(2)}%`;
const tableRecordCellHeight = calvh('15.5vh');
const tableRecordCellWidth = `${(100 / 3).toFixed(2)}%`;
const imgRecordGutterHorTop = calvh('1.5vh');

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('100vh'),
    overflow: 'visible',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  'content-container': {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('80vh'),
  },
  'score-summary-container': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '30vw',
    height: '100%',
    //paddingRight: '1.25vw',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRightWidth: '1.25vw',
  },
  'student-contender-nav-container': {
    width: '90%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
  },
  'student-contender-nav-next': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'student-contender-nav-prev': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'student-winlose-container': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  'img-student-winlose-box': {
    height: 'fit-content',
    maxHeight: calvh('10.5vh'),
    maxWidth: '22vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-win': {
    height: 'fit-content',
    maxHeight: calvh('2.8vh'),
    maxWidth: '12vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
    margin: '0.25rem 0px 0.25rem 0px',
  },
  'img-student-lose': {
    height: 'fit-content',
    maxHeight: calvh('2.8vh'),
    maxWidth: '14vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
    margin: '0.25rem 0px 0.25rem 0px',
  },
  'student-winlose-box-container': {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  'student-winlose-number-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  'student-winlose-number': {
    width: '100%',
    fontSize: calvh('8vh'),
    color: '#fff',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('-0.1vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.25vh'
    )} ${calvh('0.25vh')} 0px`,
    textAlign: 'center',
    lineHeight: calvh('8vh'),
    paddingBottom: calvh('1vh'),
  },
  'student-summary-container': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.25rem',
  },
  'img-student-summary-score': {
    height: 'fit-content',
    maxHeight: calvh('12vh'),
    maxWidth: '22vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'student-back-container': {
    display: 'flex',
    justifyContent: 'center',
  },
  'score-detail-container': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70vw',
    height: '100%',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderLeftWidth: '1.25vw',
    borderRightWidth: '2.5vw',
    // '&::before': {
    //   content: `''`,
    //   width: '1.25vw',
    // },
    // '&::after': {
    //   width: '2.5vw',
    // },
    // paddingLeft: '1.25vw',
    // paddingRight: '2.5vw',
    //marginLeft: '0.2vw',
  },
  'round-container': {
    display: 'flex',
    height: '34%',
    width: '100%',
    position: 'relative',
  },
  'round-nevigate-container': {
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 500,
    display: 'flex',
    justifyContent: 'space-around',
    width: '70vw',
    height: calvh('5vh'),
  },
  'carousel-container': {
    width: '100%',
    height: 'auto',
    '& [class~="ant-carousel"] [class~="slick-dots"] button': {
      background: '#9798aa !important',
    },
    '& [class~="ant-carousel"] [class~="slick-dots"] [class~="slick-active"] button':
      {
        background: '#adaeb6 !important',
      },
    '& [class~="ant-carousel"] [class~="slick-dots"]': {
      display: 'none !important',
    },
  },
  'student-summary-round-prev': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'round-number': {
    display: 'flex',
    alignItems: 'center',
    fontSize: calvh('5vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
  },
  'student-summary-round-next': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'student-summary-record-prev': {
    position: 'absolute',
    right: 0,
    top: calvh('-2.5vh'),
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
    zIndex: 20,
  },
  'student-summary-record-next': {
    position: 'fixed',
    right: '2vw',
    bottom: calvh('24vh'),
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
    zIndex: 20,
  },
  'record-container': {
    display: 'flex',
    height: '66%',
    width: '100%',
    position: 'relative',
  },
  'record-carousel-page-container': {
    //height: '44vh',
  },
  'table-wrap': {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  'table-round-gutter-hor': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'student-summary-line02': {
    width: '100%',
    height: calvh('0.5vh'),
  },
  'img-round-gutter-hor-top': {
    width: '100%',
    height: calvh('1vh'),
  },
  'round-gutter-hor-wrap': {
    display: 'flex',
    alignItems: 'flex-end',
    height: tableRoundCellHeight,
    width: '100%',
  },
  'table-round-gutter-ver': {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'student-summary-line01': {
    width: calvh('0.5vh'),
    height: '100%',
  },
  'round-gutter-ver-wrap': {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    width: tableRoundCellWidth,
  },
  'table-record-gutter-hor': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'img-record-gutter-hor-top': {
    width: '100%',
    height: imgRecordGutterHorTop,
    zIndex: 10,
  },
  'record-gutter-ver-wrap': {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    width: tableRecordCellWidth,
  },
  'record-gutter-hor-wrap': {
    display: 'flex',
    //alignItems: 'flex-end',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: tableRecordCellHeight,
    width: '100%',
  },
  'table-record-gutter-ver': {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'footer-container': {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: calvh('20vh'),
  },
  'footer-content-container': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    height: '95%',
    width: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 8,
    overflow: 'hidden',
  },
  'misssion-container': {},
  'point-container': {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    width: '90%',
  },
  'point-content-container': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  'img-student-summary-20p': {
    height: 'fit-content',
    maxHeight: calvh('11vh'),
    maxWidth: '25vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'point-content-wrap': {
    position: 'relative',
  },
  'student-summary-recieve-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'img-student-summary-recieve': {
    height: 'fit-content',
    maxHeight: calvh('10vh'),
    maxWidth: '25vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-win5': {
    height: 'fit-content',
    maxHeight: calvh('6vh'),
    marginTop: -4,
    maxWidth: '22vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-50p': {
    height: 'fit-content',
    maxHeight: calvh('11vh'),
    maxWidth: '25vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-winteacher': {
    height: 'fit-content',
    maxHeight: calvh('6vh'),
    marginTop: -4,
    maxWidth: '22vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-100p': {
    height: 'fit-content',
    maxHeight: calvh('11vh'),
    maxWidth: '25vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-win20': {
    height: 'fit-content',
    maxHeight: calvh('6vh'),
    marginTop: -4,
    maxWidth: '22vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-student-summary-mission01': {
    height: 'fit-content',
    maxHeight: calvh('3vh'),
    maxWidth: '24vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'student-summary-box-label-container': {
    marginLeft: -2,
    height: calvh('2.2vh'),
    width: '96%',
    position: 'relative',
  },
  'student-summary-box-label': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
  },
  'student-summary-box-label-number-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: calvh('4vh'),
    fontWeight: 600,
    paddingBottom: '0.5vh',
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('5.2vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar': {
    position: 'fixed',
    top: 0,
    left: '28.5vw',
    zIndex: 2,
    width: '2.5vw',
    height: calvh('77vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2,
    width: '2.5vw',
    height: calvh('77vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('5.2vh'),
    maxHeight: '4rem',
    zIndex: 3,
  },
}));

const TableRound = (props) => {
  const { ckey, rows } = props;
  const c = joinClasses(useStyles({ ...props }));
  const { images } = useImagesContext();
  return (
    <div className={c('table-wrap')} key={ckey}>
      <div className={c('table-round-gutter-hor')}>
        {Array.from(Array(Object.keys(rows).length).keys()).map((i, idx) => (
          <div className={c('round-gutter-hor-wrap')} key={'gutter-hor-' + idx}>
            <img
              className={c('student-summary-line02')}
              src={images['student-summary-line02.png']}
            />
          </div>
        ))}
      </div>
      <div className={c('table-round-gutter-ver')}>
        {Array.from(Array(Object.keys(rows[0] || {}).length).keys()).map(
          (i, idx) => (
            <div
              className={c('round-gutter-ver-wrap')}
              key={'gutter-ver-' + idx}
            >
              <img
                className={c('student-summary-line01')}
                src={images['student-summary-line01.png']}
              />
            </div>
          )
        )}
      </div>
      <Table
        noColumnHeader
        noHeader
        styles={{
          width: '100%',
          row: {
            // width: 'min-content',
          },
          // border: {
          //   width: calvh('0.1vh'),
          //   color: 'transparent',
          // },
          column: {
            // widths: [`100%`],
          },
          cell: {
            // minWidths: ['30%'],
            width: tableRoundCellWidth,
          },
          header: {
            cell: {
              width: calvh('12vh'),
            },
          },
        }}
        rows={rows}
      />
    </div>
  );
};

const TableRecord = (props) => {
  const { ckey, rows } = props;
  const c = joinClasses(useStyles({ ...props }));
  const { images } = useImagesContext();

  const dummyLen = rows.filter((r) => Object.keys(r).includes('dummy')).length;

  return (
    <div className={c('table-wrap')} key={ckey}>
      <div className={c('table-record-gutter-hor')}>
        {Array.from(Array(Object.keys(rows).length).keys())
          .map((i, idx) => {
            if (Object.keys(rows[idx] || {})[0] !== 'dummy') {
              return (
                <div className={c('record-gutter-hor-wrap')}>
                  <img
                    className={c('img-record-gutter-hor-top')}
                    src={images['student-summary-top.png']}
                  />
                  <img
                    className={c('student-summary-line02')}
                    src={images['student-summary-line02.png']}
                  />
                </div>
              );
            } else {
              return null;
            }
          })
          .filter((i) => !!i)}
      </div>
      <div
        className={c('table-record-gutter-ver')}
        style={{
          height: `${(
            100 -
            (100 * dummyLen) / DEFAULT_RECORD_ROWS_PER_PAGE
          ).toFixed(2)}%`,
        }}
      >
        {Array.from(Array(Object.keys(rows[0] || {}).length).keys()).map(
          (i) => (
            <div className={c('record-gutter-ver-wrap')}>
              <img
                className={c('student-summary-line01')}
                src={images['student-summary-line01.png']}
              />
            </div>
          )
        )}
      </div>

      <Table
        noColumnHeader
        noHeader
        styles={{
          width: '100%',
          row: {
            // width: 'min-content',
          },
          // border: {
          //   width: calvh('0.1vh'),
          //   color: 'transparent',
          // },
          column: {
            // widths: [`100%`],
          },
          cell: {
            // minWidths: ['30%'],
            width: tableRecordCellWidth,
          },
          header: {
            cell: {
              width: calvh('12vh'),
            },
          },
        }}
        rows={rows}
      />
    </div>
  );
};
const DEFAULT_STUDENT_CONTENDER_PAGE = 0;
const DEFAULT_STUDENT_CONTENDER_ROWS_PER_PAGE = 1;
const DEFAULT_ROUND_PAGE = 0;
const DEFAULT_ROUND_ROWS_PER_PAGE = 2;
const DEFAULT_RECORD_PAGE = 0;
const DEFAULT_RECORD_ROWS_PER_PAGE = 3;

const StudentSummary = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [getStudentSummaryFlag, setGetStudentSummaryFlag] = React.useState({});
  const [studentSummary, setStudentSummary] = React.useState(undefined);
  const store = useSelector((state) => state);
  const [studentContenderRows, setStudentContenderRows] = React.useState([
    { name: 'name1' },
    { name: 'name2' },
  ]);

  React.useEffect(() => {
    handleGetStudentSummaryService();
  }, []);

  const handleGetStudentSummaryService = (next) => {
    callService(
      getStudentSummary,
      {},
      setGetStudentSummaryFlag,
      (resp) => {
        setStudentSummary(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const roundRows = React.useMemo(
    () => [
      {
        field1: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              //width: tableRoundCellWidth,
              height: tableRoundCellHeight,
              backgroundColor: 'red',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Frame
              type={'teacher'}
              styles={{
                margin: '-4px 0px 0px 0px',
                frame: {
                  maxHeight: calvh('10.8vh'),
                  maxWidth: '20vw',
                },
              }}
              images={images}
              winFlag={false}
            />
          </div>
        ),
        field2: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'blue',
            }}
          >
            2
          </div>
        ),
        field3: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'green',
            }}
          >
            3
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'red',
            }}
          >
            4
          </div>
        ),
        field2: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'blue',
            }}
          >
            5
          </div>
        ),
        field3: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'green',
            }}
          >
            6
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'red',
            }}
          >
            7
          </div>
        ),
        field2: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'blue',
            }}
          >
            8
          </div>
        ),
        field3: (
          <div
            height={tableRoundCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRoundCellHeight,
              backgroundColor: 'green',
            }}
          >
            9
          </div>
        ),
      },
    ],
    [images]
  );
  const recordRows = React.useMemo(() => {
    const rows = [
      {
        field1: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'red',
                alignSelf: 'flex-end',
                justifyContent: 'center',
                alignItems: 'baseline',
                flexWrap: 'wrap',
              }}
            >
              <div className={c('student-summary-box-label-container')}>
                <img
                  className={c('student-summary-box-label')}
                  src={images['student-summary-box-label.png']}
                />
                <div
                  className={c('student-summary-box-label-number-container')}
                >
                  1
                </div>
              </div>

              <Frame
                type={'teacher'}
                styles={{
                  margin: '0px 0px 0px -2px',
                  frame: {
                    maxHeight: calvh('10.8vh'),
                    maxWidth: '20vw',
                  },
                  pic: {
                    width: calvh('10vh'),
                    height: 'fit-content',
                  },
                }}
                images={images}
                winFlag={true}
                pic={
                  'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                }
              />
            </div>
          </div>
        ),
        field2: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'blue',
                alignSelf: 'flex-end',
              }}
            >
              2
            </div>
          </div>
        ),
        field3: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'green',
                alignSelf: 'flex-end',
              }}
            >
              3
            </div>
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'red',
                alignSelf: 'flex-end',
              }}
            >
              4
            </div>
          </div>
        ),
        field2: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'blue',
                alignSelf: 'flex-end',
              }}
            >
              5
            </div>
          </div>
        ),
        field3: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'green',
                alignSelf: 'flex-end',
              }}
            >
              6
            </div>
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'red',
                alignSelf: 'flex-end',
              }}
            >
              7
            </div>
          </div>
        ),
        field2: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'blue',
                alignSelf: 'flex-end',
              }}
            >
              8
            </div>
          </div>
        ),
        field3: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'green',
                alignSelf: 'flex-end',
              }}
            >
              9
            </div>
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'red',
                alignSelf: 'flex-end',
              }}
            >
              10
            </div>
          </div>
        ),
        field2: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'blue',
                alignSelf: 'flex-end',
              }}
            >
              11
            </div>
          </div>
        ),
        field3: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'green',
                alignSelf: 'flex-end',
              }}
            >
              12
            </div>
          </div>
        ),
      },
      {
        field1: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'red',
                alignSelf: 'flex-end',
              }}
            >
              13
            </div>
          </div>
        ),
        field2: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'blue',
                alignSelf: 'flex-end',
              }}
            >
              14
            </div>
          </div>
        ),
        field3: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: `calc(${tableRecordCellHeight} - ${imgRecordGutterHorTop})`,
                backgroundColor: 'green',
                alignSelf: 'flex-end',
              }}
            >
              15
            </div>
          </div>
        ),
      },
    ];

    const padNum = Math.round((9 - (rows.length % 9)) / 3);
    Array.from(Array(padNum).keys()).forEach((i) => {
      rows.push({
        dummy: (
          <div
            height={tableRecordCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableRecordCellHeight,
            }}
          ></div>
        ),
      });
    });
    return rows;
  }, [images]);
  const [studentContenderRowsFilter, setStudentContenderRowsFilter] =
    React.useState([]);
  const [roundRowsFilter, setRoundRowsFilter] = React.useState([]);
  const [recordRowsFilter, setRecordRowsFilter] = React.useState([]);
  const [studentContenderPagination, setStudentContenderPagination] =
    React.useState({
      rowsPerPage: DEFAULT_STUDENT_CONTENDER_ROWS_PER_PAGE,
      page: DEFAULT_STUDENT_CONTENDER_PAGE,
    });
  const [roundPagination, setRoundPagination] = React.useState({
    rowsPerPage: DEFAULT_ROUND_ROWS_PER_PAGE,
    page: DEFAULT_ROUND_PAGE,
  });
  const [recordPagination, setRecordPagination] = React.useState({
    rowsPerPage: DEFAULT_RECORD_ROWS_PER_PAGE,
    page: DEFAULT_RECORD_PAGE,
  });
  const refStudentContenderCarousel = React.useRef(null);
  const refRoundCarousel = React.useRef(null);
  const refRecordCarousel = React.useRef(null);
  const refStudentContenderRows = React.useRef(null);
  const refRoundRows = React.useRef(null);
  const refRecordRows = React.useRef(null);

  React.useEffect(() => {
    refStudentContenderRows.current = studentContenderRows;
    setStudentContenderPagination((prev) => ({ ...prev }));
  }, [studentContenderRows]);

  React.useEffect(() => {
    refRoundRows.current = roundRows;
    setRoundPagination((prev) => ({ ...prev }));
  }, [roundRows]);

  React.useEffect(() => {
    refRecordRows.current = recordRows;
    setRecordPagination((prev) => ({ ...prev }));
  }, [recordRows]);

  React.useEffect(() => {
    const page = studentContenderPagination.page;
    const pageSize = studentContenderPagination.rowsPerPage;
    const trainersFilter = (refStudentContenderRows.current || []).slice(
      page * pageSize,
      page * pageSize + pageSize
    );
    setStudentContenderRowsFilter(trainersFilter);
  }, [studentContenderPagination]);

  React.useEffect(() => {
    const page = roundPagination.page;
    const pageSize = roundPagination.rowsPerPage;
    const trainersFilter = (refRoundRows.current || []).slice(
      page * pageSize,
      page * pageSize + pageSize
    );
    setRoundRowsFilter(trainersFilter);
  }, [roundPagination]);

  React.useEffect(() => {
    const page = recordPagination.page;
    const pageSize = recordPagination.rowsPerPage;
    const trainersFilter = (refRecordRows.current || []).slice(
      page * pageSize,
      page * pageSize + pageSize
    );
    setRecordRowsFilter(trainersFilter);
  }, [recordPagination]);

  const handleStudentContenderCarouselAfterChangePage = (newPage) => {};
  const handleRoundCarouselAfterChangePage = (newPage) => {};
  const handleRecordCarouselAfterChangePage = (newPage) => {};

  const handleClickStudentContenderPrev = () => {
    refStudentContenderCarousel.current &&
      refStudentContenderCarousel.current.prev();
    if (studentContenderPagination?.page > 0) {
      setStudentContenderPagination((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    } else {
      setStudentContenderPagination((prev) => ({
        ...prev,
        page:
          Math.ceil(
            (studentContenderRows || []).length /
              DEFAULT_STUDENT_CONTENDER_ROWS_PER_PAGE
          ) - 1,
      }));
    }
  };

  const handleClickStudentContenderNext = () => {
    refStudentContenderCarousel.current &&
      refStudentContenderCarousel.current.next();
    if (
      studentContenderPagination?.page <
      Math.ceil(
        (studentContenderRows || []).length /
          DEFAULT_STUDENT_CONTENDER_ROWS_PER_PAGE
      ) -
        1
    ) {
      setStudentContenderPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setStudentContenderPagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const handleClickRoundPrev = () => {
    refRoundCarousel.current && refRoundCarousel.current.prev();
    if (roundPagination?.page > 0) {
      setRoundPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setRoundPagination((prev) => ({
        ...prev,
        page:
          Math.ceil((roundRows || []).length / DEFAULT_ROUND_ROWS_PER_PAGE) - 1,
      }));
    }
  };

  const handleClickRoundNext = () => {
    refRoundCarousel.current && refRoundCarousel.current.next();
    if (
      roundPagination?.page <
      Math.ceil((roundRows || []).length / DEFAULT_ROUND_ROWS_PER_PAGE) - 1
    ) {
      setRoundPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    } else {
      setRoundPagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const handleClickRecordPrev = () => {
    refRecordCarousel.current && refRecordCarousel.current.prev();
    if (recordPagination?.page > 0) {
      setRecordPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setRecordPagination((prev) => ({
        ...prev,
        page:
          Math.ceil((recordRows || []).length / DEFAULT_RECORD_ROWS_PER_PAGE) -
          1,
      }));
    }
  };

  const handleClickRecordNext = () => {
    refRecordCarousel.current && refRecordCarousel.current.next();
    if (
      recordPagination?.page <
      Math.ceil((recordRows || []).length / DEFAULT_RECORD_ROWS_PER_PAGE) - 1
    ) {
      setRecordPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    } else {
      setRecordPagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const content = () => {
    return (
      <>
        <div className={c('score-summary-container')}>
          <div className={c('carousel-container')}>
            <Carousel
              afterChange={handleStudentContenderCarouselAfterChangePage}
              ref={refStudentContenderCarousel}
            >
              {Array.from(
                Array(
                  Math.ceil(
                    (studentContenderRows || []).length /
                      DEFAULT_STUDENT_CONTENDER_ROWS_PER_PAGE
                  )
                ).keys()
              ).map((_, pidx) => {
                const page = pidx;
                const pageSize = studentContenderPagination.rowsPerPage;
                const trainersFilter = (studentContenderRows || []).slice(
                  page * pageSize,
                  page * pageSize + pageSize
                );
                return (
                  <div key={`student-contender-page-` + pidx}>
                    {/* <StudentContender
                      contender={studentContenderRowsFilter[0]}
                    /> */}

                    <Frame
                      type={'contender'}
                      styles={{
                        frame: {
                          maxHeight: calvh('13vh'),
                          maxWidth: '26vw',
                        },
                        name: {
                          fontSize: calvh('4vh'),
                          lineHeight: calvh('4vh'),
                        },
                        pic: {
                          width: calvh('13.5vh'),
                          height: 'fit-content',
                        },
                      }}
                      images={images}
                      name={trainersFilter[0]?.name}
                      pic={
                        'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                      }
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>

          <div className={c('student-contender-nav-container')}>
            <img
              className={c('student-contender-nav-prev')}
              src={images['student-summary-contender-prev.png']}
              onClick={handleClickStudentContenderPrev}
              ref={clickAnime}
            />
            <img
              className={c('student-contender-nav-next')}
              src={images['student-summary-contender-next.png']}
              onClick={handleClickStudentContenderNext}
              ref={clickAnime}
            />
          </div>
          <div className={c('student-winlose-container')}>
            <img
              className={c('img-student-win')}
              src={images['student-summary-win01.png']}
            />
            <div className={c('student-winlose-box-container')}>
              <img
                className={c('img-student-winlose-box')}
                src={images['student-summary-winbox.png']}
              />
              <div className={c('student-winlose-number-container')}>
                <div className={c('student-winlose-number')}>{1}</div>
              </div>
            </div>
          </div>
          <div className={c('student-winlose-container')}>
            <img
              className={c('img-student-lose')}
              src={images['student-summary-lose01.png']}
            />
            <div className={c('student-winlose-box-container')}>
              <img
                className={c('img-student-winlose-box')}
                src={images['student-summary-losebox.png']}
              />
              <div className={c('student-winlose-number-container')}>
                <div className={c('student-winlose-number')}>{1}</div>
              </div>
            </div>
          </div>
          <div className={c('student-summary-container')}>
            <img
              className={c('img-student-summary-score')}
              src={images['student-summary-score.png']}
            />
          </div>
          <div className={c('student-back-container')}>
            <ButtonBack
              image={images['student-summary-back.png']}
              height={'6vh'}
            />
          </div>
        </div>
        <div className={c('score-detail-container')}>
          <div className={c('round-container')}>
            <div className={c('carousel-container')}>
              <Carousel
                afterChange={handleRoundCarouselAfterChangePage}
                ref={refRoundCarousel}
              >
                {Array.from(
                  Array(
                    Math.ceil(
                      (roundRows || []).length / DEFAULT_ROUND_ROWS_PER_PAGE
                    )
                  ).keys()
                ).map((_, pidx) => {
                  const page = pidx;
                  const pageSize = roundPagination.rowsPerPage;
                  const trainersFilter = (roundRows || []).slice(
                    page * pageSize,
                    page * pageSize + pageSize
                  );
                  return (
                    <div key={`round-page-` + pidx}>
                      <TableRound
                        ckey={`table-round-page-` + pidx}
                        rows={trainersFilter}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className={c('record-container')}>
            <img
              className={c('student-summary-record-prev')}
              src={images['student-summary-record-prev.png']}
              onClick={handleClickRecordPrev}
              ref={clickAnime}
            />
            <img
              className={c('student-summary-record-next')}
              src={images['student-summary-record-next.png']}
              onClick={handleClickRecordNext}
              ref={clickAnime}
            />

            <div className={c('carousel-container')}>
              <Carousel
                afterChange={handleRecordCarouselAfterChangePage}
                ref={refRecordCarousel}
                dotPosition='right'
              >
                {Array.from(
                  Array(
                    Math.ceil(
                      (recordRows || []).length / DEFAULT_RECORD_ROWS_PER_PAGE
                    )
                  ).keys()
                ).map((_, pidx) => {
                  // let recordRows = [
                  //   {
                  //     c1: <div height='4vh'>r1c1</div>,
                  //     c2: <div height='4vh'>r1c2</div>,
                  //     c3: <div height='4vh'>r1c3</div>,
                  //   },
                  //   {
                  //     c1: <div height='4vh'>r2c1</div>,
                  //     c2: <div height='4vh'>r2c2</div>,
                  //     c3: <div height='4vh'>r2c3</div>,
                  //   },
                  //   {
                  //     c1: <div height='4vh'>r3c1</div>,
                  //     c2: <div height='4vh'>r3c2</div>,
                  //     c3: <div height='4vh'>r3c3</div>,
                  //   },
                  //   {
                  //     c1: <div height='4vh'>r4c1</div>,
                  //     c2: <div height='4vh'>r4c2</div>,
                  //     c3: <div height='4vh'>r4c3</div>,
                  //   },
                  //   {
                  //     c1: <div height='4vh'>r5c1</div>,
                  //     c2: <div height='4vh'>r5c2</div>,
                  //     c3: <div height='4vh'>r5c3</div>,
                  //   },
                  // ];
                  const page = pidx;
                  const pageSize = recordPagination.rowsPerPage;
                  const trainersFilter = (recordRows || []).slice(
                    page * pageSize,
                    page * pageSize + pageSize
                  );
                  // console.log('page:::', page);
                  // console.log('trainersFilter:::', trainersFilter);
                  // console.log('recordRows:::', recordRows);
                  return (
                    <div
                      key={`record-page-` + pidx}
                      className={c('record-carousel-page-container')}
                    >
                      <TableRecord
                        ckey={`table-record-page-` + pidx}
                        rows={trainersFilter}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  };

  const footer = () => {
    return (
      <div
        className={c('footer-content-container')}
        style={{
          backgroundImage: `url(${images['student-summary-mission.png']})`,
        }}
      >
        <div className={c('misssion-container')}>
          <img
            className={c('img-student-summary-mission01')}
            src={images['student-summary-mission01.png']}
          />
        </div>
        <div className={c('point-container')}>
          <div className={c('point-content-container')}>
            <div className={c('point-content-wrap')}>
              <img
                className={c('img-student-summary-20p')}
                src={images['student-summary-20p.png']}
              />
              <div className={c('student-summary-recieve-container')}>
                <img
                  className={c('img-student-summary-recieve')}
                  src={images['student-summary-recieve.png']}
                />
              </div>
            </div>

            <img
              className={c('img-student-summary-win5')}
              src={images['student-summary-win5.png']}
            />
          </div>
          <div className={c('point-content-container')}>
            <div className={c('point-content-wrap')}>
              <div className={c('point-content-wrap')}>
                <img
                  className={c('img-student-summary-50p')}
                  src={images['student-summary-50p.png']}
                />
                <div className={c('student-summary-recieve-container')}>
                  <img
                    className={c('img-student-summary-recieve')}
                    src={images['student-summary-recieve.png']}
                  />
                </div>
              </div>
            </div>

            <img
              className={c('img-student-summary-winteacher')}
              src={images['student-summary-win5.png']}
            />
          </div>
          <div className={c('point-content-container')}>
            <div className={c('point-content-wrap')}>
              <div className={c('point-content-wrap')}>
                <img
                  className={c('img-student-summary-100p')}
                  src={images['student-summary-100p.png']}
                />
                <div className={c('student-summary-recieve-container')}>
                  <img
                    className={c('img-student-summary-recieve')}
                    src={images['student-summary-recieve.png']}
                  />
                </div>
              </div>
            </div>

            <img
              className={c('img-student-summary-win20')}
              src={images['student-summary-win20.png']}
            />
          </div>
        </div>
      </div>
    );
  };

  const pendingFlag = React.useMemo(
    () => getStudentSummaryFlag.request,
    [getStudentSummaryFlag.request]
  );

  const successFlag = React.useMemo(
    () => getStudentSummaryFlag.success,
    [getStudentSummaryFlag.success]
  );

  return (
    <div
      className={c('root')}
      background=''
      style={{
        backgroundImage: `url(${images['student-summary-bg.jpg']})`,
      }}
    >
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <>
          <div className={c('content-container')}>
            <img
              className={c('img-top-bar')}
              src={images['student-summary-top.png']}
            />
            <div className={c('round-nevigate-container')}>
              <img
                className={c('student-summary-round-prev')}
                src={images['student-summary-round-prev.png']}
                onClick={handleClickRoundPrev}
                ref={clickAnime}
              />
              <div className={c('round-number')}>{`ROUND ${
                roundPagination.page + 1
              }`}</div>
              <img
                className={c('student-summary-round-next')}
                src={images['student-summary-round-next.png']}
                onClick={handleClickRoundNext}
                ref={clickAnime}
              />
            </div>
            <img
              className={c('img-left-bar')}
              src={images['student-summary-side.png']}
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                overflow: 'hidden auto',
                marginTop: -3,
              }}
            >
              {content()}
            </div>
            <img
              className={c('img-right-bar')}
              src={images['student-summary-side.png']}
            />
            <img
              className={c('img-bottom-bar')}
              src={images['student-summary-bottom.png']}
            />
          </div>
          <div className={c('footer-container')}>{footer()}</div>
        </>
      ) : null}
    </div>
  );
};

export default StudentSummary;
