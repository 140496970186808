import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useImagesContext } from 'contexts/ImagesContext';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { logout } from 'services/auth';
import StudentID from 'components/fragments/StudentID';
import ButtonLogout from 'components/fragments/ButtonLogout';
import Progress from 'components/fragments/Progress';
import * as decisionActions from 'actions/decision';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('100vh'),
    overflow: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  'img-id': {
    height: calvh('6vh'),
    width: calvh('18vh'),
    maxWidth: '28vw',
  },
  title: {
    position: 'absolute',
    zIndex: 2,
    fontSize: calvh('5vh'),
    lineHeight: calvh('5vh'),
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar': {
    position: 'fixed',
    top: 0,
    left: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '4rem',
    zIndex: 1,
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
}));

const Background = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [withTitle, setWithTitle] = React.useState('');
  // const [displayLogout, setDisplayLogout] = React.useState(false);
  const [allowLogout, setAllowLogout] = React.useState(false);
  const [withoutSideBar, setWithoutSideBar] = React.useState(false);
  const [logoutFlag, setLogoutFlag] = React.useState({});
  // const [displayNevigateBack, setDisplayNevigateBack] = React.useState(false);
  // const [rootHeight, setRootHeight] = React.useState('100vh');
  const store = useSelector((state) => state);
  const { activeState, actionState } = store?.decision;
  const dispatch = useDispatch();

  const withTitleComponent = React.useMemo(
    () => ({
      'student-snapshort1': (
        <div className={c('title')}>
          <StudentID value={'G018'} />
        </div>
      ),
      'student-snapshort2': (
        <div className={c('title')}>
          <StudentID value={'G018'} />
        </div>
      ),
    }),
    [withTitle]
  );

  //console.log('withTitleComponent:', withTitleComponent);

  // React.useLayoutEffect(() => {
  //   //setRootHeight(calVHForSafari());
  //   if (true) {
  //     // var element = document.querySelector('[root]');
  //     // var scrollHeight = element.scrollHeight;
  //     // window.resizeTo(window.innerWidth, 100);
  //     // window.alert(
  //     //   JSON.stringify({
  //     //     innerHeight: window.innerHeight,
  //     //     availHeight: window.screen.availHeight,
  //     //   })
  //     // );
  //     // window.resizeTo(window.innerWidth, 500);
  //     // const width = window.innerWidth;
  //     // const height = window.innerHeight;
  //     // window.resizeTo(width, 200);
  //   }
  // }, []);

  React.useEffect(() => {
    //console.log('activeState:', activeState);
    if (Object.keys(withTitleComponent).includes(activeState?.state)) {
      setWithTitle(true);
    } else {
      setWithTitle(false);
    }

    if (
      activeState?.state === 'student-level' ||
      activeState?.state === 'student-point'
    ) {
      setWithoutSideBar(true);
    } else {
      setWithoutSideBar(false);
    }
    // if (activeState?.state === 'schedule') {
    //   setDisplayLogout(true);
    //   //setDisplayNevigateBack(true);
    // } else {
    //   setDisplayLogout(false);
    //   //setDisplayNevigateBack(false);
    // }
  }, [activeState]);

  React.useEffect(() => {
    // if (actionState?.['editTrainerInfo']) {
    //   setAllowLogout(true);
    // }
  }, [actionState]);

  // const handleClickBack = () => {
  //   const nevigateBackMap = {
  //     //schedule: 'introduce-end',
  //   };
  //   if (nevigateBackMap[activeState?.state]) {
  //     dispatch(
  //       decisionActions.setActiveState(nevigateBackMap[activeState?.state])
  //     );
  //   }
  // };

  // const handleClickLogout = () => {
  //   if (allowLogout) {
  //     handleLogoutService();
  //   } else {
  //     warningAlert(undefined, 'กรุณาบันทึกข้อมูลก่อนออกจากระบบ.');
  //   }
  // };

  // const handleLogoutService = (next) => {
  //   callService(
  //     logout,
  //     null,
  //     setLogoutFlag,
  //     (resp) => {
  //       dispatch(decisionActions.setActiveState('login'));
  //       next && next(resp);
  //     },
  //     (error) => {
  //       errorAlert(error);
  //     }
  //   );
  // };

  return (
    <div
      className={c('root')}
      background=''
      style={{
        backgroundImage: `url(${images['student-bg-bg.jpg']})`,
      }}
    >
      {/* {displayNevigateBack ? (
        <div style={{ position: 'absolute', zIndex: 999, left: 2, top: -4 }}>
          <NevigateBack onClick={handleClickBack} height={calvh('4.5vh')} />
        </div>
      ) : null}
     */}

      {withTitleComponent[activeState?.state]}

      <img
        className={c('img-top-bar')}
        src={images['student-background-top.png']}
      />
      {!withoutSideBar ? (
        <img
          className={c('img-left-bar')}
          src={images['student-background-side.png']}
        />
      ) : null}

      <div
        style={{
          width: '89%',
          height: '100%',
          //overflow: 'hidden auto'
        }}
      >
        {props.children}
      </div>
      {!withoutSideBar ? (
        <img
          className={c('img-right-bar')}
          src={images['student-background-side.png']}
        />
      ) : null}

      <img
        className={c('img-bottom-bar')}
        src={images['student-background-bottom.png']}
      />
      {/* {displayLogout ? (
        <div style={{ position: 'absolute', zIndex: 999, right: 5, bottom: 2 }}>
          <ButtonLogout
            onClick={handleClickLogout}
            disabled={logoutFlag.request}
          />
        </div>
      ) : null} */}
      {logoutFlag.request ? <Progress /> : null}
    </div>
  );
};

export default Background;
