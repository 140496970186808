import axios from 'host';
import { getTrainerInfo } from './trainer';

export const getPrinciple = (next = null, error = null, complete = null) => {
  axios
    .get('/videos')
    .then((resp) => {
      next &&
        next(
          (resp.data || []).map((data) => {
            return {
              id: data?.id,
              section1: {
                video: data,
                description: 'เวลา 30 นาที',
                list: ['ดูคลิปทำความเข้าใจกฏกติกาพื้นฐาน'],
              },
              section2: {
                title: 'สิ่งสำคัญเน้นย้ำ',
                list: [
                  'ความสนุก50% เนื้อหา50%',
                  'เปลี่ยนกิจกรรมเมื่อเด็กรู้สึกเบื่อ',
                ],
              },
            };
          })
        );
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};

export const getTrainerPrincipleChecklist = (
  next = null,
  error = null,
  complete = null
) => {
  getTrainerInfo(
    (trainerInfo) => {
      axios
        .get('/teacher/videos')
        .then((resp) => {
          const idList = (resp.data || [])
            .filter((i) => {
              const list = resp.data || [];
              return (
                list[0]?.id !== i?.id && list[list.length - 1]?.id !== i?.id
              );
            })
            .map((i) => i.id);
          next &&
            next({
              checklist: resp.data,
              principles: (resp.data || [])
                .filter((data) => idList.includes(data.id))
                .map((data) => {
                  return {
                    ...data,
                    //videoValue: '/videos/steam/Josaki02.mp4',
                    // id: data?.id,
                    // section1: {
                    //   video: data,
                    //   description: 'เวลา 30 นาที',
                    //   list: ['ดูคลิปทำความเข้าใจกฏกติกาพื้นฐาน'],
                    // },
                    // section2: {
                    //   title: 'สิ่งสำคัญเน้นย้ำ',
                    //   list: [
                    //     'ความสนุก50% เนื้อหา50%',
                    //     'เปลี่ยนกิจกรรมเมื่อเด็กรู้สึกเบื่อ',
                    //   ],
                    // },
                  };
                }),
              trainerInfo,
            });
        })
        .catch((err) => {
          error && error(err);
        })
        .finally(() => {
          complete && complete();
        });
    },
    (err) => {
      error && error(err);
      complete && complete();
    }
  );
};

export const editTrainerPrincipleInfo = (
  { id, watched },
  next = null,
  error = null,
  complete = null
) => {
  axios
    .patch('/teacher/videos', { id, watched })
    .then((resp) => {
      next && next(resp.data);
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};
