import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { calvh } from 'utils/element';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: ({ styles }) => styles?.padding,
    margin: ({ styles }) => styles?.margin,
  },
  'img-text-field': {
    // width: '42vw',
    // maxHeight: calvh('7vh'),
    // objectFit: 'contain',
    height: calvh('6vh'),
    width: calvh('18vh'),
    maxWidth: '28vw',
  },
  'text-value': {
    position: 'absolute',
    fontSize: calvh('7vh'),
    color: '#6c2a12',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%',
    top: 0,
    left: 0,
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('-0.1vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
  },
  // 'text-container': {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   maxWidth: ({ styles }) => styles?.maxWidth || '40vw',
  //   width: '100%',
  //   height: ({ styles }) => styles?.height || calvh('5vh'),
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: '100% 70%',
  //   backgroundPosition: 'center',
  // },
}));

const StudentID = (props) => {
  const { value, onChange } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      {/* <div className={'text' + ' ' + c('label-container')}>
        <div className={'text' + ' ' + c('label')}>
          {require ? <div require=''>**</div> : null}
          {label}
        </div>
        {helperText ? (
          <div className={c('label-helpertext')}>{helperText}</div>
        ) : null}
      </div> */}

      <img className={c('img-text-field')} src={images['student-id.png']} />
      <div className={c('text-value')}>{value}</div>
    </div>
  );
};

export default StudentID;
