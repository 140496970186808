import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert } from 'utils/alert';
import { getIntroduceEnd } from 'services/introduce';
import { editTrainerInfo } from 'services/trainer';
//import { useImagesContext } from 'contexts/ImagesContext';
import { useDispatch } from 'react-redux';
import Progress from 'components/fragments/Progress';
import ButtonOK from 'components/fragments/ButtonOK';
import ButtonBack from 'components/fragments/ButtonBack';
import PointList from 'components/fragments/PointList';
import * as decisionActions from 'actions/decision';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
    },
  },
  'text-section1-title': {
    textAlign: 'center',
    padding: 2,
    color: '#009420',
    fontSize: calvh('7vh'),
    margin: 'auto',
  },
  'container-devider': {
    width: '95%',
    height: calvh('0.36vh'),
    backgroundColor: '#6c2912',
  },
  'box-section2': {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: calvh('1vh'),
    padding: calvh('1vh'),
    border: `${calvh('0.7vh')} solid #f0292991`,
    borderRadius: '5px',
    backgroundColor: '#f6fff080',
    width: 'fit-content',
    margin: 'auto',
    '& [title]': {
      fontSize: calvh('3.6vh'),
      textAlign: 'center',
      color: '#f02929',
      width: 'fit-content',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '49vw',
    },
  },
  point: {
    fontSize: calvh('5vh'),
    fontWeight: 'bolder',
    color: '#009420',
    textShadow: `#fff ${calvh('0.2vh')} ${calvh('0.2vh')} 0px, #fff ${calvh(
      '-0.2vh'
    )} ${calvh('-0.14vh')} 0px, #fff 0px ${calvh('0.4vh')} 0px`,
  },
  description: {
    fontSize: calvh('4.8vh'),
    fontWeight: 'bolder',
    color: '#1421ae',
    textAlign: 'center',
  },
  'img-intro-end': {
    height: 'fit-content',
    maxHeight: calvh('61vh'),
    maxWidth: '90vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
}));

const IntroduceEnd = (props) => {
  //const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [getIntroduceEndFlag, setGetIntroduceEndFlag] = React.useState({});
  const [editTrainerInfoFlag, setEditTrainerInfoFlag] = React.useState({});
  const [indroduceEnd, setIndroduceEnd] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(decisionActions.setActionState('reachedIntroduceEnd', true));
    handleGetIntroduceEndService();
  }, []);

  const handleClickOk = () => {
    handleEditTrainerInfoService(() => {
      dispatch(decisionActions.setActiveState('schedule'));
    });
  };

  const handleClickBack = () => {
    dispatch(
      decisionActions.setActiveState('principle', { previous: 'introduce-end' })
    );
  };

  const handleEditTrainerInfoService = (next) => {
    callService(
      editTrainerInfo,
      { videoDone: true },
      setEditTrainerInfoFlag,
      (resp) => {
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleGetIntroduceEndService = (next) => {
    callService(
      getIntroduceEnd,
      {},
      setGetIntroduceEndFlag,
      (resp) => {
        //dispatch(authActions.setAuthenSuccess());
        setIndroduceEnd(resp);
        next && next(resp);
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleError = (error) => {
    errorAlert(error, (result) => {
      if (result.isConfirmed) {
        if (error.response.status === 401) {
          dispatch(decisionActions.setActiveState('login'));
        }
      } else if (result.isDenied) {
      }
    });
  };

  const pendingFlag = React.useMemo(
    () => getIntroduceEndFlag.request,
    [getIntroduceEndFlag.request]
  );

  const successFlag = React.useMemo(
    () => getIntroduceEndFlag.success,
    [getIntroduceEndFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ paddingBottom: calvh('1vh') }}
          >
            <p className={'text' + ' ' + c('text-section1-title')}>
              {'✔ภารกิจสำเร็จ'}
            </p>
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: '10px 0px' }}
          >
            <div className={c('container-devider')} />
          </Grid>
          {/* <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{
              height: '50%',
              paddingLeft: calvh('1vh'),
              paddingBottom: calvh('1vh'),
            }}
          >
            <PointList
              list={indroduceEnd?.section1?.list}
              spacing={calvh('0.8vh')}
              styles={{
                item: {
                  color: '#1421ae',
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
          >
            <div className={c('box-section2')}>
              <div title='' className={'text'}>
                {indroduceEnd?.section2?.text}
              </div>
            </div>
          </Grid> */}

          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            style={{ padding: '1vh' }}
          >
            <img
              className={c('img-intro-end')}
              src={`${config.hostUrl}${indroduceEnd?.imageUri}`}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent='space-between'
            style={{ padding: calvh('1vh') }}
          >
            <Grid item>
              <ButtonBack onClick={handleClickBack} />
            </Grid>
            <Grid item>
              <ButtonOK
                onClick={handleClickOk}
                disabled={editTrainerInfoFlag.request}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {editTrainerInfoFlag.request ? <Progress /> : null}
    </div>
  );
};

export default IntroduceEnd;
