import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';
import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'button-upload': {
    height: ({ height }) => height || calvh('6vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
  },
}));

const ButtonUpload = (props) => {
  const { onClick, disabled } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('button-upload')}
        src={images['button-upload.png']}
        ref={(ref) => !disabled && clickAnime(ref)}
        onClick={(e) => !disabled && onClick(e)}
      />
    </ButtonBase>
  );
};

export default ButtonUpload;
