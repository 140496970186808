import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonWatch from 'components/fragments/ButtonWatch/ButtonWatch';
const ButtonWatchComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonWatch/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonWatch {...props} />
  </ImagesProvider>
);

export default ButtonWatchComponent;
