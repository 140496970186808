import axios from '../host';
import jwt_decode from 'jwt-decode';

// export const setAuthToken = (token) => {
//   if (token) {
//     axios.defaults.headers.common['x-auth-token'] = token;
//   } else {
//     delete axios.defaults.headers.common['x-auth-token'];
//   }
// };

export const checkIsTokenExpired = () => {
  const token = localStorage.getItem('token');
  //console.log('token:', !!token);
  //localStorage.clear();
  if (token) {
    var decoded = jwt_decode(token);
    if (decoded.exp < Date.now() / 1000) {
      localStorage.clear();
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export function monitorAuthenExpire(onExpire) {
  window.refInterval && clearInterval(window.refInterval);
  window.refInterval = setInterval(() => {
    // //console.log('loginDate:', localStorage.getItem('loginDate'));
    // if (localStorage.getItem('loginDate')) {
    //   const loginDate = dayjs(localStorage.getItem('loginDate'));
    //   const curDate = dayjs.utc().local();
    //   const diff = curDate.diff(loginDate, 'minute');
    //   console.log('diff:', diff);
    //   if (diff >= 1440 /* 1 day */) {
    //     localStorage.removeItem('loginDate');
    //     onExpire();
    //   }
    // }

    if (checkIsTokenExpired()) {
      onExpire();
    }
  }, 60000 * 5); // 5 minute
}
