import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { calvh } from 'utils/element';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: ({ vertical }) => (vertical ? 'column' : undefined),
    justifyContent: ({ vertical }) => (vertical ? 'center' : undefined),
    alignItems: 'center',
    flexWrap: ({ vertical }) => (vertical ? 'nowrap' : undefined),
    //paddingRight: calvh('1vh'),
    marginBottom: calvh('1vh'),
    width: ({ styles }) => styles?.width,
    margin: ({ styles }) => styles?.margin,
  },
  'label-container': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: ({ vertical, styles }) =>
      vertical
        ? styles?.labelContainer?.padding || `0px 0px 0px 0px`
        : styles?.labelContainer?.padding || `0px ${calvh('1vh')} 0px 0px`,
    width: 'min-content',
  },
  label: {
    width: calvh('25vh'),
    maxHeight: calvh('4.5vh'),
    '@media (min-width:434px)': {
      maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
    objectPosition: '0%',
    paddingLeft: '1rem',
  },
  'input-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: ({ styles }) => styles?.inputContainer.maxWidth,
    width: ({ styles }) => styles?.inputContainer.width,
    height: ({ styles }) => styles?.inputContainer.height || calvh('5vh'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: ({ styles }) =>
      styles?.inputContainer.backgroundSize || '100% 70%',
    backgroundPosition: 'center',
  },
  input: {
    fontSize: ({ styles }) => styles?.input?.fontSize || calvh('3vh'),
    fontWeight: 600,
    margin: ({ styles }) => styles?.input?.margin || calvh('0.8vh'),
    height: ({ styles }) => styles?.input?.height || calvh('2.1vh'),
    padding: ({ styles }) => styles?.input?.padding || calvh('1vh'),
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    width: '100%',
    lineHeight: ({ styles }) => styles?.input?.lineHeight,
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width:434px)': {
      fontSize: ({ styles }) => styles?.input?.fontSizeSm,
    },
  },
}));

const TextField3 = (props) => {
  const { label, value, onChange, imgLabel, imgField, textarea } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <div className={c('label-container')}>
        {label ? (
          label
        ) : (
          <img src={images[imgLabel]} className={c('label')}></img>
        )}
      </div>

      <div
        className={c('input-container')}
        style={{
          backgroundImage: `url(${imgField || images['input-field.png']})`,
        }}
      >
        {textarea ? (
          <textarea className={c('input')} onChange={onChange} value={value} />
        ) : (
          <input className={c('input')} onChange={onChange} value={value} />
        )}
      </div>
    </div>
  );
};

export default TextField3;
