import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useImagesContext } from 'contexts/ImagesContext';
import { joinClasses, callService } from 'utils/utility';
import { clickAnime } from 'utils/element';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { logout } from 'services/auth';
import Table from 'components/fragments/Table';
import { Carousel } from 'antd';
import ButtonBack from 'components/fragments/ButtonBack';
import Frame from 'components/fragments/Frame';
import Progress from 'components/fragments/Progress';
import StarNumber from 'components/fragments/StarNumber';
import BoxNumber from 'components/fragments/BoxNumber';
import { getStudentScore } from 'services/student-score';

const tableScoreCellHeight = calvh('11.8vh');
//const tableScoreCellWidth = `${(100 / 3).toFixed(2)}%`;
const tableScoreCellWidths = [
  '13%',
  '22%',
  `${(66 / 3).toFixed(2)}%`,
  `${(66 / 3).toFixed(2)}%`,
  `${(66 / 3).toFixed(2)}%`,
];
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('100vh'),
    overflow: 'visible',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  'content-container': {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('80vh'),
  },
  'score-container': {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '100%',
    //paddingRight: '1.25vw',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRightWidth: '2.5vw',
  },
  'column-label-container': {
    top: 0,
    right: 0,
    position: 'absolute',
    zIndex: 100,
    display: 'flex',
    width: '100vw',
    height: calvh('5vh'),
  },
  'carousel-container': {
    zIndex: 1,
    width: '100%',
    height: 'auto',
    '& [class~="ant-carousel"] [class~="slick-dots"] button': {
      background: '#9798aa !important',
    },
    '& [class~="ant-carousel"] [class~="slick-dots"] [class~="slick-active"] button':
      {
        background: '#adaeb6 !important',
      },
    '& [class~="ant-carousel"] [class~="slick-dots"]': {
      display: 'none !important',
    },
  },
  'student-score-side-prev': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'column-text-container': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    fontSize: calvh('4vh'),
    lineHeight: calvh('4vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
  },
  'column-text': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'student-score-side-next': {
    height: calvh('6vh'),
    maxWidth: '9vw',
    objectFit: 'contain',
  },
  'table-wrap': {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  'table-gutter-hor': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'student-score-hor-line': {
    width: '100%',
    height: calvh('0.5vh'),
  },
  'img-side-gutter-hor-top': {
    width: '100%',
    height: calvh('1vh'),
  },
  'gutter-hor-wrap': {
    display: 'flex',
    alignItems: 'flex-end',
    height: tableScoreCellHeight,
    width: '100%',
  },
  'table-gutter-ver': {
    display: 'flex',
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  'student-score-ver-line': {
    width: calvh('0.5vh'),
    height: '100%',
  },
  'gutter-ver-wrap': {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    //width: tableScoreCellWidth,
  },
  'footer-container': {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: calvh('20vh'),
  },
  'footer-content-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'hidden',
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('5.2vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar-container': {
    position: 'fixed',
    top: 0,
    left: '33.5vw',
    zIndex: 101,
  },
  'img-table-first-col': {
    position: 'fixed',
    top: 0,
    left: '-1.5vw',
    zIndex: 0,
    width: '14.5vw',
    height: calvh('80vh'),
  },
  'img-left-bar-wrap': {
    position: 'relative',
    display: 'flex',
  },
  'img-left-bar': {
    width: '2.5vw',
    height: calvh('80vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 2,
    width: '2.5vw',
    height: calvh('80vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('5.2vh'),
    maxHeight: '4rem',
    zIndex: 3,
  },
  'student-score-nev-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  'student-score-prev': {
    // position: 'fixed',
    // left: 'calc(33.5vw - 8px)',
    // top: calvh('2vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 50%',
    marginTop: calvh('2vh'),
  },
  'student-score-next': {
    // position: 'fixed',
    // left: 'calc(33.5vw - 8px)',
    // bottom: calvh('22vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 100%',
    marginBottom: '-12px',
  },
  'score-field-point-container': {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: tableScoreCellHeight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  'score-field-point-number-container': {
    display: 'flex',
    width: '100%',
    height: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'score-field-point-point-container': {
    display: 'flex',
    width: '100%',
    height: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  'end-number': {
    position: 'absolute',
    zIndex: 2,
    fontSize: calvh('9vh'),
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%',
    top: -13,
    left: -5,
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('-0.1vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.25vh'
    )} ${calvh('0.25vh')} 0px`,
  },
}));

const DEFAULT_SCORE_PAGE = 0;
const DEFAULT_SCORE_ROWS_PER_PAGE = 2;

const TableScore = (props) => {
  const { ckey, rows } = props;
  const c = joinClasses(useStyles({ ...props }));
  const { images } = useImagesContext();
  const dummyLen = rows.filter((r) => Object.keys(r).includes('dummy')).length;

  return (
    <div className={c('table-wrap')} key={ckey}>
      {/* <div className={c('table-gutter-hor')}>
        {Array.from(Array(Object.keys(rows).length).keys()).map((i, idx) => (
          <div className={c('gutter-hor-wrap')} key={'gutter-hor-' + idx}>
            <img
              className={c('student-score-hor-line')}
              src={images['student-score-hor-line.png']}
            />
          </div>
        ))}
      </div>
      <div className={c('table-gutter-ver')}>
        {Array.from(Array(Object.keys(rows[0] || {}).length).keys()).map(
          (i, idx) => (
            <div className={c('gutter-ver-wrap')} key={'gutter-ver-' + idx}>
              <img
                className={c('student-score-ver-line')}
                src={images['student-score-ver-line.png']}
              />
            </div>
          )
        )}
      </div> */}
      <div className={c('table-gutter-hor')}>
        {Array.from(Array(Object.keys(rows).length).keys())
          .map((i, idx) => {
            if (Object.keys(rows[idx] || {})[0] !== 'dummy') {
              return (
                <div className={c('gutter-hor-wrap')} key={'gutter-hor-' + idx}>
                  <img
                    className={c('student-score-hor-line')}
                    src={images['student-score-hor-line.png']}
                  />
                </div>
              );
            } else {
              return null;
            }
          })
          .filter((i) => !!i)}
      </div>

      <div
        className={c('table-gutter-ver')}
        style={{
          height: `${(
            100 -
            (100 * dummyLen) / DEFAULT_SCORE_ROWS_PER_PAGE
          ).toFixed(2)}%`,
        }}
      >
        {Array.from(Array(Object.keys(rows[0] || {}).length).keys()).map(
          (i, idx) => (
            <div
              key={'gutter-ver-' + idx}
              className={c('gutter-ver-wrap')}
              style={{ width: tableScoreCellWidths[idx] }}
            >
              <img
                className={c('student-score-ver-line')}
                src={images['student-score-ver-line.png']}
              />
            </div>
          )
        )}
      </div>

      <Table
        noHeader
        styles={{
          width: '100%',
          row: {
            // width: 'min-content',
          },
          // border: {
          //   width: calvh('0.1vh'),
          //   color: 'transparent',
          // },
          column: {
            // widths: [`100%`],
          },
          cell: {
            // minWidths: ['30%'],
            //width: tableScoreCellWidth,
            widths: tableScoreCellWidths,
          },
          header: {
            cell: {
              width: calvh('12vh'),
            },
          },
        }}
        rows={rows}
      />
    </div>
  );
};

const StudentScore = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [getStudentScoreFlag, setGetStudentScoreFlag] = React.useState({});
  const [studentScore, setStudentScore] = React.useState(undefined);
  const store = useSelector((state) => state);

  React.useEffect(() => {
    handleGetStudentScoreService();
  }, []);

  const handleGetStudentScoreService = (next) => {
    callService(
      getStudentScore,
      {},
      setGetStudentScoreFlag,
      (resp) => {
        setStudentScore(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const scoreRows = React.useMemo(() => {
    const rows = [
      {
        field: (
          <div
            height={tableScoreCellHeight}
            className={c('score-field-point-container')}
          >
            <div className={c('score-field-point-number-container')}>
              <StarNumber
                number={1}
                styles={{
                  height: calvh('6vh'),
                  fontSize: calvh('5vh'),
                }}
              />
            </div>
            <div
              className={c('score-field-point-point-container')}
              style={{
                backgroundImage: `url(${
                  images[`student-score-${'200'}p.png`]
                })`,
              }}
            >
              {/* <img
                style={{
                  height: calvh('6vh'),
                  maxWidth: '9vw',
                  objectFit: 'contain',
                }}
                src={images[`student-score-${'200'}p.png`]}
              /> */}
            </div>
          </div>
        ),
        field0: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Frame
              type={'score'}
              styles={{
                margin: '-4px 0px 0px 0px',
                frame: {
                  maxHeight: calvh('10.8vh'),
                  maxWidth: '20vw',
                },
                name: {
                  fontSize: calvh('3vh'),
                  lineHeight: calvh('3vh'),
                  transform: 'translateY(4px)',
                },
                pic: {
                  width: calvh('11.2vh'),
                  height: 'fit-content',
                },
              }}
              images={images}
              pic={
                'https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
              }
              name={'เม่น1'}
            />
          </div>
        ),
        field1: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BoxNumber
              type={'win'}
              number={10}
              styles={{ root: { paddingBottom: calvh('0.6vh') } }}
            />
          </div>
        ),
        field2: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BoxNumber
              type={'lose'}
              number={10}
              styles={{ root: { paddingBottom: calvh('0.6vh') } }}
            />
          </div>
        ),
        field3: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BoxNumber
              type={'score'}
              number={10}
              styles={{ root: { paddingBottom: calvh('0.6vh') } }}
            />
          </div>
        ),
      },
      {
        field: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'blue',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            2
          </div>
        ),
        field0: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'yellow',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Frame
              type={'teacher'}
              styles={{
                margin: '-4px 0px 0px 0px',
                frame: {
                  maxHeight: calvh('10.8vh'),
                  maxWidth: '20vw',
                },
                name: {
                  fontSize: calvh('3vh'),
                  lineHeight: calvh('3vh'),
                  transform: 'translateY(4px)',
                },
              }}
              images={images}
              winFlag={false}
              name={'เม่น2'}
            />
          </div>
        ),
        field1: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'red',
            }}
          >
            4
          </div>
        ),
        field2: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'blue',
            }}
          >
            5
          </div>
        ),
        field3: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'green',
            }}
          >
            6
          </div>
        ),
      },
      {
        field: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'blue',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            3
          </div>
        ),
        field0: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'yellow',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Frame
              type={'teacher'}
              styles={{
                margin: '-4px 0px 0px 0px',
                frame: {
                  maxHeight: calvh('10.8vh'),
                  maxWidth: '20vw',
                },
                name: {
                  fontSize: calvh('3vh'),
                  lineHeight: calvh('3vh'),
                  transform: 'translateY(4px)',
                },
              }}
              images={images}
              winFlag={true}
              name={'เม่น'}
            />
          </div>
        ),
        field1: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'red',
            }}
          >
            7
          </div>
        ),
        field2: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'blue',
            }}
          >
            8
          </div>
        ),
        field3: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
              backgroundColor: 'green',
            }}
          >
            9
          </div>
        ),
      },
    ];
    const padNum =
      DEFAULT_SCORE_ROWS_PER_PAGE > rows.length
        ? DEFAULT_SCORE_ROWS_PER_PAGE - rows.length
        : rows.length % DEFAULT_SCORE_ROWS_PER_PAGE;
    Array.from(Array(padNum).keys()).forEach((i) => {
      rows.push({
        dummy: (
          <div
            height={tableScoreCellHeight}
            style={{
              display: 'flex',
              width: '100%',
              height: tableScoreCellHeight,
            }}
          ></div>
        ),
      });
    });
    return rows;
  }, [images]);

  const [scoreRowsFilter, setScoreRowsFilter] = React.useState([]);
  const [scorePagination, setScorePagination] = React.useState({
    rowsPerPage: DEFAULT_SCORE_ROWS_PER_PAGE,
    page: DEFAULT_SCORE_PAGE,
  });

  const refScoreCarousel = React.useRef(null);
  const refScoreRows = React.useRef(null);

  React.useEffect(() => {
    refScoreRows.current = scoreRows;
    setScorePagination((prev) => ({ ...prev }));
  }, [scoreRows]);

  React.useEffect(() => {
    const page = scorePagination.page;
    const pageSize = scorePagination.rowsPerPage;
    const trainersFilter = (refScoreRows.current || []).slice(
      page * pageSize,
      page * pageSize + pageSize
    );
    setScoreRowsFilter(trainersFilter);
  }, [scorePagination]);

  const handleScoreCarouselAfterChangePage = (newPage) => {};

  const handleClickScorePrev = () => {
    refScoreCarousel.current && refScoreCarousel.current.prev();
    if (scorePagination?.page > 0) {
      setScorePagination((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setScorePagination((prev) => ({
        ...prev,
        page:
          Math.ceil((scoreRows || []).length / DEFAULT_SCORE_ROWS_PER_PAGE) - 1,
      }));
    }
  };

  const handleClickScoreNext = () => {
    refScoreCarousel.current && refScoreCarousel.current.next();
    if (
      scorePagination?.page <
      Math.ceil((scoreRows || []).length / DEFAULT_SCORE_ROWS_PER_PAGE) - 1
    ) {
      setScorePagination((prev) => ({ ...prev, page: prev.page + 1 }));
    } else {
      setScorePagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const content = () => {
    return (
      <>
        <div className={c('score-container')}>
          <div className={c('carousel-container')}>
            <Carousel
              afterChange={handleScoreCarouselAfterChangePage}
              ref={refScoreCarousel}
              dotPosition='right'
            >
              {Array.from(
                Array(
                  Math.ceil(
                    (scoreRows || []).length / DEFAULT_SCORE_ROWS_PER_PAGE
                  )
                ).keys()
              ).map((_, pidx) => {
                const page = pidx;
                const pageSize = scorePagination.rowsPerPage;
                const trainersFilter = (scoreRows || []).slice(
                  page * pageSize,
                  page * pageSize + pageSize
                );
                return (
                  <div key={`score-page-` + pidx}>
                    <TableScore
                      ckey={`table-score-page-` + pidx}
                      rows={trainersFilter}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <img
            className={c('img-table-first-col')}
            src={images['student-score-point-bg.png']}
          />
        </div>
      </>
    );
  };

  const footer = () => {
    return (
      <div
        className={c('footer-content-container')}
        style={{
          backgroundImage: `url(${images['student-score-root-bg.jpg']})`,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '21vw',
          }}
        >
          <img
            style={{
              height: calvh('14vh'),
              maxWidth: '19vw',
              objectFit: 'contain',
            }}
            src={images[`student-score-matching.png`]}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <img
            style={{
              height: calvh('18vh'),
              maxWidth: '24vw',
              objectFit: 'contain',
            }}
            src={images[`student-score-end.png`]}
          />
          <div className={c('end-number')}>45</div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '21vw',
          }}
        >
          <img
            style={{
              height: calvh('20vh'),
              maxWidth: '25vw',
              objectFit: 'contain',
              paddingTop: calvh('2.4vh'),
            }}
            src={images[`student-score-hall.png`]}
          />
        </div>
      </div>
    );
  };

  const pendingFlag = React.useMemo(
    () => getStudentScoreFlag.request,
    [getStudentScoreFlag.request]
  );

  const successFlag = React.useMemo(
    () => getStudentScoreFlag.success,
    [getStudentScoreFlag.success]
  );

  return (
    <div
      className={c('root')}
      background=''
      style={{
        backgroundImage: `url(${images['student-score-bg.jpg']})`,
      }}
    >
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <>
          <div className={c('content-container')}>
            <img
              className={c('img-top-bar')}
              src={images['student-score-top.png']}
            />
            <div className={c('column-label-container')}>
              <div className={c('column-text-container')}>
                <div
                  className={c('column-text')}
                  style={{
                    width: `calc(${tableScoreCellWidths[0]} + ${tableScoreCellWidths[1]})`,
                  }}
                >
                  ชื่อกลุ่ม
                </div>
                <div
                  className={c('column-text')}
                  style={{ width: tableScoreCellWidths[2] }}
                >
                  WIN
                </div>
                <div
                  className={c('column-text')}
                  style={{ width: tableScoreCellWidths[3] }}
                >
                  LOSE
                </div>
                <div
                  className={c('column-text')}
                  style={{ width: tableScoreCellWidths[4] }}
                >
                  SCORE
                </div>
              </div>
            </div>
            <div className={c('img-left-bar-container')}>
              <div className={c('img-left-bar-wrap')}>
                <img
                  className={c('img-left-bar')}
                  src={images['student-score-side.png']}
                />
                <div className={c('student-score-nev-container')}>
                  <img
                    className={c('student-score-prev')}
                    src={images['student-score-prev.png']}
                    onClick={handleClickScorePrev}
                    ref={clickAnime}
                  />
                  <img
                    className={c('student-score-next')}
                    src={images['student-score-next.png']}
                    onClick={handleClickScoreNext}
                    ref={clickAnime}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                overflow: 'hidden auto',
                marginTop: -3,
              }}
            >
              {content()}
            </div>
            <img
              className={c('img-right-bar')}
              src={images['student-score-side.png']}
            />
            {/* <img
              className={c('img-bottom-bar')}
              src={images['student-score-bottom.png']}
            /> */}
          </div>
          <div className={c('footer-container')}>{footer()}</div>
        </>
      ) : null}
    </div>
  );
};

export default StudentScore;
