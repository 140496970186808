import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ImagesProvider } from 'contexts/ImagesContext';
import Wrapper, { When } from 'components/utils/wrapper';
import Background from 'pages/user-checklist/background';
import Login from 'components/features/login';
import Introduce from 'components/features/introduce';
import Principle from 'components/features/principle';
import IntroduceEnd from 'components/features/introduce-end';
import Schedule from 'components/features/schedule';
import * as decisionActions from 'actions/decision';
import { checkIsTokenExpired, monitorAuthenExpire } from 'utils/auth';

const BackgroundComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'pages/user-checklist/background',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Background {...props} />
  </ImagesProvider>
);
const LoginComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/login/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <Login {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const IntroduceComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/introduce/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <Introduce {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const PrincipleComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/principle/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <Principle {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const IntroduceEndComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/introduce-end/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <IntroduceEnd {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const ScheduleComponent = (props) => (
  <BackgroundComponent>
    <ImagesProvider
      intro={false}
      r={require.context(
        'components/features/schedule/',
        true,
        /\.(png|jpe?g|svg|gif)$/
      )}
    >
      <Schedule {...props} />
    </ImagesProvider>
  </BackgroundComponent>
);

const Decision = (props) => {
  const store = useSelector((state) => state);
  //const { isAuthenSuccess } = store?.auth;
  const { activeState } = store?.decision;
  const [state, setState] = React.useState('');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(decisionActions.setActiveState('login'));
    monitorAuthenExpire(() => {
      dispatch(decisionActions.setActiveState('login'));
    });
  }, []);

  React.useEffect(() => {
    if (!checkIsTokenExpired() && activeState?.state) {
      setState(activeState?.state);
    } else {
      if (activeState?.state !== 'login') {
        dispatch(decisionActions.setActiveState('login'));
      } else {
        setState(activeState?.state);
      }
    }
  }, [activeState]);

  return (
    <div root='' className={'app-font-face'}>
      <Wrapper open consider={state}>
        <When condition={'login'}>
          <LoginComponent />
        </When>
        <When condition={'introduce'}>
          <IntroduceComponent />
        </When>
        <When condition={'principle'}>
          <PrincipleComponent />
        </When>
        <When condition={'introduce-end'}>
          <IntroduceEndComponent />
        </When>
        <When condition={'schedule'}>
          <ScheduleComponent />
        </When>
      </Wrapper>
    </div>
  );
};
export default Decision;
