import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { calvh } from 'utils/element';

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ styles }) => styles?.width || calvh('80vh'),
    padding: ({ styles }) => styles?.padding || undefined,
    margin: ({ styles }) => styles?.margin || undefined,
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'row-header-container': {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: calvh('0.5vh'),
    height: '100%',
    border: ({ styles }) =>
      styleBorder({ ...(styles?.border || {}), color: 'transparent' }),
  },
  'row-header': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  'row-header-item': {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    //border: ({ styles }) => `${styles?.border?.width} solid transparent`,
  },
  'table-column-item': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: ({ styles }) => styleBorder(styles?.border),
  },
  'table-column': {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  'table-row': {
    display: 'flex',
    flexWrap: 'wrap',
    width: ({ styles }) => styles?.row?.width,
  },
  'table-row-item': {
    flexBasis: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  'table-cell': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));
const styleBorder = (border) => `${border?.width} solid ${border?.color}`;

const Table = (props) => {
  const {
    columns,
    rows,
    rowHeaders,
    styles,
    noRowGutter,
    noColumnGutter,
    noColumnHeader,
    noHeader,
  } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [rowHeight, setRowHeight] = React.useState(0);
  const [columnHeight, setColumnHeight] = React.useState(0);
  const refColumns = React.useRef(null);
  const refRows = React.useRef(null);

  React.useEffect(() => {
    if ((rows || []).length) {
      setTimeout(() => {
        handleCalSize();
      }, 200);
    }
  }, [rows]);

  const handleCalSize = () => {
    let column, row;
    if (refColumns.current) {
      column = refColumns.current.querySelector('[column]');
    }
    if (refRows.current) {
      row = refRows.current.querySelector('[row]');
    }

    if (column?.firstChild) {
      setColumnHeight(column?.firstChild?.getAttribute('height'));
    }
    if (row?.firstChild?.firstChild) {
      setRowHeight(row?.firstChild?.firstChild?.getAttribute('height'));
    }
  };

  return (
    <div className={c('root')}>
      <div
        className={c('container')}
        style={{
          visibility:
            noHeader && rowHeight
              ? 'visible'
              : columnHeight && rowHeight
              ? 'visible'
              : 'hidden',
        }}
      >
        {(rowHeaders || []).length > 0 ? (
          <div
            style={{
              width: 'min-content',
            }}
          >
            <div className={c('row-header-container')}>
              {!noHeader ? (
                <div
                  className={c('header')}
                  style={{
                    height: columnHeight,
                    width: styles?.header?.column?.width || '12vw',
                  }}
                ></div>
              ) : null}

              <div className={c('row-header')}>
                {(rowHeaders || []).map((rowHeader, ridx) => (
                  <div
                    key={'hrow_' + ridx}
                    row=''
                    text-header=''
                    className={'text' + ' ' + c('row-header-item')}
                    style={{
                      border: 'none',
                    }}
                  >
                    <div
                      style={{
                        height: rowHeight,
                        display: 'flex',
                        width: styles?.header?.cell?.width || '12vw',
                        justifyContent:
                          styles?.header?.cell?.justifyContent || undefined,
                        paddingRight:
                          styles?.header?.cell?.paddingRight || undefined,
                      }}
                    >
                      {rowHeader}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        <div
          style={{
            width: (rowHeaders || []).length ? '80%' : '100%',
          }}
        >
          <div
            columns=''
            className={c('table-column')}
            style={{ display: noColumnHeader ? 'none' : undefined }}
            ref={refColumns}
          >
            {(columns || []).map((column, cidx) => (
              <div
                column=''
                key={'column_' + cidx}
                text-header=''
                className={'text' + ' ' + c('table-column-item')}
                style={{
                  width: styles?.column?.widths
                    ? styles?.column?.widths[cidx]
                    : `${100 / (columns || []).length}%`,
                  borderLeft: cidx > 0 ? 'none' : styleBorder(styles?.border),
                }}
              >
                {column}
              </div>
            ))}
          </div>
          <div rows='' className={c('table-row')} ref={refRows}>
            {(rows || []).map((row, ridx) => (
              <div key={'row_' + ridx} row='' className={c('table-row-item')}>
                {(Object.keys(row) || []).map((key, kidx) => (
                  <div
                    text-cell=''
                    key={'krow_' + kidx}
                    className={'text' + ' ' + c('table-cell')}
                    style={{
                      flexBasis: styles?.column?.widths
                        ? styles?.column?.widths[kidx]
                        : `${100 / (columns || []).length}%`,
                      borderTop:
                        (noColumnHeader && ridx > 0) || noRowGutter
                          ? 'none'
                          : styleBorder(styles?.border),
                      borderLeft:
                        kidx > 0 ? 'none' : styleBorder(styles?.border),
                      borderRight:
                        noColumnGutter && kidx < Object.keys(row).length - 1
                          ? 'none'
                          : styleBorder(styles?.border),
                      borderBottom:
                        noRowGutter && ridx < rows.length - 1
                          ? 'none'
                          : styleBorder(styles?.border),
                      minWidth: styles?.cell?.minWidths?.[kidx]
                        ? `${styles.cell.minWidths[kidx]}`
                        : undefined,
                      width:
                        styles?.cell?.widths?.[kidx] || styles?.cell?.width,
                    }}
                  >
                    {row[key]}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
