import axios from 'host';
import config from '../config';

export const getSchedule = (next = null, error = null, complete = null) => {
  axios
    .get('/teach-times')
    .then((resp) => {
      const monday = (resp?.data || []).filter((item) => item.day === 'MONDAY');
      const tuesday = (resp?.data || []).filter(
        (item) => item.day === 'TUESDAY'
      );
      const wednesday = (resp?.data || []).filter(
        (item) => item.day === 'WEDNESDAY'
      );
      const thursday = (resp?.data || []).filter(
        (item) => item.day === 'THURSDAY'
      );
      const friday = (resp?.data || []).filter((item) => item.day === 'FRIDAY');
      const saturday = (resp?.data || []).filter(
        (item) => item.day === 'SATURDAY'
      );
      const sunday = (resp?.data || []).filter((item) => item.day === 'SUNDAY');

      next &&
        next([
          {
            day: 'จันทร์',
            times: [
              {
                mark: false,
                time: '9.00-12.00',
                selected: false,
                id: monday.find((item) => item.timePeriod === 'MORNING')?.id,
              },
              {
                mark: false,
                time: '12.00-15.00',
                selected: false,
                id: monday.find((item) => item.timePeriod === 'AFTERNOON')?.id,
              },
              {
                mark: true,
                time: '15.00-18.00',
                selected: false,
                id: monday.find((item) => item.timePeriod === 'EVENING')?.id,
              },
            ],
          },
          {
            day: 'อังคาร',
            times: [
              {
                mark: false,
                time: '9.00-12.00',
                selected: false,
                id: tuesday.find((item) => item.timePeriod === 'MORNING')?.id,
              },
              {
                mark: false,
                time: '12.00-15.00',
                selected: false,
                id: tuesday.find((item) => item.timePeriod === 'AFTERNOON')?.id,
              },
              {
                mark: true,
                time: '15.00-18.00',
                selected: false,
                id: tuesday.find((item) => item.timePeriod === 'EVENING')?.id,
              },
            ],
          },
          {
            day: 'พุธ',
            times: [
              {
                mark: false,
                time: '9.00-12.00',
                selected: false,
                id: wednesday.find((item) => item.timePeriod === 'MORNING')?.id,
              },
              {
                mark: false,
                time: '12.00-15.00',
                selected: false,
                id: wednesday.find((item) => item.timePeriod === 'AFTERNOON')
                  ?.id,
              },
              {
                mark: true,
                time: '15.00-18.00',
                selected: false,
                id: wednesday.find((item) => item.timePeriod === 'EVENING')?.id,
              },
            ],
          },
          {
            day: 'พฤหัส',
            times: [
              {
                mark: false,
                time: '9.00-12.00',
                selected: false,
                id: thursday.find((item) => item.timePeriod === 'MORNING')?.id,
              },
              {
                mark: false,
                time: '12.00-15.00',
                selected: false,
                id: thursday.find((item) => item.timePeriod === 'AFTERNOON')
                  ?.id,
              },
              {
                mark: true,
                time: '15.00-18.00',
                selected: false,
                id: thursday.find((item) => item.timePeriod === 'EVENING')?.id,
              },
            ],
          },
          {
            day: 'ศุกร์',
            times: [
              {
                mark: false,
                time: '9.00-12.00',
                selected: false,
                id: friday.find((item) => item.timePeriod === 'MORNING')?.id,
              },
              {
                mark: false,
                time: '12.00-15.00',
                selected: false,
                id: friday.find((item) => item.timePeriod === 'AFTERNOON')?.id,
              },
              {
                mark: true,
                time: '15.00-18.00',
                selected: false,
                id: friday.find((item) => item.timePeriod === 'EVENING')?.id,
              },
            ],
          },
          {
            day: 'เสาร์',
            times: [
              {
                mark: true,
                time: '8.00-19.00',
                selected: false,
                id: saturday.find((item) => item.timePeriod === 'ALLDAY')?.id,
              },
            ],
          },
          {
            day: 'อาทิตย์',
            times: [
              {
                mark: true,
                time: '8.00-19.00',
                selected: false,
                id: sunday.find((item) => item.timePeriod === 'ALLDAY')?.id,
              },
            ],
          },
        ]);
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};

export const getTrainerSchedule = (
  { id },
  next = null,
  error = null,
  complete = null
) => {
  let apiUrl = '';
  if (window.location.href.includes(config.adminPath)) {
    apiUrl = `/teachers/${id}/teach-time`;
  } else {
    apiUrl = `/teacher/teach-time`;
  }
  axios
    .get(apiUrl)
    .then((resp) => {
      const selectedSchedule = (resp.data || []).map((item) => item.id);
      getSchedule(
        (schedule) => {
          const updateSchedule = [...(schedule || [])];
          updateSchedule.forEach((day) => {
            (day?.times || []).forEach((time) => {
              time.selected = selectedSchedule.includes(time.id);
            });
          });
          next && next(updateSchedule);
        },
        error,
        complete
      );
    })
    .catch((err) => {
      error && error(err);
    });
  // .finally(() => {
  //   complete && complete();
  // });

  // setTimeout(() => {
  //   next([
  //     {
  //       day: 'จันทร์',
  //       times: [
  //         { mark: false, time: '9.00-12.00', selected: false },
  //         { mark: false, time: '12.00-15.00', selected: false },
  //         { mark: true, time: '15.00-18.00', selected: false },
  //       ],
  //     },
  //     {
  //       day: 'อังคาร',
  //       times: [
  //         { mark: false, time: '9.00-12.00', selected: false },
  //         { mark: false, time: '12.00-15.00', selected: false },
  //         { mark: true, time: '15.00-18.00', selected: false },
  //       ],
  //     },
  //     {
  //       day: 'พุธ',
  //       times: [
  //         { mark: false, time: '9.00-12.00', selected: false },
  //         { mark: false, time: '12.00-15.00', selected: false },
  //         { mark: true, time: '15.00-18.00', selected: false },
  //       ],
  //     },
  //     {
  //       day: 'พฤหัส',
  //       times: [
  //         { mark: false, time: '9.00-12.00', selected: false },
  //         { mark: false, time: '12.00-15.00', selected: false },
  //         { mark: true, time: '15.00-18.00', selected: false },
  //       ],
  //     },
  //     {
  //       day: 'ศุกร์',
  //       times: [
  //         { mark: false, time: '9.00-12.00', selected: false },
  //         { mark: false, time: '12.00-15.00', selected: false },
  //         { mark: true, time: '15.00-18.00', selected: false },
  //       ],
  //     },
  //     {
  //       day: 'เสาร์',
  //       times: [{ mark: true, time: '8.00-19.00', selected: false }],
  //     },
  //     {
  //       day: 'อาทิตย์',
  //       times: [{ mark: true, time: '8.00-19.00', selected: false }],
  //     },
  //   ]);
  //   complete();
  // }, 1000);
};

export const editTrainerSchedule = (
  { schedule, id },
  next = null,
  error = null,
  complete = null
) => {
  let apiUrl = '';
  if (window.location.href.includes(config.adminPath)) {
    apiUrl = `/teachers/${id}/teach-time`;
  } else {
    apiUrl = `/teacher/teach-time`;
  }
  axios
    .post(apiUrl, {
      teachTimeIds: (schedule || []).reduce((accum, day) => {
        const selected = day.times
          .filter((item) => item?.selected)
          .map((item) => item?.id);
        return Array.from(new Set([...accum, ...selected]));
      }, []),
    })
    .then((resp) => {
      next && next(resp.data);
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};
