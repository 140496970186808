import * as type from '../actions/config';

const initialState = {
  isMutedSound: window.localStorage.getItem('isMutedSound'),
};

export default function (state = initialState, action) {
  switch (action.type) {
    case type.SET_MUTE_SOUND:
      return { ...state, isMutedSound: action.payload.value };
    default:
      return state;
  }
}
