import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from 'lib/history';
import UserCheckListDecision from 'pages/user-checklist/decision';
import TrainersStatusDecision from 'pages/trainers/decision';
import StudentDecision from 'pages/student/decision';
import { Grid } from '@material-ui/core';
import store from './store';
import config from './config';
import imgLoading from 'images/circle-loading-animation1.gif';
import './App.css';

// import { setAuthToken } from 'utils/auth';

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

const Loading = () => (
  <Grid>
    <img src={imgLoading} />
  </Grid>
);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              exact
              path={config?.adminPath}
              component={TrainersStatusDecision}
            />
            <Route
              exact
              path={config?.studentPath}
              component={StudentDecision}
            />
            <Route exact path='/' component={UserCheckListDecision} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
