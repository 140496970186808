import * as type from '../actions/decision';

const initialState = { activeState: '', actionState: {} };
export default function (state = initialState, action) {
  switch (action.type) {
    case type.SET_ACTIVE_STATE:
      return { ...state, activeState: action.payload };
    case type.SET_ACTION_STATE:
      return {
        ...state,
        actionState: {
          ...(state.actionState || {}),
          [action?.payload?.action]: action?.payload?.value,
        },
      };
    default:
      return state;
  }
}
