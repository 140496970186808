import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useImagesContext } from 'contexts/ImagesContext';
import { joinClasses, callService } from 'utils/utility';
import { errorAlert, warningAlert } from 'utils/alert';
import { logout } from 'services/auth';
import ButtonLogout from 'components/fragments/ButtonLogout';
import Progress from 'components/fragments/Progress';
import { calvh } from 'utils/element';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('100vh'),
    overflow: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  title: {
    position: 'absolute',
    zIndex: 2,
    fontSize: calvh('5vh'),
    color: '#7a390b',
    top: 4,
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar': {
    position: 'fixed',
    top: 0,
    left: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '4rem',
    zIndex: 1,
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
  'img-trainers-logo': {
    height: calvh('10vh'),
    maxWidth: '16vw',
    objectFit: 'contain',
  },
}));

const withTitleState = {
  trainers: true,
};

const Background = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [withTitle, setWithTitle] = React.useState(false);
  const [displayLogout, setDisplayLogout] = React.useState(false);
  const [allowLogout, setAllowLogout] = React.useState(false);
  const [logoutFlag, setLogoutFlag] = React.useState({});
  const store = useSelector((state) => state);
  const { activeState, actionState } = store?.decision;

  React.useEffect(() => {
    if (Object.keys(withTitleState).includes(activeState?.state)) {
      setWithTitle(withTitleState[activeState?.state]);
    } else {
      setWithTitle(false);
    }
    if (activeState?.state === 'schedule') {
      setDisplayLogout(true);
    } else {
      setDisplayLogout(false);
    }
  }, [activeState]);

  React.useEffect(() => {
    if (actionState?.['editTrainerInfo']) {
      setAllowLogout(true);
    }
  }, [actionState]);

  const handleClickLogout = () => {
    if (allowLogout) {
      handleLogoutService();
    } else {
      warningAlert(undefined, 'กรุณาบันทึกข้อมูลก่อนออกจากระบบ.');
    }
  };

  const handleLogoutService = (next) => {
    callService(
      logout,
      null,
      setLogoutFlag,
      (resp) => {
        //dispatch(authActions.setAuthenSuccess());
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  return (
    <div
      className={c('root')}
      root=''
      style={{
        backgroundImage: `url(${images['trainers-bg-bg.jpg']})`,
      }}
    >
      {withTitle ? (
        <div className={'text' + ' ' + c('title')}>
          <img
            className={c('img-trainers-logo')}
            src={images['trainers-logo.png']}
          />
        </div>
      ) : null}
      <img
        className={c('img-top-bar')}
        src={images['trainers-background-top.png']}
      />
      <img
        className={c('img-left-bar')}
        src={images['trainers-background-side.png']}
      />
      <div style={{ width: '89%', height: '100%' }}>{props.children}</div>
      <img
        className={c('img-right-bar')}
        src={images['trainers-background-side.png']}
      />
      <img
        className={c('img-bottom-bar')}
        src={images['trainers-background-bottom.png']}
      />
      {displayLogout ? (
        <div style={{ position: 'absolute', zIndex: 999, right: 5, bottom: 2 }}>
          <ButtonLogout
            onClick={handleClickLogout}
            disabled={logoutFlag.request}
          />
        </div>
      ) : null}
      {logoutFlag.request ? <Progress /> : null}
    </div>
  );
};

export default Background;
