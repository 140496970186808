import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';
import ButtonBack from 'components/fragments/ButtonBack';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
}));

const StudentLevel = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: '100%' }}
      >
        <Grid
          item
          container
          justifyContent='center'
          alignItems='flex-start'
          alignContent='flex-start'
        >
          <Grid item xs={12} container justifyContent='center'>
            <img
              style={{
                height: 'fit-content',
                maxHeight: calvh('88vh'),
                maxWidth: '88vw',
                objectFit: 'contain',
                objectPosition: '50% 0%',
              }}
              src={images[`student-level-use.jpg`]}
            />
            {/* <div
              style={{
                width: '100%',
                height: '80vh',
                display: 'flex',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundPosition: 'center',
                backgroundImage: `url(${images[`student-level-use.jpg`]})`,
              }}
            ></div> */}
          </Grid>
        </Grid>
        <div style={{ position: 'absolute', bottom: '-4vh', zIndex: 2 }}>
          <ButtonBack />
        </div>
      </Grid>
    </div>
  );
};

export default StudentLevel;
