import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  joinClasses,
  callService,
  checkValidity,
  isSafariMobile,
} from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert, successAlert } from 'utils/alert';
import { getTrainerSchedule } from 'services/schedule';
import { editTrainerInfo } from 'services/trainer';
import { useImagesContext } from 'contexts/ImagesContext';
import { useSelector, useDispatch } from 'react-redux';
import Progress from 'components/fragments/Progress';
import ButtonCopy from 'components/fragments/ButtonCopy';
import ButtonSave from 'components/fragments/ButtonSave';
import ButtonBack from 'components/fragments/ButtonBack';
import Table from 'components/fragments/Table';
import ScheduleTable from 'components/fragments/ScheduleTable';
import TextField from 'components/fragments/TextField';
import SuccessAlert from 'components/fragments/SuccessAlert';
import * as decisionActions from 'actions/decision';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
    },
  },
  'container-devider': {
    width: '95%',
    height: calvh('0.25vh'),
    backgroundColor: '#6c2912',
  },
  'container-form': {
    '@media (min-width:850px)': {
      justifyContent: 'center',
    },
  },
  'img-field-tel': {
    height: calvh('4vh'),
    maxWidth: '24vw',
    objectFit: 'contain',
  },
  'img-field-name': {
    height: calvh('5vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
  },
  'img-field-nickname': {
    height: calvh('5vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
  },
}));

const TrainerSchedule = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [getTrainerScheduleFlag, setGetTrainerScheduleFlag] = React.useState(
    {}
  );
  const [editTrainerInfoFlag, setEditTrainerInfoFlag] = React.useState({});
  const [schedule, setSchedule] = React.useState([]);
  const [updateSchedule, setUpdateSchedule] = React.useState([]);
  const [editTrainerInfoResp, setEditTrainerInfoResp] =
    React.useState(undefined);
  const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
  const trainerSelected = useSelector(
    (state) => state?.decision?.activeState?.props?.trainer
  );
  const [form, setForm] = React.useState({
    phoneNumber: trainerSelected?.phoneNumber,
    name: trainerSelected?.name,
    nickName: trainerSelected?.nickName,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    handleGetTrainerScheduleService();
  }, []);

  const handleChangeSchedule = (updateSchedule) => {
    setUpdateSchedule(updateSchedule);
  };

  const handleValidate = () => {
    let valid = true;
    let message = '';
    if (!checkValidity(form['phoneNumber'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุหมายเลขโทรศัพท์';
    } else if (!checkValidity(form['phoneNumber'], { isPhoneNumber: true })) {
      valid = false;
      message = 'กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง';
    } else if (!checkValidity(form['name'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุชื่อ';
    } else if (!checkValidity(form['nickName'], { required: true })) {
      valid = false;
      message = 'กรุณาระบุชื่อเล่น';
    }
    return { valid, message };
  };

  const handleClickSave = () => {
    //dispatch(decisionActions.setActionState('editTrainerInfo', true));
    const { valid, message } = handleValidate();
    if (valid) {
      handleEditTrainerInfoService(() => {
        //handleOpenSuccessAlert(true);
        successAlert();
        //dispatch(decisionActions.setActionState('editTrainerInfo', true));
      });
    } else {
      warningAlert(undefined, message);
    }
  };

  const handleClickBack = () => {
    dispatch(decisionActions.setActiveState('trainers'));
  };

  const handleChangeForm = (event, field) => {
    const value = event.target.value;
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleOpenSuccessAlert = (value) => {
    setOpenSuccessAlert(value);
  };

  const handleGetTrainerScheduleService = (next) => {
    const id = trainerSelected?.id;
    callService(
      getTrainerSchedule,
      { id },
      setGetTrainerScheduleFlag,
      (resp) => {
        setSchedule(resp);
        setUpdateSchedule(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const handleEditTrainerInfoService = (next) => {
    const id = trainerSelected?.id;
    callService(
      editTrainerInfo,
      { ...form, id, schedule: updateSchedule },
      setEditTrainerInfoFlag,
      (resp) => {
        setEditTrainerInfoResp(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const pendingFlag = React.useMemo(
    () => getTrainerScheduleFlag.request,
    [getTrainerScheduleFlag.request]
  );

  const successFlag = React.useMemo(
    () => getTrainerScheduleFlag.success,
    [getTrainerScheduleFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid
            item
            xs={12}
            container
            className={c('container-form')}
            style={{ paddingBottom: calvh('3vh') }}
          >
            <Table
              styles={{
                width: 'fit-content',
                row: {
                  // width: 'min-content',
                },
                border: {
                  width: calvh('0.1vh'),
                  color: 'transparent',
                },
                column: {
                  //widths: [`100%`],
                },
                cell: {
                  // minWidths: ['20vw'],
                },
                header: {
                  cell: {
                    width: calvh('12vh'),
                  },
                },
              }}
              rows={[
                {
                  field: (
                    <div height={calvh('5vh')} style={{ width: '100%' }}>
                      <TextField
                        imgTextBox={images['trainer-schedule-box.png']}
                        //helperText={'ไม่ต้องใส่นามสกุล'}
                        styles={{
                          width: '100%',
                          inputMaxWidth: calvh('50vh'),
                          inputWidth: '100%',
                          fontSize: calvh('3.2vh'),
                          fontSizeSm: calvh('2.6vh'),
                          inputMargin: calvh('0.5vh'),
                          inputHeight: calvh('3.2vh'),
                        }}
                        onChange={(e) => handleChangeForm(e, 'name')}
                        value={form['name']}
                      />
                    </div>
                  ),
                },
                {
                  field: (
                    <div height={calvh('5vh')} style={{ width: '100%' }}>
                      <TextField
                        imgTextBox={images['trainer-schedule-box.png']}
                        styles={{
                          width: '100%',
                          inputMaxWidth: calvh('50vh'),
                          inputWidth: '100%',
                          fontSize: calvh('3.2vh'),
                          fontSizeSm: calvh('2.6vh'),
                          inputMargin: calvh('0.5vh'),
                          inputHeight: calvh('3.2vh'),
                        }}
                        onChange={(e) => handleChangeForm(e, 'nickName')}
                        value={form['nickName']}
                      />
                    </div>
                  ),
                },
              ]}
              rowHeaders={[
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <img
                    className={c('img-field-nickname')}
                    src={images['trainer-schedule-nickname.png']}
                    height={calvh('5vh')}
                  />
                </div>,
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <img
                    className={c('img-field-name')}
                    src={images['trainer-schedule-name.png']}
                  />
                </div>,
              ]}
              noColumnHeader
              noHeader
              noColumnGutter
            />
          </Grid>

          <Grid item xs={12}>
            <ScheduleTable
              schedule={schedule}
              onChange={handleChangeSchedule}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{
              flexWrap: 'nowrap',
              padding: `${calvh('6vh')} ${calvh('1vh')}`,
            }}
            alignItems='center'
            className={c('container-form')}
          >
            <TextField
              label={
                <img
                  className={c('img-field-tel')}
                  src={images['trainer-schedule-tel.png']}
                />
              }
              styles={{
                //width: '100%',
                inputMaxWidth: calvh('50vh'),
                //inputWidth: '60%',
                fontSize: calvh('3.2vh'),
                fontSizeSm: calvh('2.6vh'),
                inputMargin: calvh('0.5vh'),
                inputHeight: calvh('3.2vh'),
              }}
              onChange={(e) => handleChangeForm(e, 'phoneNumber')}
              value={form['phoneNumber']}
            />
            {isSafariMobile() ? null : (
              <ButtonCopy
                height={calvh('3.5vh')}
                copyText={form['phoneNumber']}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: `${calvh('1vh')} 0px 0px 0px` }}
          >
            <div className={c('container-devider')} />
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{
              padding: `${calvh('1vh')} ${calvh('1vh')} 0px ${calvh('1vh')}`,
              flexWrap: 'nowrap',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <ButtonSave
                onClick={handleClickSave}
                height={calvh('5vh')}
                disabled={editTrainerInfoFlag.request}
              />
              <ButtonBack onClick={handleClickBack} height={calvh('5vh')} />
            </div>
          </Grid>
        </Grid>
      ) : null}
      {editTrainerInfoFlag.request ? <Progress /> : null}
      {/* {openSuccessAlert ? (
        <SuccessAlert onClose={() => handleOpenSuccessAlert(false)} />
      ) : null} */}
    </div>
  );
};

export default TrainerSchedule;
