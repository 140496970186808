import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import Comment from 'components/fragments/Comment/Comment';
const CommentComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/Comment/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <Comment {...props} />
  </ImagesProvider>
);

export default CommentComponent;
