import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { clickAnime, calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';
import Table from 'components/fragments/Table';
import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  'text-cell': {
    fontSize: calvh('2vh'),
    '@media (min-width:434px)': {
      fontSize: calvh('2.5vh'),
    },
  },
  'text-header': {
    whiteSpace: 'nowrap',
    fontSize: calvh('2.8vh'),
    '@media (min-width:434px)': {
      fontSize: calvh('3.3vh'),
    },
    '@media (max-width:434px)': {
      fontSize: calvh('2.6vh'),
    },
  },
  'text-row-header': {
    whiteSpace: 'nowrap',
    fontSize: calvh('3vh'),
    '@media (min-width:434px)': {
      fontSize: calvh('3.5vh'),
    },
    '@media (max-width:434px)': {
      fontSize: calvh('2.8vh'),
    },
  },
  'column-header': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: ({ columnHeight }) => columnHeight,
  },
  'row-header': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'cell-container': {
    display: 'flex',
    width: '100%',
    height: ({ rowHeight }) => rowHeight,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cell: {
    width: '80%',
    height: '80%',
    border: `${calvh('0.5vh')} solid #fff`,
    backgroundColor: ({ selected }) => (selected ? '#009420' : 'gray'),
  },
  'img-star': {
    objectFit: 'contain',
    height: calvh('3vh'),
    width: '3vw',
    '&[marksize=small]': {
      height: calvh('2vh'),
      width: '2vw',
    },
  },
  'text-mark': {
    textAlign: 'center',
    padding: 2,
    color: 'red',
    fontSize: calvh('3vh'),
    '@media (max-width:434px)': {
      fontSize: calvh('2.5vh'),
    },
  },
  'text-mark-container': {
    flexBasis: '100%',
  },
  'text-mark-warp': {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: calvh('5vh'),
    '@media (max-width:434px)': {
      fontSize: calvh('4vh'),
    },
  },
}));

const columnHeight = calvh('5vh');
const rowHeight = calvh('5vh');

const ColumnHeader = (props) => {
  const { value, mark, images, height, marksize } = props;
  const c = joinClasses(useStyles({ ...props, columnHeight: height }));
  return (
    <div className={c('column-header text-header')} height={height}>
      {mark ? (
        <img
          className={c('img-star')}
          src={images['schedule-star.png']}
          marksize={marksize}
        />
      ) : null}
      {value}
    </div>
  );
};

const RowHeader = (props) => {
  const { value } = props;
  const c = joinClasses(useStyles({ ...props }));

  return <div className={c('row-header text-row-header')}>{value}</div>;
};

const Cell = (props) => {
  const { onClick, height, data } = props;

  const c = joinClasses(useStyles({ ...props, rowHeight: height }));
  return (
    <div className={c('cell-container')} height={height}>
      <ButtonBase
        className={c('cell text-cell')}
        ref={clickAnime}
        onClick={() => onClick(data)}
      ></ButtonBase>
    </div>
  );
};

// const mapColumnIndex = (field) => {
//   const map = {
//     selectTimes: 0,
//     times: 1,
//   };
//   return Number.isInteger(map[field]) ? map[field] : Number.MAX_SAFE_INTEGER;
// };

const checkExtraDay = (day) => {
  const extraDays = ['เสาร์', 'อาทิตย์'];
  return extraDays.includes(day);
};

const ScheduleTable = (props) => {
  const { schedule, onChange } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  //const [selected, setSelected] = React.useState({});
  const [timeRows, setTimeRows] = React.useState([]);

  React.useEffect(() => {
    setTimeRows(schedule || []);
  }, [schedule]);

  // ------------------- time -----------------------//
  const timeColumns = React.useMemo(() => {
    return timeRows
      .filter((row) => !checkExtraDay(row?.day))
      .reduce((accum, row) => {
        const result = (row.times || []).find(
          (time) => !accum.find((acc) => acc.time === time?.time)
        );
        if (result) {
          accum.push(result);
        }
        return accum;
      }, []);
  }, [timeRows]);
  // ---------------------end time ----------------------//
  //--------------------- time content -----------------//
  const timeColumnContents = React.useMemo(() => {
    return (timeColumns || []).map((column) => (
      <ColumnHeader
        height={columnHeight}
        value={column?.time}
        mark={column?.mark}
        images={images}
        marksize={'small'}
      />
    ));
  }, [timeColumns, images]);
  const timeRowContents = React.useMemo(() => {
    return (timeRows || [])
      .filter((row) => !checkExtraDay(row?.day))
      .map((row, ridx) => {
        const result = {};
        (row?.times || [])
          .sort((a, b) => b.time - a.time)
          .forEach((time, cidx) => {
            result[cidx] = (
              <Cell
                height={rowHeight}
                selected={row?.times[cidx]?.selected}
                data={row}
                onClick={() => {
                  row.time = row?.times[cidx]?.time;
                  handleSelectDate({
                    ridx,
                    cidx,
                    data: row,
                  });
                }}
              />
            );
          });
        return result;
      });
  }, [timeRows]);
  const timeRowHeaderContents = React.useMemo(() => {
    return (timeRows || [])
      .filter((row) => !checkExtraDay(row?.day))
      .map((rowHeader) => <RowHeader value={rowHeader?.day} />);
  }, [timeRows]);
  //---------------------end time content ----------------//

  // --------------------- extra time -------------------//

  const extraTimeRows = React.useMemo(() => {
    return (timeRows || []).filter((row) => checkExtraDay(row?.day));
  }, [timeRows]);
  // --------------------- end extra time ---------------//
  // --------------------- extra time content -------------------//
  const extraTimeRowContents = React.useMemo(() => {
    return (extraTimeRows || []).map((row, ridx) => {
      const result = {};
      (row?.times || [])
        .sort((a, b) => b.time - a.time)
        .forEach((time) => {
          Object.keys(time).forEach((key, cidx) => {
            switch (key) {
              case 'time':
                result[key] = (
                  <ColumnHeader
                    height={columnHeight}
                    value={time[key]}
                    mark={time['mark']}
                    images={images}
                  />
                );
                break;
              case 'selected':
                result[key] = (
                  <Cell
                    height={rowHeight}
                    selected={time[key]}
                    onClick={() => {
                      row.time = time?.time;
                      handleSelectDate({
                        ridx,
                        cidx,
                        data: row,
                      });
                    }}
                  />
                );
                break;
            }
          });
        });
      return result;
    });
  }, [extraTimeRows, images]);
  const extraTimeRowHeaderContents = React.useMemo(() => {
    return (extraTimeRows || []).map((extraTimeRow) => (
      <RowHeader value={extraTimeRow?.day} />
    ));
  }, [extraTimeRows]);
  // --------------------- end extra time content ---------------//

  const handleSelectDate = ({ data }) => {
    const updateTimeRows = [...timeRows];
    (updateTimeRows || []).find(
      (row) =>
        row?.day === data?.day &&
        (row.times || []).find((time) => {
          let result = false;
          if (time?.time === data?.time) {
            result = true;
            time.selected = !time.selected;
          }
          return result;
        })
    );
    setTimeRows(updateTimeRows);
    onChange && onChange([...updateTimeRows]);
  };

  return (
    <div className={c('root')}>
      <Table
        styles={{
          border: {
            width: calvh('0.4vh'),
            color: 'saddlebrown',
          },
          margin: `0px 0px ${calvh('2vh')} 0px`,
          header: {
            cell: {
              justifyContent: 'flex-end',
              paddingRight: '2vw',
            },
          },
        }}
        columns={timeColumnContents}
        rows={timeRowContents}
        rowHeaders={timeRowHeaderContents}
        noRowGutter
      />
      <Table
        styles={{
          border: {
            width: calvh('0.4vh'),
            color: 'saddlebrown',
          },
          column: {
            widths: [
              `${100 - 100 / (timeColumns || []).length}%`,
              `${100 / (timeColumns || []).length}%`,
            ],
          },
          header: {
            cell: {
              justifyContent: 'flex-end',
              paddingRight: '2vw',
            },
          },
        }}
        rows={extraTimeRowContents}
        rowHeaders={extraTimeRowHeaderContents}
        //columns={timeColumnContents}
        // rows={timeRowContents}
        // rowHeaders={timeRowHeaderContents}
        noColumnHeader
        noHeader
        noColumnGutter
      />
      <div className={c('text-mark-container')}>
        <div className={c('text-mark-warp')}>
          <img className={c('img-star')} src={images['schedule-star.png']} />
          <div className={c('text-mark')}>
            {' = ช่วงเวลาที่เด็กมาเรียนเยอะ'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTable;
