import axios from 'host';
import config from '../config';

export const getStudentPhoto = (
  req,
  next = null,
  error = null,
  complete = null
) => {
  // axios
  //   .get('/teachers')
  //   .then((resp) => {
  //     next && next(resp.data);
  //   })
  //   .catch((err) => {
  //     error && error(err);
  //   })
  //   .finally(() => {
  //     complete && complete();
  //   });
  setTimeout(() => {
    next &&
      next({
        comment: 'เรียนโกะเก่งงานรู้จัดการชีวิต สถาบันสอนหมากล้อมตัวกลม',
        rows: [
          {
            date: '1/01/21',
            image:
              'https://www.usgo.org/news/wp-content/uploads/2017/02/20161204_133056-600x352.jpg',
          },
          {
            date: '2/02/21',
            image:
              'https://www.chinadailyhk.com/attachments/image/65/90/96/462950_168247/462950_168247_800_450_jpg.jpg',
          },
          {
            date: '3/02/21',
            image:
              'https://gameofgo.app/images/young-kids-playing-game-of-go.jpg',
          },
          {
            date: '4/02/21',
            image:
              'https://scontent.fkdt2-1.fna.fbcdn.net/v/t1.6435-9/64284826_2050810568360918_7703211982123433984_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeEEOjq1_41th6KBsMdBEc69ZFUfdjIMVKdkVR92MgxUp3B_YkR17iURI-VPJZKO1zq59wahS-S9upH0KMpwKfWE&_nc_ohc=1ZoWFU-uILcAX82nJdA&_nc_ht=scontent.fkdt2-1.fna&oh=00_AfAtgaz7ZUgILiF599avwG4-spkIkv4l3o4BGBnTBVEjqQ&oe=6406E95C',
          },
          {
            date: '5/02/21',
            image:
              'https://www.eurogofed.org/newsp/files/22nd_EYGC_Grenoble_2017/13x13.jpg',
          },
        ],
      });
  }, 1000);
  complete && complete();
};
