import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import NevigateBack from 'components/fragments/NevigateBack/NevigateBack';
const NevigateBackComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/NevigateBack/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <NevigateBack {...props} />
  </ImagesProvider>
);

export default NevigateBackComponent;
