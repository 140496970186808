import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonSave from 'components/fragments/ButtonSave/ButtonSave';
const ButtonSaveComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonSave/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonSave {...props} />
  </ImagesProvider>
);

export default ButtonSaveComponent;
