import axios from 'host';
import config from '../config';

export const getStudentGameComment = (
  req,
  next = null,
  error = null,
  complete = null
) => {
  // axios
  //   .get('/teachers')
  //   .then((resp) => {
  //     next && next(resp.data);
  //   })
  //   .catch((err) => {
  //     error && error(err);
  //   })
  //   .finally(() => {
  //     complete && complete();
  //   });
  setTimeout(() => {
    next &&
      next([
        {
          date: '1/01/21',
          comment:
            'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อยตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อยตั้งใจเรียนดีมาก',
          byTeacher: 'ครูนัท',
        },
        {
          date: '2/02/21',
          comment:
            'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย',
          byTeacher: 'ครูนัท',
        },
        {
          date: '3/02/21',
          comment: 'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย',
          byTeacher: 'ครูนัท',
        },
        {
          date: '4/02/21',
          comment:
            'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย',
          byTeacher: 'ครูนัท',
        },
        {
          date: '5/02/21',
          comment: 'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย',
          byTeacher: 'ครูนัท',
        },
        {
          date: '6/02/21',
          comment: 'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ครูนัท',
          byTeacher: 'ครูนัท',
        },
        {
          date: '7/02/21',
          comment:
            'ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย กลับบ้านทำโจทย์เพิ่มเยอะหน่อย ตั้งใจเรียนดีมาก กลับบ้านทำโจทย์เพิ่มเยอะหน่อย',
          byTeacher: 'ครูนัท',
        },
      ]);
  }, 1000);
  complete && complete();
};
