// import dayjs from 'dayjs';
// import utc from 'dayjs/plugin/utc';
// dayjs.extend(utc);
const telReg = /^\d{3}\d{3}\d{4}$/;
const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const characterReg = /\D/g;

export const joinClasses = (classes) => (sClasses) =>
  sClasses
    .split(' ')
    .map((c) => classes[c])
    .join(' ');

export function LightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function delay(fn, ms) {
  let timer = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

export function extractErrorRespMsg(respMsg) {
  let errorMsg;
  if (typeof respMsg === 'object') {
    if (respMsg?.response?.data) {
      if (typeof respMsg.response.data === 'object') {
        errorMsg =
          respMsg.response.data.error ||
          respMsg.response.data.message ||
          respMsg.response.data.msg;
      } else {
        errorMsg = respMsg.response.data;
      }
    } else if (respMsg.message || respMsg.warning) {
      errorMsg = respMsg.message || respMsg.warning;
    } else {
      errorMsg = respMsg.response;
    }
  } else {
    errorMsg = respMsg;
  }
  return JSON.stringify(errorMsg);
}

export function callService(
  service,
  request,
  setServiceFlag,
  next = null,
  error = null
) {
  const setFlag = (value) => (prev) => {
    return { ...(prev || {}), ...value };
  };
  const handleSetFlag = (data) => setServiceFlag(setFlag(data));
  handleSetFlag({ request: true, success: false, error: false });
  const params = [
    request,
    (resp) => {
      handleSetFlag({ success: true });
      next && next(resp);
    },
    (err) => {
      handleSetFlag({ error: true });
      error && error(err);
    },
    () => {
      handleSetFlag({ request: false });
    },
  ].filter((i) => !!i);
  service(...params);
}

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = (value || '').trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    isValid = emailReg.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isTel) {
    isValid = telReg.test(value) && isValid;
  }
  if (rules.isChar) {
    isValid = characterReg.test(value) && isValid;
  }

  if (rules.isPhoneNumber) {
    isValid = /^((06)|(08)|(09))[0-9]{8}$/.test(value) && isValid;
  }

  return isValid;
};

export const copyTextToClipboard = (copyText) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(copyText).then(
      function () {
        /* clipboard successfully set */
      },
      function () {
        /* clipboard write failed */
      }
    );
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    //console.log('Text copied to clipboard');
  }
};

export function isSafariMobile() {
  return (
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    !/line/i.test(navigator.userAgent)
  );
}
