import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import SuccessAlert from 'components/fragments/SuccessAlert/SuccessAlert';
//import {ColumnHeader} from 'components/fragments/SuccessAlert/SuccessAlert';
const SuccessAlertComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/SuccessAlert/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <SuccessAlert {...props} />
  </ImagesProvider>
);

export default SuccessAlertComponent;
