import axios from 'host';
import config from '../config';

export const getStudentScore = (
  req,
  next = null,
  error = null,
  complete = null
) => {
  axios
    .get('/teachers')
    .then((resp) => {
      next && next(resp.data);
    })
    .catch((err) => {
      error && error(err);
    })
    .finally(() => {
      complete && complete();
    });
};
