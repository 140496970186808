import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses, copyTextToClipboard } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { clickAnime, calvh } from 'utils/element';
import { ButtonBase } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'btn-copy': {
    //marginTop: '1vh',
    height: ({ height }) => height || calvh('7vh'),
    maxWidth: '22vw',
    objectFit: 'contain',
  },
}));

const ButtonCopy = (props) => {
  const { onClick, copyText } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const refCopyText = React.useRef(null);
  React.useEffect(() => {
    refCopyText.current = copyText;
  }, [copyText]);
  return (
    <ButtonBase className={c('root')}>
      <img
        className={c('btn-copy')}
        src={images['button-copy.png']}
        ref={clickAnime}
        onClick={() => {
          copyTextToClipboard(refCopyText.current || '');
          onClick && onClick();
        }}
      />
    </ButtonBase>
  );
};

export default ButtonCopy;
