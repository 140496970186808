import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { useImagesContext } from 'contexts/ImagesContext';
import { calvh } from 'utils/element';
import { toBase64 } from 'utils/image';
import ButtonUpload from 'components/fragments/ButtonUpload';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingRight: calvh('1vh'),
    marginBottom: calvh('1vh'),
    width: ({ styles }) => styles?.width,
  },
  'label-container': {
    display: 'flex',
    flexWrap: 'wrap',
    //justifyContent: 'flex-end',
    //paddingRight: calvh('1vh'),
    //width: 'min-content',
  },
  'button-container': {
    display: 'flex',
    padding: '0.2rem 0rem 0rem 0rem',
  },
  label: {
    maxHeight: calvh('3.4vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
  },
  'input-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ styles }) => styles?.inputContainer.width,
    height: calvh('8vh'),
    maxWidth: calvh('30vh'),
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 70%',
    backgroundPosition: 'center',
  },
  input: {
    fontSize: calvh('3vh'),
    height: calvh('4vh'),
    padding: `0px 0px ${calvh('0.5vh')} 0px`,
    fontWeight: 600,
    margin: ({ styles }) => styles?.input?.margin || calvh('0.8vh'),
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    width: '100%',
    //lineHeight: '3rem',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width:434px)': {
      fontSize: ({ styles }) => styles?.input?.fontSizeSm,
    },
  },
}));

const UploadImage = (props) => {
  const { label, onChange, imgLabel, imgField, noLabel, styles } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');
  const inputRef = React.useRef(null);

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const handleChangeUpload = (event) => {
    const file = event.target.files[0];
    const name = file?.name;
    setName(name);
    toBase64(file).then((base64) => {
      console.log('base64::', base64);
      onChange && onChange({ name, data: base64 });
    });
  };

  return (
    <div className={c('root')}>
      {!noLabel ? (
        <div className={c('label-container')}>
          <img
            src={images['upload-image-photo.png']}
            className={c('label')}
          ></img>
        </div>
      ) : null}

      <div className={c('button-container')}>
        <ButtonUpload
          onClick={handleClickUpload}
          height={styles?.button?.height}
        />
      </div>
      <div
        className={c('input-container')}
        style={{
          backgroundImage: `url(${
            imgField || images['upload-image-field.png']
          })`,
        }}
      >
        <input
          type={'file'}
          //accept='image/png, image/jpeg'
          accept='image/*'
          ref={inputRef}
          style={{ visibility: 'hidden', width: 0, height: 0 }}
          onChange={handleChangeUpload}
        />
        <input
          className={c('input')}
          //onChange={onChange}
          value={name}
          readOnly
        />
      </div>
    </div>
  );
};

export default UploadImage;
