import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  root: ({ styles }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(styles?.root || {}),
  }),
  'comment-text': {
    fontSize: calvh('3.5vh'),
    lineHeight: calvh('3.5vh'),
    fontWeight: 600,
  },
}));

const Comment = (props) => {
  const { text, styles } = props;
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  return (
    <div className={c('root')}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          backgroundPosition: 'center',
          backgroundImage: `url(${images[`comment-field-bg.png`]})`,
          padding: calvh('1vh'),
          height: styles?.field?.height,
        }}
      >
        <div className={c('comment-text')}>{text}</div>
      </div>
    </div>
  );
};

export default Comment;
