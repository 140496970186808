import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import {
//   Search as SearchIcon,
//   DoubleArrow as DoubleArrowIcon,
//   CheckCircle as CheckCircleIcon
// } from '@material-ui/icons';
import { joinClasses, callService } from 'utils/utility';
import { errorAlert, warningAlert } from 'utils/alert';
import { calvh, clickAnime } from 'utils/element';
import { getStudentComment } from 'services/student-comment';
import { useImagesContext } from 'contexts/ImagesContext';
import Comment from 'components/fragments/Comment';
import ButtonBack from 'components/fragments/ButtonBack';
import Progress from 'components/fragments/Progress';
import { Carousel } from 'antd';
//import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
  },
  'carousel-container': {
    zIndex: 1,
    width: '100%',
    height: 'auto',
    '& [class~="ant-carousel"] [class~="slick-dots"] button': {
      background: '#9798aa !important',
    },
    '& [class~="ant-carousel"] [class~="slick-dots"] [class~="slick-active"] button':
      {
        background: '#adaeb6 !important',
      },
    '& [class~="ant-carousel"] [class~="slick-dots"]': {
      display: 'none !important',
    },
  },
  'student-comment-comment': {
    maxHeight: calvh('3.5vh'),
    '@media (min-width:434px)': {
      //maxHeight: calvh('5vh'),
    },
    '@media (max-width:350px)': {
      //maxHeight: calvh('4vh'),
    },
    objectFit: 'contain',
  },
  'label-date': {
    fontSize: calvh('3.8vh'),
    lineHeight: calvh('3.8vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
    //paddingBottom: '5px',
  },
  'label-by': {
    fontSize: calvh('4vh'),
    lineHeight: calvh('4vh'),
    color: '#000',
    textShadow: `#fff ${calvh('0.1vh')} ${calvh('0.1vh')} 0px, #fff ${calvh(
      '-0.1vh'
    )} ${calvh('0.16vh')} 0px, #fff 0px ${calvh('-0.16vh')} 0px, #000 ${calvh(
      '0.1vh'
    )} ${calvh('0.1vh')} 0px`,
    alignSelf: 'flex-end',
  },
  'container-devider': {
    width: '100%',
    height: '.2rem',
    backgroundColor: '#7d4c08',
  },
  'student-comment-prev': {
    position: 'fixed',
    zIndex: 2,
    left: '91vw',
    top: calvh('9vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'student-comment-next': {
    position: 'fixed',
    zIndex: 2,
    left: '91vw',
    bottom: calvh('3vh'),

    height: calvh('8vh'),
    maxWidth: '8vw',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
}));

const DEFAULT_COMMENT_PAGE = 0;
const DEFAULT_COMMENT_ROWS_PER_PAGE = 4;

const StudentComment = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [getStudentCommentFlag, setGetStudentCommentFlag] = React.useState({});
  const [studentComments, setStudentComments] = React.useState([]);
  const [commentPagination, setCommentPagination] = React.useState({
    rowsPerPage: DEFAULT_COMMENT_ROWS_PER_PAGE,
    page: DEFAULT_COMMENT_PAGE,
  });
  const refCommentCarousel = React.useRef(null);

  React.useEffect(() => {
    handleGetStudentComment();
  }, []);

  const handleCommentCarouselAfterChangePage = (newPage) => {};

  const handleClickCommentPrev = () => {
    refCommentCarousel.current && refCommentCarousel.current.prev();
    if (commentPagination?.page > 0) {
      setCommentPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    } else {
      setCommentPagination((prev) => ({
        ...prev,
        page:
          Math.ceil(
            (studentComments || []).length / DEFAULT_COMMENT_ROWS_PER_PAGE
          ) - 1,
      }));
    }
  };

  const handleClickCommentNext = () => {
    refCommentCarousel.current && refCommentCarousel.current.next();
    if (
      commentPagination?.page <
      Math.ceil(
        (studentComments || []).length / DEFAULT_COMMENT_ROWS_PER_PAGE
      ) -
        1
    ) {
      setCommentPagination((prev) => ({ ...prev, page: prev.page + 1 }));
    } else {
      setCommentPagination((prev) => ({ ...prev, page: 0 }));
    }
  };

  const handleGetStudentComment = (next) => {
    callService(
      getStudentComment,
      {},
      setGetStudentCommentFlag,
      (resp) => {
        setStudentComments(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const pendingFlag = React.useMemo(
    () => getStudentCommentFlag.request,
    [getStudentCommentFlag.request]
  );

  const successFlag = React.useMemo(
    () => getStudentCommentFlag.success,
    [getStudentCommentFlag.success]
  );

  return (
    <div className={c('root')}>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        style={{ height: '100%' }}
      >
        <Grid
          item
          container
          justifyContent='center'
          alignItems='flex-start'
          alignContent='flex-start'
          style={{ marginTop: '0.5rem' }}
        >
          {pendingFlag ? (
            <Progress />
          ) : successFlag ? (
            <>
              <Grid item xs={11} container>
                <img
                  style={{
                    height: calvh('4vh'),
                    maxWidth: '32vw',
                    objectFit: 'contain',
                  }}
                  src={images[`student-comment-comment.png`]}
                />
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent='center'
                  style={{ padding: '5px 0px' }}
                >
                  <div className={c('container-devider')} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={11}
                container
                style={{
                  // height: calvh('79vh'),
                  // overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <div className={c('carousel-container')}>
                  <Carousel
                    afterChange={handleCommentCarouselAfterChangePage}
                    ref={refCommentCarousel}
                    dotPosition='right'
                  >
                    {Array.from(
                      Array(
                        Math.ceil(
                          (studentComments || []).length /
                            DEFAULT_COMMENT_ROWS_PER_PAGE
                        )
                      ).keys()
                    ).map((_, pidx) => {
                      const page = pidx;
                      const pageSize = commentPagination.rowsPerPage;
                      const trainersFilter = (studentComments || []).slice(
                        page * pageSize,
                        page * pageSize + pageSize
                      );
                      return (
                        <div key={`comment-page-` + pidx}>
                          <div
                            style={{
                              height: calvh('79vh'),
                              overflow: 'auto',
                            }}
                          >
                            {(trainersFilter || []).map((comment) => (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexFlow: 'column',
                                }}
                              >
                                <div className={c('label-date')}>
                                  {comment.date}
                                </div>
                                <Comment
                                  text={comment.comment}
                                  styles={{
                                    root: {
                                      height: '100%',
                                      width: '90%',
                                      alignSelf: 'center',
                                    },
                                    field: {
                                      //height: calvh('10vh'),
                                    },
                                  }}
                                />
                                <div className={c('label-by')}>
                                  {comment.byTeacher}
                                </div>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justifyContent='center'
                                  style={{ padding: '5px 0px' }}
                                >
                                  <div className={c('container-devider')} />
                                </Grid>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                <img
                  className={c('student-comment-prev')}
                  src={images['student-comment-prev.png']}
                  onClick={handleClickCommentPrev}
                  ref={clickAnime}
                />
                <img
                  className={c('student-comment-next')}
                  src={images['student-comment-next.png']}
                  onClick={handleClickCommentNext}
                  ref={clickAnime}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
        <div style={{ position: 'absolute', bottom: '-4vh', zIndex: 2 }}>
          <ButtonBack />
        </div>
      </Grid>
    </div>
  );
};

export default StudentComment;
