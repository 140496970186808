import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { joinClasses } from 'utils/utility';
import { calvh } from 'utils/element';
import { useImagesContext } from 'contexts/ImagesContext';

const useStyles = makeStyles((theme) => ({
  frame: {
    height: 'fit-content',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'img-pic-flag': {
    //height: 'fit-content',
    // maxHeight: '91%',
    // maxWidth: '91%',
    objectFit: 'contain',
    clipPath: 'circle(50% at 50% 50%)',
  },
  'img-win-flag': {
    height: 'fit-content',
    maxHeight: '91%',
    maxWidth: '91%',
    objectFit: 'contain',
    objectPosition: '0% 0%',
  },
  'frame-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  'frame-win-flag-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'frame-pic-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  'frame-label-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  'frame-label': {
    width: '100%',
    //fontSize: calvh('4vh'),
    color: '#fff',
    textShadow: `#000 ${calvh('0.23vh')} ${calvh('0.23vh')} 0px, #000 ${calvh(
      '-0.23vh'
    )} ${calvh('-0.23vh')} 0px, #000 ${calvh('0.23vh')} ${calvh(
      '-0.23vh'
    )} 0px, #000 ${calvh('-0.23vh')} ${calvh('0.23vh')} 0px`,
    textAlign: 'center',
    //lineHeight: calvh('4vh'),
  },
}));

const Frame = (props) => {
  const { ckey, name, winFlag, styles, type, pic } = props || {};
  const c = joinClasses(useStyles({ ...props }));
  const { images } = useImagesContext();
  return (
    <div
      className={c('frame-container')}
      key={ckey}
      style={{ margin: styles?.margin }}
    >
      <img
        className={c('frame')}
        style={{
          maxHeight: styles?.frame?.maxHeight,
          maxWidth: styles?.frame?.maxWidth,
        }}
        src={images[`frame-${type}.png`]}
      />
      {pic !== undefined ? (
        <div className={c('frame-pic-container')}>
          <img
            className={c('img-pic-flag')}
            src={pic}
            style={{
              height: styles?.pic?.height,
              width: styles?.pic?.width,
            }}
          />
        </div>
      ) : null}
      {winFlag !== undefined ? (
        <div className={c('frame-win-flag-container')}>
          <img
            className={c('img-win-flag')}
            src={images[`frame-${winFlag ? 'win' : 'lose'}.png`]}
          />
        </div>
      ) : null}
      <div className={c('frame-label-container')}>
        <div
          className={c('frame-label')}
          style={{
            fontSize: styles?.name?.fontSize,
            lineHeight: styles?.name?.lineHeight,
            transform: styles?.name?.transform,
          }}
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export default Frame;
