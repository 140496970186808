import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useImagesContext } from 'contexts/ImagesContext';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert, warningAlert } from 'utils/alert';
import { logout } from 'services/auth';
import NevigateBack from 'components/fragments/NevigateBack';
import ButtonLogout from 'components/fragments/ButtonLogout';
import Progress from 'components/fragments/Progress';
import * as decisionActions from 'actions/decision';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    height: calvh('100vh'),
    overflow: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  title: {
    position: 'absolute',
    zIndex: 2,
    fontSize: calvh('5vh'),
    lineHeight: calvh('5vh'),
    color: '#7a390b',
  },
  'img-top-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '5rem',
    zIndex: 1,
  },
  'img-left-bar': {
    position: 'fixed',
    top: 0,
    left: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-right-bar': {
    position: 'fixed',
    top: 0,
    right: '3%',
    zIndex: 0,
    width: '2.5%',
    height: calvh('100vh'),
  },
  'img-bottom-bar': {
    width: '100%',
    height: calvh('6vh'),
    maxHeight: '4rem',
    zIndex: 1,
  },
  'container-devider': {
    width: '60%',
    height: '.2rem',
    backgroundColor: '#6c2912',
  },
}));

const withTitleState = {
  introduce: 'หน้า',
  principle: 'หน้า',
};

const Background = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));
  const [withTitle, setWithTitle] = React.useState('');
  const [displayLogout, setDisplayLogout] = React.useState(false);
  const [allowLogout, setAllowLogout] = React.useState(false);
  const [logoutFlag, setLogoutFlag] = React.useState({});
  const [displayNevigateBack, setDisplayNevigateBack] = React.useState(false);
  const [rootHeight, setRootHeight] = React.useState('100vh');
  const store = useSelector((state) => state);
  const { activeState, actionState } = store?.decision;
  const dispatch = useDispatch();

  // React.useLayoutEffect(() => {
  //   //setRootHeight(calVHForSafari());
  //   if (true) {
  //     // var element = document.querySelector('[root]');
  //     // var scrollHeight = element.scrollHeight;
  //     // window.resizeTo(window.innerWidth, 100);
  //     // window.alert(
  //     //   JSON.stringify({
  //     //     innerHeight: window.innerHeight,
  //     //     availHeight: window.screen.availHeight,
  //     //   })
  //     // );
  //     // window.resizeTo(window.innerWidth, 500);
  //     // const width = window.innerWidth;
  //     // const height = window.innerHeight;
  //     // window.resizeTo(width, 200);
  //   }
  // }, []);

  React.useEffect(() => {
    if (Object.keys(withTitleState).includes(activeState?.state)) {
      setWithTitle(withTitleState[activeState?.state]);
    } else {
      setWithTitle('');
    }
    if (activeState?.state === 'schedule') {
      setDisplayLogout(true);
      //setDisplayNevigateBack(true);
    } else {
      setDisplayLogout(false);
      //setDisplayNevigateBack(false);
    }
  }, [activeState]);

  React.useEffect(() => {
    if (actionState?.['editTrainerInfo']) {
      setAllowLogout(true);
    }
  }, [actionState]);

  const handleClickBack = () => {
    const nevigateBackMap = {
      //schedule: 'introduce-end',
    };
    if (nevigateBackMap[activeState?.state]) {
      dispatch(
        decisionActions.setActiveState(nevigateBackMap[activeState?.state])
      );
    }
  };

  const handleClickLogout = () => {
    if (allowLogout) {
      handleLogoutService();
    } else {
      warningAlert(undefined, 'กรุณาบันทึกข้อมูลก่อนออกจากระบบ.');
    }
  };

  const handleLogoutService = (next) => {
    callService(
      logout,
      null,
      setLogoutFlag,
      (resp) => {
        dispatch(decisionActions.setActiveState('login'));
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  return (
    <div
      className={c('root')}
      background=''
      style={{
        backgroundImage: `url(${images['user-checklist-bg-bg.jpg']})`,
      }}
    >
      {displayNevigateBack ? (
        <div style={{ position: 'absolute', zIndex: 999, left: 2, top: -4 }}>
          <NevigateBack onClick={handleClickBack} height={calvh('4.5vh')} />
        </div>
      ) : null}
      {withTitle ? (
        <div className={'text' + ' ' + c('title')}>{withTitle}</div>
      ) : null}
      <img
        className={c('img-top-bar')}
        src={images['user-checklist-background-top.png']}
      />
      <img
        className={c('img-left-bar')}
        src={images['user-checklist-background-side.png']}
      />
      <div
        style={{
          width: '89%',
          height: '100%',
          // overflow: 'hidden auto'
        }}
      >
        {props.children}
      </div>
      <img
        className={c('img-right-bar')}
        src={images['user-checklist-background-side.png']}
      />
      <img
        className={c('img-bottom-bar')}
        src={images['user-checklist-background-bottom.png']}
      />
      {displayLogout ? (
        <div style={{ position: 'absolute', zIndex: 999, right: 5, bottom: 2 }}>
          <ButtonLogout
            onClick={handleClickLogout}
            disabled={logoutFlag.request}
          />
        </div>
      ) : null}
      {logoutFlag.request ? <Progress /> : null}
    </div>
  );
};

export default Background;
