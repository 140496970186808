const prod = {
  // hostUrl: 'https://study.tuagom.com/webservice',
  //hostUrl: 'https://study-test.tuagom.com/study-test/service',
  hostUrl: 'https://tuagom.com/teach-api',
  adminPath: '/user22',
  studentPath: '/student',
  lineLiff: 'https://line.me/ti/p/gS4AXKwJrK',
};

const dev = {
  // hostUrl: 'http://localhost:5000',
  hostUrl: 'https://tuagom.com/teach-api',
  adminPath: '/user22',
  studentPath: '/student',
  lineLiff: 'https://line.me/ti/p/gS4AXKwJrK',
};

const config = process.env.NODE_ENV !== 'production' ? dev : prod;
// console.debug('process.env.NODE_ENV', process.env.NODE_ENV);

export default {
  // Add common config values here
  ...config,
};
