import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonAddLine from 'components/fragments/ButtonAddLine/ButtonAddLine';
const ButtonAddLineComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonAddLine/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonAddLine {...props} />
  </ImagesProvider>
);

export default ButtonAddLineComponent;
