import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { joinClasses, callService } from 'utils/utility';
import { calvh } from 'utils/element';
import { errorAlert } from 'utils/alert';
import { getTrainers } from 'services/trainer';
import { useImagesContext } from 'contexts/ImagesContext';
import { useDispatch } from 'react-redux';
import Progress from 'components/fragments/Progress';
import TrainerList from 'components/fragments/TrainerList';
import ButtonBack from 'components/fragments/ButtonBack';
import 'antd/dist/antd.css';
import { Carousel } from 'antd';

import * as decisionActions from 'actions/decision';
import { clickAnime } from 'utils/element';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    '& [text-resize]': {
      fontSize: calvh('3vh'),
      '@media (min-width:434px)': {
        fontSize: calvh('3.5vh'),
      },
    },
  },
  'container-devider': {
    width: '80%',
    height: calvh('0.4vh'),
    backgroundColor: '#6c2912',
  },
  'carousel-branch-container': {
    width: '80vw',
    height: 'auto',

    '& [class~="ant-carousel"] [class~="slick-dots"] button': {
      background: '#9798aa !important',
    },
    '& [class~="ant-carousel"] [class~="slick-dots"] [class~="slick-active"] button':
      {
        background: '#adaeb6 !important',
      },
    '& [class~="ant-carousel"] [class~="slick-dots-bottom"]': {
      display: 'none !important',
    },
  },
  'trainers-container': {
    width: '100%',
    display: 'flex !important',
    //padding: '0px 2vh',
  },
  'carousel-nav-container': {
    position: 'absolute',
    bottom: calvh('-5vh'),
    zIndex: 2000,
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90vw',
  },
  'carousel-nav-next': {
    height: calvh('10vh'),
    maxWidth: '18vw',
    objectFit: 'contain',
    //right: '-1vh',
    //bottom: '-5vh',
  },
  'carousel-nav-prev': {
    height: calvh('10vh'),
    maxWidth: '20vw',
    objectFit: 'contain',
    //left: '-1vh',
    //bottom: '-5vh',
  },
  'carousel-refresh': {
    height: calvh('12vh'),
    maxWidth: '24vw',
    objectFit: 'contain',
    //left: 'calc(50%)',
    //bottom: '-5vh',
  },
}));

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 10;

const Trainers = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props }));

  const [getTrainersFlag, setgetTrainersFlag] = React.useState({});
  const [trainers, setTrainers] = React.useState([]);
  const [trainersFilter, setTrainersFilter] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    page: DEFAULT_PAGE,
  });
  const refCarousel = React.useRef(null);
  const refTrainers = React.useRef(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    refTrainers.current = trainers;
    setPagination((prev) => ({ ...prev }));
  }, [trainers]);

  React.useEffect(() => {
    const page = pagination.page;
    const pageSize = pagination.rowsPerPage;
    const trainersFilter = (refTrainers.current || []).slice(
      page * pageSize,
      page * pageSize + pageSize
    );
    setTrainersFilter(trainersFilter);
  }, [pagination]);

  React.useEffect(() => {
    handlegetTrainersService();
  }, []);

  const handleClickTrainer = (trainer) => {
    dispatch(decisionActions.setActiveState('trainer-schedule', { trainer }));
  };

  const handleAfterChangePage = (newPage) => {};

  const handleClickRefresh = () => {
    setPagination({ rowsPerPage: DEFAULT_ROWS_PER_PAGE, page: DEFAULT_PAGE });
    handlegetTrainersService();
  };

  const handlegetTrainersService = (next) => {
    callService(
      getTrainers,
      null,
      setgetTrainersFlag,
      (resp) => {
        //dispatch(authActions.setAuthenSuccess());
        setTrainers(resp);
        next && next(resp);
      },
      (error) => {
        errorAlert(error);
      }
    );
  };

  const pendingFlag = React.useMemo(
    () => getTrainersFlag.request,
    [getTrainersFlag.request]
  );

  const successFlag = React.useMemo(
    () => getTrainersFlag.success,
    [getTrainersFlag.success]
  );

  return (
    <div className={c('root')}>
      {pendingFlag ? (
        <Progress />
      ) : successFlag ? (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: `${calvh('6vh')} 0px ${calvh('1.5vh')} 0px` }}
          >
            <div className={c('container-devider')} />
          </Grid>
          <Grid item xs={12} container>
            <div className={c('carousel-branch-container')}>
              <Carousel afterChange={handleAfterChangePage} ref={refCarousel}>
                {Array.from(
                  Array(
                    Math.ceil((trainers || []).length / DEFAULT_ROWS_PER_PAGE)
                  ).keys()
                ).map((_, pidx) => (
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    className={c('trainers-container')}
                    key={'carousel-page-' + pidx}
                  >
                    <TrainerList
                      trainers={trainersFilter}
                      parentIdx={pidx * DEFAULT_ROWS_PER_PAGE}
                      onClick={handleClickTrainer}
                    />
                  </Grid>
                ))}
              </Carousel>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            style={{ padding: `${calvh('1.5vh')} 0px ${calvh('1.5vh')} 0px` }}
          >
            <div className={c('container-devider')} />
          </Grid>
          <div className={c('carousel-nav-container')}>
            <img
              src={images['trainers-back.png']}
              className={c('carousel-nav-prev')}
              onClick={() => {
                refCarousel.current && refCarousel.current.prev();
                if (pagination?.page > 0) {
                  setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
                } else {
                  setPagination((prev) => ({
                    ...prev,
                    page:
                      Math.ceil(
                        (trainers || []).length / DEFAULT_ROWS_PER_PAGE
                      ) - 1,
                  }));
                }
              }}
              ref={clickAnime}
            />
            <img
              src={images['trainers-refresh.png']}
              className={c('carousel-refresh')}
              onClick={handleClickRefresh}
              ref={clickAnime}
            />
            <img
              src={images['trainers-next.png']}
              className={c('carousel-nav carousel-nav-next')}
              onClick={() => {
                refCarousel.current && refCarousel.current.next();
                if (
                  pagination?.page <
                  Math.ceil((trainers || []).length / DEFAULT_ROWS_PER_PAGE) - 1
                ) {
                  console.log('setPagination');
                  setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
                } else {
                  setPagination((prev) => ({ ...prev, page: 0 }));
                }
              }}
              ref={clickAnime}
            />
          </div>
        </Grid>
      ) : null}
    </div>
  );
};

export default Trainers;
