import React, { createContext, useContext } from 'react';
const HocContext = createContext();
const WrapperHoc = (props) => {
  const { open, consider } = props;
  const conditionChild = React.useMemo(() => {
    if (props?.children && Object.keys(props).includes('consider')) {
      let children = React.Children.toArray(props?.children).map(
        (child) => ({
          ...child,
          layer: [0],
        })
      );
      let queue = children;
      while (queue.length) {
        const child = queue.shift(); // pop first
        if (child?.type?.type?.name === 'WhenHoc') {
          queue.unshift(child); // push first
          children = queue.filter(
            (item) => item.layer?.join() === child.layer?.join()
          );
          break;
        } else {
          if(child?.props?.children){
            React.Children.toArray(child?.props?.children).forEach(
              (subChild) => {
                const layer = [...child.layer];
                layer.push(layer[layer.length - 1] + 1);
                queue.push({ ...subChild, layer });
              }
            );
          }
        }
      }
      return children.find((child) => child?.props?.condition === consider);
    } else {
      return undefined;
    }
  }, [props?.children, consider]);

  const isConditionMatched = React.useMemo(
    () => !!conditionChild,
    [conditionChild]
  );

  return (
    <HocContext.Provider value={{ consider, isConditionMatched }}>
      {!!open ? props?.children : null}
    </HocContext.Provider>
  );
};
const WhenHoc = (props) => {
  const { condition } = props;
  const { consider } = useContext(HocContext);
  return <>{consider === condition ? props?.children : null}</>;
};

const OtherwiseHoc = (props) => {
  const { isConditionMatched } = useContext(HocContext);
  return <>{isConditionMatched === false ? props?.children : null}</>;
};

const Wrapper = React.memo(
  WrapperHoc
);

const When = React.memo(
  WhenHoc);

const Otherwise = React.memo(OtherwiseHoc);

export { Wrapper, When, Otherwise };

export default Wrapper;
