import React from 'react';
import { ImagesProvider } from 'contexts/ImagesContext';
import ButtonOK from 'components/fragments/ButtonOK/ButtonOK';
const ButtonOKComponent = (props) => (
  <ImagesProvider
    intro={false}
    r={require.context(
      'components/fragments/ButtonOK/',
      true,
      /\.(png|jpe?g|svg|gif)$/
    )}
  >
    <ButtonOK {...props} />
  </ImagesProvider>
);

export default ButtonOKComponent;
