import React from 'react';
import Swal from 'sweetalert2';
//import alertify from 'alertifyjs';
import { isSafariMobile } from 'utils/utility';
import { calvh } from 'utils/element';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const didOpen = () => {
  // if (isSafariMobile()) {
  const swalBody = Swal.getHtmlContainer().parentNode.parentNode.parentNode;
  if (swalBody) {
    swalBody.style.top = '';
    swalBody.style.setProperty('height', '100%', 'important');
  }
  //}
};

const didClose = () => {
  // if (isSafariMobile()) {
  //   const swalBody = Swal.getHtmlContainer().parentNode.parentNode.parentNode;
  //   if (swalBody) {
  //     //swalBody.style.top = '';
  //     //swalBody.style.setProperty('height', '');
  //   }
  // }
};

export const saveSuccess = () => {
  return MySwal.fire({
    title: 'Saved successfully',
    icon: 'success',
  });
};

// export const errorMessage = (error) => {
//   return MySwal.fire({ title: error, icon: 'error' });
// };

export const successAlert = (title, html) => {
  //Swal.fire(title || 'บันทึกข้อมูล', html || 'ทำรายการสำเร็จ', 'success');

  Swal.fire({
    title: title || 'บันทึกข้อมูล',
    icon: 'success',
    html: html || 'ทำรายการสำเร็จ',
    didOpen,
    didClose,
  });
};

export const warningAlert = (title, html) => {
  //Swal.fire(title || '', html, 'warning');
  // Swal.mixin({
  //   toast: true,
  //   position: 'top-end',
  //   timer: 3000,
  //   timerProgressBar: true,
  // });
  //alert(isSafariMobile());
  Swal.fire({
    title,
    icon: 'warning',
    html: `<div style="font-size:2.4vh;color:red">${html}</div>`,
    didOpen,
    didClose,
  });
};

export const errorAlert = (err, callBack = () => {}) => {
  let errmsg = err;

  if (
    err.response &&
    err.response.data &&
    err.response.data.errors &&
    Array.isArray(err.response.data.errors) &&
    (err.response.data.errors || []).length > 0
  ) {
    errmsg = err.response.data.errors.map((e) => e.msg).join(', ');
  } else if (err.response && err.response.data && err.response.data.errors) {
    errmsg = err.response.data.errors;
  } else if (err.message) {
    errmsg = err.message;
  }
  Swal.fire({
    title: 'เกิดข้อผิดพลาด',
    text: JSON.stringify(errmsg),
    icon: 'error',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'ตกลง',
    didOpen,
  }).then(callBack);
};
