import axios from '../host';
export const SET_MUTE_SOUND = 'SET_MUTE_SOUND';

export const setMuteSound = (val) => async (dispatch) => {
  if (!val) {
    window.localStorage.removeItem('isMutedSound');
  } else {
    window.localStorage.setItem('isMutedSound', !!val);
  }

  return dispatch({
    type: SET_MUTE_SOUND,
    payload: { value: !!val },
  });
};
